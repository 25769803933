import { useState } from 'react';
import VideoView from './VideoView';
import MapView from './MapView';
import '../../../../../../../styles/NewDashboard/ShiftReview/ShiftCard/ShiftExpanded/EventDetail/EventDetail.css';

const EventDetail = ({ showVideo, event, onShowVideo, alertData, locations }) => {
  console.log('event', event);
  return (
    <div className="event-detail-container">
      {event.type === 'escalation' ? (
        <VideoView event={event} alertData={alertData} />
      ) : (
        <MapView locations={locations} size="large" event={event} />
      )}
    </div>
  );
};

export default EventDetail;
