import React, { useState, useEffect, useRef, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBatteryEmpty,
  faBatteryQuarter,
  faBatteryHalf,
  faBatteryThreeQuarters,
  faBatteryFull,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';

export const getBatteryIcon = (batteryStatus) => {
  if (batteryStatus > 75) return faBatteryFull;
  if (batteryStatus > 50) return faBatteryThreeQuarters;
  if (batteryStatus > 25) return faBatteryHalf;
  if (batteryStatus > 10) return faBatteryQuarter;
  return faBatteryEmpty;
};

export const SignalStrength = ({ strength }) => {
  const strengthClass = `signal-strength signal-strength-${strength || 'unknown'}`;
  return (
    <span className={strengthClass}>
      <span className="bar-container">
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </span>
    </span>
  );
};

export const HLSVideoPlayer = ({ hlsUrl: initialHlsUrl, deviceId, isStreaming, onToggleStream }) => {
  const playerRef = useRef(null);
  const playerId = useRef(`player_id_${Math.random().toString(36).substr(2, 9)}`); // Generate a unique ID
  const playerInstanceRef = useRef(null); // Reference to store the player instance
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const retryTimeoutRef = useRef(null);
  const hasReconnected = useRef(false);
  const [isRecovering, setIsRecovering] = useState(false);
  const [isAudioOnly, setIsAudioOnly] = useState(false);
  const [hlsUrl, setHlsUrl] = useState(initialHlsUrl);
  const [originalVideoUrl, setOriginalVideoUrl] = useState(initialHlsUrl);
  const [isPreparingAudio, setIsPreparingAudio] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isPlaying, setIsPlaying] = useState(false);
  const [showRetryButton, setShowRetryButton] = useState(false);
  const [showFallbackMessage, setShowFallbackMessage] = useState(false);

  // if (!Hls.isSupported()) {
  //   return (
  //     <video controls src={hlsUrl}>
  //       Your browser does not support the video tag.
  //     </video>
  //   );
  // }

  const loadScript = (src, id) => {
    /**
     * Used for loading the hls.js and ovenplayer.js scripts
     * Loads a script from a given source and ID.
     * If the script is already loaded, it resolves immediately.
     * If the script is not loaded, it creates a new script element and appends it to the document.
     * @param {string} src - The source URL of the script.
     * @param {string} id - The ID of the script.
     * @returns {Promise} - A promise that resolves when the script is loaded.
     */
    return new Promise((resolve, reject) => {
      if (document.getElementById(id)) {
        resolve();
        return;
      }
      const script = document.createElement('script');
      script.src = src;
      script.id = id;
      script.onload = resolve;
      script.onerror = reject;
      document.body.appendChild(script);
    });
  };

  const initializePlayer = useCallback(() => {
    /**
     * Initializes the OvenPlayer with the given HLS URL and player ID.
     * If the OvenPlayer is available and the player container exists, it creates a new player instance.
     * If the player is already created, it updates the player instance.
     */
    if (window.OvenPlayer && document.getElementById(playerId.current)) {
      try {
        const player = window.OvenPlayer.create(playerId.current, {
          sources: [
            {
              file: hlsUrl,
              label: isAudioOnly ? 'Audio Stream' : 'Video Stream',
              type: 'll-hls',
              latency: 'low', // Set the latency mode to 'low'
            },
          ],
          playback: {
            autoStart: true,
            autoFallback: true,
            autoQualityMode: true,
            qualityOption: {
              defaultQuality: 'auto',
              allowChangeQuality: true,
            },
            muted: true,
            hlsConfig: {
              lowLatencyMode: true, // Enable low latency mode
              maxLiveSyncPlaybackRate: 1.5, // Allow faster synchronization
              targetLiveBufferLength: 2, // Reduce buffer length to minimize latency
              liveSyncDuration: 2, // Lower the sync duration
              liveSyncDurationCount: 3, // Minimum number of segments for sync
              liveMaxLatencyDuration: 2,
              liveBackBufferLength: 0, // No back buffer
              startLevel: -1, // Start at the live edge
            },
          },
        });
        player.on('ready', () => {
          setIsLoading(false);
          setHasError(false);
          // Don't reset retryCount here
        });

        player.on('idle', () => {
          console.log('OvenPlayer idle');
          player.play();
        });

        player.on('stateChanged', (state) => {
          console.log('Player state changed:', state);
          // if (state.newstate === 'stalled') {
          //   const currentLatency = player.latency;
          //   player.setLiveLatency(currentLatency + 5); // Increase latency by 5 seconds
          // }
          if (state.newstate === 'playing') {
            const qualityLevels = player.getQualityLevels();
            console.log('Available quality levels:', qualityLevels);

            setIsLoading(false);
            setHasError(false);
            setRetryCount(0);
            setIsRecovering(false);
            hasReconnected.current = true;
          }
          // } else if (state.prevstate === 'error' && state.newstate === 'idle') {
          //   setIsRecovering(true);
          //   setIsLoading(true);
          //   setHasError(false);
          //   setRetryCount(0);
          // }
        });

        player.on('play', () => {
          setIsPlaying(true);
        });

        player.on('pause', () => {
          setIsPlaying(false);
        });

        player.on('error', (error) => {
          console.error('OvenPlayer error:', error);
          handlePlayerError();
        });

        playerInstanceRef.current = player; // Store the player instance
      } catch (error) {
        console.error('Failed to initialize OvenPlayer:', error);
        handlePlayerError();
      }
    } else {
      console.error('OvenPlayer is not available or container not found');
      handlePlayerError();
    }
  }, [hlsUrl]);

  const retryConnection = useCallback(() => {
    /**
     * Retries the connection by clearing the player instance, setting the loading and error states, and calling initializePlayer.
     */
    setIsLoading(true);
    setHasError(false);
    if (playerInstanceRef.current) {
      // Instead of removing the player, try to load the new source
      playerInstanceRef.current.load([
        {
          file: hlsUrl,
          label: isAudioOnly ? 'Audio Stream' : 'Video Stream',
          type: 'll-hls',
          latency: 'low',
        },
      ]);
      playerInstanceRef.current.play();
    } else {
      initializePlayer();
    }
  }, [hlsUrl, isAudioOnly, initializePlayer]);

  useEffect(() => {
    setOriginalVideoUrl(initialHlsUrl);
  }, [initialHlsUrl]);

  const switchToAudioStream = useCallback(() => {
    const audioUrl = hlsUrl.replace('-video.m3u8', '.m3u8');
    setHlsUrl(audioUrl);
    setIsAudioOnly(true);
    setRetryCount(0);
    setHasError(false);
    setIsLoading(true);
    setIsPreparingAudio(false);
    setShowFallbackMessage(true);

    // Set a timeout to hide the fallback message after 5 seconds
    setTimeout(() => {
      setShowFallbackMessage(false);
    }, 5000);

    retryConnection();
  }, [retryConnection]);

  const handleVideoPlayerError = useCallback(() => {
    console.log('handleVideoPlayerError called');
    setIsLoading(false);
    setHasError(true);
    hasReconnected.current = false;
    setRetryCount((prevCount) => {
      const newCount = prevCount + 1;
      console.log('New retry count:', newCount);
      if (newCount <= 4) {
        clearTimeout(retryTimeoutRef.current);
        retryTimeoutRef.current = setTimeout(() => {
          retryConnection();
        }, 5000);
        // Different messages for each attempt
        switch (newCount) {
          case 1:
            setErrorMessage('Initializing connection to livestream...');
            break;
          case 2:
            setErrorMessage('Authenticating livestream...');
            break;
          case 3:
            setErrorMessage('Optimizing stream quality...');
            break;
          case 4:
            setErrorMessage('Falling back to audio stream if video is not available...');
            break;
        }
      } else {
        switchToAudioStream();
      }
      return newCount;
    });
  }, [retryConnection, switchToAudioStream]);

  const handleAudioPlayerError = useCallback(() => {
    setIsLoading(false);
    setHasError(true);
    hasReconnected.current = false;
    setRetryCount((prevCount) => {
      const newCount = prevCount + 1;
      if (newCount <= 2) {
        clearTimeout(retryTimeoutRef.current);
        retryTimeoutRef.current = setTimeout(() => {
          retryConnection();
        }, 5000);
        setErrorMessage(`Attempting to connect to audio stream... (Attempt ${newCount}/2)`);
      } else {
        setErrorMessage('Unable to connect to audio stream. Please try again.');
        setShowRetryButton(true);
      }
      return newCount;
    });
  }, [retryConnection]);

  const handlePlayerError = useCallback(() => {
    console.log('About to call handlePlayerError');
    if (isAudioOnly) {
      handleAudioPlayerError();
    } else {
      handleVideoPlayerError();
    }
  }, [handleAudioPlayerError, handleVideoPlayerError]);

  const switchToVideoStream = useCallback(
    (event) => {
      event.stopPropagation(); // Prevent the event from bubbling up to the parent
      console.log('Switching to video stream', originalVideoUrl);
      setHlsUrl(originalVideoUrl);
      setIsAudioOnly(false);
      setRetryCount(0);
      setHasError(false);
      setIsLoading(true);
      setShowRetryButton(false);
      setErrorMessage(''); // Clear any error messages
      console.log('About to retry connection');
      retryConnection();
    },
    [originalVideoUrl, retryConnection]
  );

  const retryAudioStream = useCallback(
    (event) => {
      event.stopPropagation();
      setShowRetryButton(false);
      setRetryCount(0);
      setHasError(false);
      setIsLoading(true);
      retryConnection();
    },
    [retryConnection]
  );

  useEffect(() => {
    if (isStreaming) {
      const loadScripts = async () => {
        try {
          if (!document.getElementById('hls-js-script')) {
            await loadScript('https://cdn.jsdelivr.net/npm/hls.js@latest', 'hls-js-script');
          }
          if (!document.getElementById('ovenplayer-script')) {
            await loadScript('https://cdn.jsdelivr.net/npm/ovenplayer@latest/dist/ovenplayer.js', 'ovenplayer-script');
          }
          initializePlayer();
        } catch (error) {
          console.error('Failed to load scripts:', error);
          handlePlayerError();
        }
      };

      loadScripts();
    } else {
      // Clean up when streaming is toggled off
      if (playerInstanceRef.current) {
        playerInstanceRef.current.remove();
        playerInstanceRef.current = null;
      }
    }

    return () => {
      clearTimeout(retryTimeoutRef.current);
      if (playerInstanceRef.current) {
        playerInstanceRef.current.remove();
        playerInstanceRef.current = null;
      }
    };
  }, [hlsUrl, initializePlayer, handlePlayerError, isStreaming]);

  return (
    <div className="video-wrapper">
      <div className="hls-video-container">
        {isStreaming ? (
          <>
            <div ref={playerRef} id={playerId.current} className="hls-video" />
            {isAudioOnly && isPlaying && (
              <div className={`audio-only-overlay ${isPlaying ? '' : 'hidden'}`}>
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/74/Speaker_icon.svg/1280px-Speaker_icon.svg.png"
                  alt="Audio Only"
                />
              </div>
            )}
            {(isLoading || isRecovering) && (
              <div className="loading-overlay">
                <FontAwesomeIcon icon={faSpinner} spin size="3x" />
                <p>
                  {isRecovering
                    ? 'Fetching stream...'
                    : isPreparingAudio
                      ? 'Unable to livestream video. Connecting to audio livestream...'
                      : isAudioOnly
                        ? 'Connecting to audio livestream...'
                        : 'Loading...'}
                </p>
              </div>
            )}
            {hasError && !isRecovering && (
              <div className="error-overlay">
                {showRetryButton ? (
                  <>
                    <p>{errorMessage}</p>
                    <button onClick={retryAudioStream} className="retry-button">
                      Retry
                    </button>
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon icon={faSpinner} spin size="3x" />
                    <p>{errorMessage}</p>
                  </>
                )}
              </div>
            )}
            {isAudioOnly && (
              <>
                {showFallbackMessage ? (
                  <div className="fallback-message">
                    Unable to connect to the video stream, falling back to audio-only stream.
                  </div>
                ) : (
                  <div className="audio-only-indicator">
                    Audio Only
                    <button onClick={switchToVideoStream} className="switch-to-video-button">
                      Switch to Video
                    </button>
                  </div>
                )}
              </>
            )}
          </>
        ) : (
          <div className="stream-placeholder">
            <p>Livestream is currently off</p>
          </div>
        )}
      </div>
      <div className="stream-toggle">
        <label className="switch">
          <input type="checkbox" checked={isStreaming} onChange={() => onToggleStream(deviceId)} />
          <span className="slider round"></span>
        </label>
        <span>
          {isStreaming ? 'Stop Livestream' : 'Start Livestream'}
          <span className="beta-tag">BETA</span>
        </span>
      </div>
    </div>
  );
};
