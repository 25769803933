import axios from 'axios';

import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import isEqual from 'lodash/isEqual';
import { useParams } from 'react-router-dom';
import { useDeviceFilter } from '../contexts/DeviceFilterContext';
import { useAuth0 } from '@auth0/auth0-react';
import Tooltip from '@mui/material/Tooltip';
import InfiniteScroll from 'react-infinite-scroll-component';

import { MapContainer, TileLayer, Marker, Popup, ZoomControl } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import '../styles/LivePopup.css';

import { HLSVideoPlayer, SignalStrength, getBatteryIcon } from './LivestreamComponents';
import MobilePanel from './MobilePanel';
import Card from './Card';
import Dashboard from './Dashboard';
import VideoManagement from './VideoManagement';
import DeviceAssignment from './DeviceAssignment';
import AlertPopup from './AlertPopup';
import { Modal } from './LiveStreamModal';

import { PLIX_OVERWATCH_ACCOUNT } from '../utils/utilsEvents';

import { fetchRecentEscalationAlerts, getAlertLabelingMetrics } from '../utils/dashboardCosmosQueries';
import { fetchLocationAtTimestamp } from '../utils/dashboardTimescaleQueries';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleCheck,
  faBell,
  faVolumeUp,
  faChevronDown,
  faCompass,
  faShield,
  faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';

const ALERT_REFRESH_INTERVAL = 60 * 1000;
const ALERTS_PER_PAGE = 20;

const createCustomIcon = (status, isActive = false) => {
  let statusClass;
  switch (status) {
    case 'Recording':
      statusClass = 'in-shift';
      break;
    case 'In Shift':
      statusClass = 'in-shift';
      break;
    case 'Offline':
      statusClass = 'offline';
      break;
    case 'Unknown':
      statusClass = 'default';
      break;
    default:
      statusClass = 'default';
  }

  return L.divIcon({
    className: `circle-icon ${statusClass}`,
    html: `<img src="/plix_logo_black.svg" alt="Device Logo"/>`,
    iconSize: [32, 32],
    iconAnchor: [16, 16],
    popupAnchor: [0, -16],
  });
};

const MetricsDisplay = React.memo(({ metrics, deviceMetrics, isOverwatch }) => {
  if (!isOverwatch) return null;

  const formatFraction = (fraction) => {
    return `${fraction.numerator} / ${fraction.denominator}`;
  };

  return (
    <div className="metrics-display">
      {deviceMetrics && (
        <Tooltip
          title={<span style={{ fontSize: '0.8rem' }}>Devices in shift within the last 14 days</span>}
          placement="top-start"
          arrow
        >
          <div className="metric-pill">
            <span className="metric-label">Active Devices</span>
            <span className="metric-value">{formatFraction(deviceMetrics)}</span>
          </div>
        </Tooltip>
      )}
      {metrics.labelingCoverage && (
        <Tooltip
          title={<span style={{ fontSize: '0.8rem' }}>Escalations labeled in the last 14 days</span>}
          placement="top-start"
          arrow
        >
          <div className="metric-pill">
            <span className="metric-label">Labeled</span>
            <span className="metric-value">{formatFraction(metrics.labelingCoverage)}</span>
          </div>
        </Tooltip>
      )}
      {metrics.labelingAccuracy && (
        <Tooltip
          title={<span style={{ fontSize: '0.8rem' }}>Escalations labeled in last 14 days as correct</span>}
          placement="top-start"
          arrow
        >
          <div className="metric-pill">
            <span className="metric-label">Accuracy</span>
            <span className="metric-value">{formatFraction(metrics.labelingAccuracy)}</span>
          </div>
        </Tooltip>
      )}
    </div>
  );
});

function RealTimeMap({ userId, config }) {
  const { user, logout } = useAuth0();
  const [showLogoutButton, setShowLogoutButton] = useState(false);

  const { sidebarOption } = useParams();
  const { devices, selectedOrg } = useDeviceFilter();
  const memoizedDevices = useMemo(() => devices, [devices]);
  const [cardsToDisplay, setCardsToDisplay] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalDevice, setModalDevice] = useState(null);
  const [currentLocation, setCurrentLocation] = useState([40.075044252652596, -98.80344386500497]);
  const [dotIcon, setDotIcon] = useState(faVolumeUp);
  const [isCollapsed, setIsCollapsed] = useState({
    devices: false,
    alerts: false,
  });
  const [activeDevice, setActiveDevice] = useState(null);
  const [hasNewAlerts, setHasNewAlerts] = useState(false);
  const [streamingDevices, setStreamingDevices] = useState({});
  const [keepAliveIntervals, setKeepAliveIntervals] = useState({});

  const [alerts, setAlerts] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);

  const markerRefs = useRef({});
  const pageLoadTime = useRef(new Date().toISOString());
  const mapRef = useRef(null);
  const firstDeviceSelected = useRef(false);
  // const latestAlertTimestamp = useRef(null);

  const firstMapFit = useRef(false);

  const [activeAlert, setActiveAlert] = useState(null);
  const [alertMarker, setAlertMarker] = useState(null);
  const alertMarkerRef = useRef(null);

  // Metrics

  const [deviceMetrics, setDeviceMetrics] = useState(null);
  const [labelingMetrics, setLabelingMetrics] = useState({ coverage: null, accuracy: null });
  const calculateDeviceMetrics = useCallback(() => {
    const thirteenDaysAgo = new Date();
    thirteenDaysAgo.setDate(thirteenDaysAgo.getDate() - 13);

    const activeDevices = memoizedDevices.filter((device) => {
      const lastShiftTime = new Date(device.lastShiftTime);
      return lastShiftTime >= thirteenDaysAgo;
    });

    setDeviceMetrics({
      numerator: activeDevices.length,
      denominator: memoizedDevices.length,
    });
  }, [memoizedDevices]);

  // Mobile panel

  const [isMobilePanelExpanded, setIsMobilePanelExpanded] = useState(false);
  const handleCloseMobilePanel = useCallback(() => {
    setIsMobilePanelExpanded(false);
  }, []);
  useEffect(() => {
    const handleMapClick = () => {
      handleCloseMobilePanel();
    };

    if (mapRef.current) {
      mapRef.current.on('click', handleMapClick);
    }

    return () => {
      if (mapRef.current) {
        mapRef.current.off('click', handleMapClick);
      }
    };
  }, [handleCloseMobilePanel]);
  const handleToggleMobilePanel = useCallback((isExpanded) => {
    setIsMobilePanelExpanded(isExpanded);
  }, []);

  // Logout button

  const handleLogoClick = (e) => {
    e.stopPropagation();
    setShowLogoutButton(!showLogoutButton);
  };

  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showLogoutButton && !event.target.closest('.mobile-user-controls')) {
        setShowLogoutButton(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [showLogoutButton]);

  // Fit map

  const fitMapToMarkers = useCallback(
    (mpRef) => {
      console.log('fitMapToMarkers: mpRef: ', mpRef, ' firstMapFit.current: ', firstMapFit.current);
      if (mpRef && memoizedDevices.length > 0) {
        const validDevices = memoizedDevices.filter(
          (device) => Math.abs(device.location[0]) > 0.1 && Math.abs(device.location[1]) > 0.1
        );
        console.log('validDevices: ', validDevices);
        if (validDevices.length > 0) {
          const bounds = L.latLngBounds(validDevices.map((device) => device.location));
          const center = bounds.getCenter();
          const mapSize = mpRef.getSize();

          // Calculate the size of the bounds
          const northEast = bounds.getNorthEast();
          const southWest = bounds.getSouthWest();
          const latDiff = northEast.lat - southWest.lat;
          const lngDiff = northEast.lng - southWest.lng;

          // Calculate the zoom level
          const WORLD_DIM = { height: 256, width: 256 };
          const ZOOM_MAX = 18;

          function latRad(lat) {
            const sin = Math.sin((lat * Math.PI) / 180);
            const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
            return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
          }

          function zoom(mapPx, worldPx, fraction) {
            return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
          }

          const latFraction = (latRad(northEast.lat) - latRad(southWest.lat)) / Math.PI;
          const lngDiff1 = lngDiff;
          const lngFraction = (lngDiff1 < 0 ? lngDiff1 + 360 : lngDiff1) / 360;

          const latZoom = zoom(mapSize.y, WORLD_DIM.height, latFraction);
          const lngZoom = zoom(mapSize.x, WORLD_DIM.width, lngFraction);

          const zoomLvl = Math.min(latZoom, lngZoom, ZOOM_MAX);

          console.log(`Map fit computation:
        Valid devices: ${validDevices.length}
        Bounds: ${bounds.toBBoxString()}
        Center: ${center.lat.toFixed(4)}, ${center.lng.toFixed(4)}
        Optimal zoom: ${zoomLvl.toFixed(2)}
        Map size: ${mapSize.x}x${mapSize.y}
        Lat diff: ${latDiff.toFixed(2)}, Lng diff: ${lngDiff.toFixed(2)}`);

          mpRef.setView(center, zoomLvl, { animate: false });
          firstMapFit.current = true;
        }
      }
    },
    [memoizedDevices]
  );

  useEffect(() => {
    if (mapRef.current) {
      fitMapToMarkers(mapRef.current);
    }
  }, [selectedOrg, fitMapToMarkers]);

  useEffect(() => {
    /**
     * Sets the current location to the location of the first device and centers the map on it.
     */
    console.log(memoizedDevices);
    const interval = setInterval(() => {
      if (memoizedDevices.length > 0 && !firstDeviceSelected.current) {
        // const firstDeviceLocation = memoizedDevices[0].location;
        // setCurrentLocation(firstDeviceLocation);
        const marker = markerRefs.current[memoizedDevices[0].deviceId];

        firstDeviceSelected.current = true;

        if (marker && marker._map) {
          fitMapToMarkers(marker._map);
          // marker._map.flyTo(firstDeviceLocation, 18, { animate: false });
          // setCurrentLocation(firstDeviceLocation);
          // firstDeviceSelected.current = true;
        }
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [memoizedDevices]);

  useEffect(() => {
    // Reset streaming devices when the component mounts
    setStreamingDevices({});
  }, []);

  const [fetchError, setFetchError] = useState(null);

  const updateAlerts = useCallback(
    async (forceUpdate = false) => {
      console.log('updateAlerts with devices: ', memoizedDevices, 'activeDevice: ', activeDevice);
      if (memoizedDevices.length === 0) return;

      const deviceIds = activeDevice ? [activeDevice] : memoizedDevices.map((d) => d.deviceId);
      console.log('deviceIds: ', deviceIds);

      try {
        const {
          alerts: fetchedAlerts,
          hasMore,
          error,
        } = await fetchRecentEscalationAlerts(userId, deviceIds, 1, ALERTS_PER_PAGE);

        if (error) {
          setFetchError(error);
          return;
        }

        console.log('updateAlerts: ', fetchedAlerts);
        setAlerts((prevAlerts) => {
          if (!isEqual(prevAlerts, fetchedAlerts)) {
            return fetchedAlerts;
          }
          return prevAlerts;
        });
        setHasMore(hasMore);
        setPage(1);
        setHasNewAlerts(false);
        setFetchError(null);

        // Fetch and set labeling metrics
        const metrics = await getAlertLabelingMetrics(userId, deviceIds);
        setLabelingMetrics((prevMetrics) => {
          if (!isEqual(prevMetrics, metrics)) {
            return metrics;
          }
          return prevMetrics;
        });
      } catch (error) {
        console.error('Error updating alerts:', error);
        setFetchError('Failed to update alerts. Please check your network connection and refresh the page.');
      }
    },
    [userId, memoizedDevices, activeDevice]
  );

  useEffect(() => {
    console.log('Effect triggered, activeDevice:', activeDevice);
    updateAlerts();
    calculateDeviceMetrics();
    const interval = setInterval(() => {
      updateAlerts();
      calculateDeviceMetrics();
    }, ALERT_REFRESH_INTERVAL);
    return () => clearInterval(interval);
  }, [activeDevice, updateAlerts, calculateDeviceMetrics]);

  const loadMoreAlerts = async () => {
    const nextPage = page + 1;
    const deviceIds = activeDevice ? [activeDevice] : memoizedDevices.map((d) => d.deviceId);
    const { alerts: newAlerts, hasMore } = await fetchRecentEscalationAlerts(
      userId,
      deviceIds,
      nextPage,
      ALERTS_PER_PAGE
    );
    setAlerts((prevAlerts) => [...prevAlerts, ...newAlerts]);
    setPage(nextPage);
    setHasMore(hasMore);
  };

  const sendMessageToDevice = async (deviceId, command) => {
    /**
     * Sends a message to the device to start or stop the livestream.
     * The message is sent to the Azure function which sends the message to the device's FCM token.
     */
    const azureFunctionUrl = 'https://plixnotifyfirebase.azurewebsites.net/api/notifyfirebase';
    // const azureFunctionUrl = 'https://plixnotifyfirebase';
    // Look up the device in the devices context to find the fcmToken
    const device = memoizedDevices.find((d) => d.deviceId === deviceId);
    const fcmToken = device.fcmToken;
    console.log('DEVICE:', device);
    console.log('SENDING MESSAGE:', command);
    console.log('FCM TOKEN BEING SENT:', fcmToken);

    try {
      const response = await axios.post(azureFunctionUrl, { fcmToken, command });
      console.log('Successfully sent message:', response.data);
    } catch (error) {
      console.error('Error sending message:', error.response ? error.response.data : error.message);
    }
  };

  const sendKeepAlive = async (deviceId) => {
    /**
     * Sends a keep-alive message to the device to keep the livestream alive.
     * @param {string} deviceId - The ID of the device to send the keep-alive message to.
     */

    try {
      await sendMessageToDevice(deviceId, 'KEEP ALIVE');
      console.log('Sent keep-alive message for device:', deviceId);
    } catch (error) {
      console.error('Failed to send keep-alive message:', error);
    }
  };

  const handleToggleStream = (deviceId) => {
    setStreamingDevices((prev) => {
      /**
       * Toggles the streaming status of the specified deviceId.
       * Sends a START LIVESTREAM message to the device to start the livestream.
       * Starts a keep-alive interval to keep the livestream alive.
       * @param {string} deviceId - The ID of the device to toggle the streaming status for.
       * @returns {object} - The new state object with the streaming status for the specified deviceId.
       */
      const newState = { ...prev, [deviceId]: !prev[deviceId] };
      if (newState[deviceId]) {
        sendMessageToDevice(deviceId, 'START LIVESTREAM');

        // Start keep-alive interval
        const intervalId = setInterval(() => sendKeepAlive(deviceId), 55000); // 55 seconds
        setKeepAliveIntervals((prevIntervals) => ({
          ...prevIntervals,
          [deviceId]: intervalId,
        }));
      } else {
        // Clear keep-alive interval
        if (keepAliveIntervals[deviceId]) {
          clearInterval(keepAliveIntervals[deviceId]);
          setKeepAliveIntervals((prevIntervals) => {
            const newIntervals = { ...prevIntervals };
            delete newIntervals[deviceId];
            return newIntervals;
          });
        }
      }
      return newState;
    });
  };

  const handleDeviceClick = useCallback((deviceId, location) => {
    console.log('Device clicked:', deviceId);
    setCurrentLocation(location);
    firstDeviceSelected.current = true;

    // Reset z-index and remove active class from all markers
    Object.values(markerRefs.current).forEach((marker) => {
      if (marker && marker.setZIndexOffset) {
        marker.setZIndexOffset(0);
      }
    });

    // Set the clicked marker as active and bring it to the front
    const marker = markerRefs.current[deviceId];
    if (marker) {
      marker.openPopup();
      if (marker.setZIndexOffset) {
        marker.setZIndexOffset(1000);
      }
    }

    // Center the map on the clicked device's location
    const map = marker._map;
    if (map) {
      map.flyTo(location, 18, { animate: false });
    }
  }, []);

  const handleCloseAlertPopup = useCallback(() => {
    setActiveAlert(null);
    setAlertMarker(null);
    if (alertMarkerRef.current) {
      alertMarkerRef.current.remove();
    }
  }, []);

  const handleCardClick = useCallback(
    async (alert) => {
      if (mapRef) {
        const mpRef = mapRef.current;
        const location = await fetchLocationAtTimestamp(alert.DeviceId, alert.Timestamp);
        console.log('location: ', location);
        if (location) {
          // Close any existing alert popup
          handleCloseAlertPopup();

          setActiveAlert({ ...alert, location });
          setAlertMarker(location);
          mpRef.flyTo(location, 18, { animate: false });
          setTimeout(() => {
            if (alertMarkerRef.current) {
              alertMarkerRef.current.openPopup();
            }
          }, 100);
          handleCloseMobilePanel();
        }
      }
    },
    [handleCloseAlertPopup, handleCloseMobilePanel]
  );

  const handleDeviceSelect = useCallback(
    (deviceId) => {
      const device = memoizedDevices.find((d) => d.deviceId === deviceId);
      console.log('Device selected:', device);
      if (device) {
        handleDeviceClick(deviceId, device.location);
        handleCloseMobilePanel();
      }
    },
    [memoizedDevices, handleDeviceClick, updateAlerts]
  );

  const handleClosePopup = (deviceId) => {
    /**
     * Handles the close popup by setting the device filter, active device, and streaming devices.
     * @param {string} deviceId - The ID of the device to stop streaming.
     */
    setActiveDevice(null);
    setStreamingDevices((prev) => ({ ...prev, [deviceId]: false }));
    // Clear keep-alive interval
    if (keepAliveIntervals[deviceId]) {
      clearInterval(keepAliveIntervals[deviceId]);
      setKeepAliveIntervals((prevIntervals) => {
        const newIntervals = { ...prevIntervals };
        delete newIntervals[deviceId];
        return newIntervals;
      });
    }
  };

  const toggleCollapse = (containerType) => {
    /**
     * Toggles the collapse state of the specified container type.
     * @param {string} containerType - The type of container to toggle the collapse state for.
     */
    setIsCollapsed((prevState) => ({
      ...prevState,
      [containerType]: !prevState[containerType],
    }));
  };

  const handleOpenPopup = useCallback(
    (deviceId) => {
      console.log('Popup opened for device:', deviceId);
      setActiveDevice(deviceId);
      handleCloseMobilePanel();
    },
    [updateAlerts]
  );

  const NoAlertsMessage = () => (
    <div className="no-alerts-message">
      <FontAwesomeIcon icon={faShield} size="3x" color="#d3d3d3" />
      <p>
        No escalations detected yet
        {activeDevice && memoizedDevices.find((d) => d.deviceId === activeDevice)
          ? ` for ${memoizedDevices.find((d) => d.deviceId === activeDevice).assignedTo || activeDevice}`
          : ''}
      </p>
    </div>
  );

  const renderContent = () => {
    /**
     * Renders the content of the real-time map.
     */
    console.log('devices in real time map', memoizedDevices);

    switch (sidebarOption) {
      case 'dashboard':
        return <Dashboard transitionsConfig={config.transitionsConfig} userId={userId} />;
      case 'video':
        return <VideoManagement />;
      default:
        return (
          <div style={{ flex: 1, position: 'relative' }}>
            <div className="mobile-user-controls">
              <div className="mobile-user-logo" onClick={handleLogoClick}>
                {user.email.endsWith('cpgsecure.com') ? (
                  <img className="user-avatar-logo" src="/logos/cpg_logo.webp" alt="User Logo" />
                ) : (
                  <img className="user-avatar" src={user.picture} alt="User Avatar" />
                )}
              </div>
              <button
                className={`mobile-logout-button ${showLogoutButton ? 'show' : ''}`}
                onClick={handleLogout}
                style={{
                  transform: showLogoutButton ? 'translateX(-50%) translateY(0)' : 'translateX(-50%) translateY(-10px)',
                }}
              >
                <FontAwesomeIcon icon={faSignOutAlt} /> Logout
              </button>
            </div>
            <MapContainer
              // center={devices.length > 0 ? devices[0].location : [40.075044252652596, -98.80344386500497]}
              // zoom={devices.length > 0 ? 18 : 0} // Default zoom level if devices array is empty
              // style={{ height: '100vh', width: '100%' }}
              // zoomControl={false}
              center={
                memoizedDevices.length > 0 ? memoizedDevices[0].location : [40.075044252652596, -98.80344386500497]
              }
              zoom={memoizedDevices.length > 0 ? 18 : 0}
              style={{ height: '100vh', width: '100%' }}
              zoomControl={false}
              whenCreated={(map) => {
                mapRef.current = map;
                // console.log('Map created!');
                // fitMapToMarkers();
              }}
              ref={mapRef}
            >
              <TileLayer
                url={`https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/{z}/{x}/{y}?access_token=${process.env.REACT_APP_MAPBOX_PUBLIC_TOKEN}`}
                attribution='&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> contributors'
                tileSize={512}
                zoomOffset={-1}
              />
              {memoizedDevices.map((device, index) => (
                <Marker
                  key={index}
                  position={device.location}
                  icon={createCustomIcon(device.deviceState, activeDevice === device.deviceId)}
                  ref={(el) => (markerRefs.current[device.deviceId] = el)}
                  eventHandlers={{
                    popupopen: (e) => {
                      handleOpenPopup(device.deviceId);
                      const closeButton = e.popup._closeButton;
                      if (closeButton) {
                        closeButton.style.width = '30px';
                        closeButton.style.height = '30px';
                        closeButton.style.fontSize = '24px';
                        closeButton.style.lineHeight = '30px';
                      }
                    },
                    popupclose: () => handleClosePopup(device.deviceId),
                  }}
                >
                  <Popup className="video-popup">
                    <div style={{ paddingLeft: '3px' }} className="video-popup-inner">
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: '10px',
                          justifyContent: 'space-between',
                        }}
                        className="live-popup-header"
                      >
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                          <span
                            style={{ fontWeight: 'bold', marginRight: '0px', cursor: 'pointer' }}
                            onClick={() => {
                              setShowModal(true);
                            }}
                            className="assigned-to"
                          >
                            {device.assignedTo}
                          </span>
                          <span className="device-id">{device.deviceId}</span>
                          <span className="device-status">
                            <FontAwesomeIcon
                              icon={getBatteryIcon(device.batteryStatus)}
                              style={{ marginLeft: '0px', marginRight: '5px' }}
                            />
                            {device.batteryStatus}%
                          </span>
                          {/* <span style={{ marginLeft: '20px' }} className="device-signal-strength">
                            <SignalStrength strength={device.signalStrength} />
                          </span> */}
                        </div>
                      </div>
                      {device.config.streamAddress.endsWith('.m3u8') &&
                      device.deviceState !== 'Offline' &&
                      device.deviceState !== 'Unknown' &&
                      (device.liveViewPolicy === 'Only During Recording'
                        ? device.deviceState === 'Recording'
                        : device.liveViewPolicy === undefined || device.liveViewPolicy === 'During Shift') ? (
                        <HLSVideoPlayer
                          hlsUrl={device.config.streamAddress}
                          deviceId={device.deviceId}
                          isStreaming={streamingDevices[device.deviceId] || false}
                          onToggleStream={handleToggleStream}
                          onError={(error) => console.error('HLS Player Error:', error)}
                        />
                      ) : device.deviceState === 'Offline' ? (
                        <div className="offline-message">
                          <p>Device is offline</p>
                        </div>
                      ) : device.liveViewPolicy === 'Only During Recording' && device.deviceState !== 'Recording' ? (
                        <div className="offline-message">
                          <p>Live view can only be started while recording.</p>
                        </div>
                      ) : (
                        <div className="offline-message">
                          <p>Live stream not available</p>
                        </div>
                      )}
                    </div>
                  </Popup>
                </Marker>
              ))}
              {alertMarker && activeAlert && (
                <Marker
                  position={alertMarker}
                  icon={L.divIcon({
                    className: 'alert-marker',
                    html: '<div style="background-color: red; width: 20px; height: 20px; border-radius: 50%; opacity: 0.7;"></div>',
                    iconSize: [20, 20],
                    iconAnchor: [10, 10],
                  })}
                  ref={alertMarkerRef}
                  eventHandlers={{
                    popupopen: (e) => {
                      const closeButton = e.popup._closeButton;
                      if (closeButton) {
                        closeButton.style.width = '30px';
                        closeButton.style.height = '30px';
                        closeButton.style.fontSize = '24px';
                        closeButton.style.lineHeight = '30px';
                      }
                    },
                    popupclose: handleCloseAlertPopup,
                  }}
                >
                  <Popup className="video-popup" onClose={handleCloseAlertPopup}>
                    {activeAlert && <AlertPopup alert={activeAlert} />}
                  </Popup>
                </Marker>
              )}
              <MetricsDisplay
                metrics={labelingMetrics}
                deviceMetrics={deviceMetrics}
                isOverwatch={userId === PLIX_OVERWATCH_ACCOUNT}
              />

              <ZoomControl position="bottomright" />
            </MapContainer>
            {modalDevice && (
              <Modal isOpen={showModal} onClose={() => setShowModal(false)} modalDevice={modalDevice}>
                {modalDevice.config.streamAddress.endsWith('.m3u8') ? (
                  <HLSVideoPlayer src={modalDevice.config.streamAddress} style={{ width: '100%', height: 'auto' }} />
                ) : (
                  <img
                    src={modalDevice.config.streamAddress}
                    alt="Full Size Stream"
                    style={{ width: '100%', height: 'auto' }}
                  />
                )}
              </Modal>
            )}
            <div className={`devices-container ${isCollapsed.devices ? 'collapsed' : 'expanded'}`}>
              <div className="devices-header" onClick={() => toggleCollapse('devices')}>
                <FontAwesomeIcon icon={faCompass} style={{ color: 'red', marginRight: '5px' }} />
                <h3 style={{ flexGrow: 1, margin: 0 }}>Devices</h3>
                <Tooltip title={isCollapsed.devices ? 'Show More' : 'Collapse'}>
                  <FontAwesomeIcon icon={faChevronDown} className="collapse-icon" style={{ cursor: 'pointer' }} />
                </Tooltip>
              </div>
              <div className="content">
                <DeviceAssignment handleDeviceClick={handleDeviceClick} />
              </div>
            </div>
            <div className={`alerts-container ${isCollapsed.alerts ? 'collapsed' : 'expanded'}`}>
              <div className="alerts-header" onClick={() => toggleCollapse('alerts')}>
                <FontAwesomeIcon icon={faBell} style={{ color: 'red', marginRight: '5px' }} />
                <h3 style={{ flexGrow: 1, margin: 0 }}>Alerts</h3>
                <span className="current-time">{format(new Date(), 'h:mm a')}</span>
                <Tooltip title={isCollapsed.alerts ? 'Show More' : 'Collapse'}>
                  <FontAwesomeIcon icon={faChevronDown} className="collapse-icon" style={{ cursor: 'pointer' }} />
                </Tooltip>
              </div>
              <div className="content" id="alertsScrollContainer">
                {fetchError ? (
                  <div className="error-message">{fetchError}</div>
                ) : alerts && alerts.length > 0 ? (
                  <InfiniteScroll
                    dataLength={alerts.length}
                    next={loadMoreAlerts}
                    hasMore={hasMore}
                    loader={<div className="loader-text">Loading more alerts...</div>}
                    scrollableTarget="alertsScrollContainer"
                  >
                    {alerts.map((alert) => (
                      <Card
                        userId={userId}
                        key={alert.id}
                        initialCard={alert}
                        icon={config.transitionsConfig.find((t) => t.title === alert.Label)?.icon}
                        onDeviceSelect={handleDeviceSelect}
                        onCardClick={handleCardClick}
                      />
                    ))}
                  </InfiniteScroll>
                ) : (
                  <NoAlertsMessage />
                )}
              </div>
            </div>
          </div>
        );
    }
  };

  const refreshButtonClass = hasNewAlerts ? 'refresh-button pulsate' : 'refresh-button';

  useEffect(() => {
    /**
     * Clears all keep-alive intervals when the component unmounts.
     */
    return () => {
      // Clear all keep-alive intervals when component unmounts
      Object.values(keepAliveIntervals).forEach(clearInterval);
    };
  }, [keepAliveIntervals]);

  return (
    <div className="app-container">
      {renderContent()}
      <MobilePanel
        userId={userId}
        devices={memoizedDevices}
        alerts={alerts}
        handleDeviceClick={handleDeviceClick}
        handleCardClick={handleCardClick}
        config={config}
        activeDevice={activeDevice}
        NoAlertsMessage={NoAlertsMessage}
        isExpanded={isMobilePanelExpanded}
        onToggle={handleToggleMobilePanel}
        handleDeviceSelect={handleDeviceSelect}
      />
    </div>
  );
}

export default RealTimeMap;
