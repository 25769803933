// LabelAlertPopup.js
import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import '../styles/LabelAlertPopup.css';

import categoriesConfig from '../config/categories.json';

const categories = categoriesConfig.categories.map((category) => ({
  label: category.name,
  description: category.description,
}));

// CLAP categories ----------------------------------------------------------------
const categoryGroupsCLAP = {
  screaming: ['a person screaming', 'a person shouting', 'a person yelling'],
  laughter: ['a sound of laughter', 'a person laughing', 'a person giggling'],
  normal_conversation: [
    'a person talking',
    'a person having a casual conversation',
    'a person having a normal conversation',
    'a person talking to another person',
  ],
};

const getCategoryForLabel = (label) => {
  for (const [category, labels] of Object.entries(categoryGroupsCLAP)) {
    if (labels.includes(label)) {
      return category;
    }
  }
  return null;
};
/**
 * Coalescing strategy for CLAP categories
 * We create a coalesceCategories function in here that processes the CLAP results.
 * For each segment (segment 0-7; 15 sec each), it checks if the top three labels belong to the same category and
 * if their average score is above 0.2.
 * If both conditions are met, the category is added to the result set.
 * In the SearchResult component, we use this function to get the coalesced categories and display them in the tooltip.
 * check plixeventstorage for raw CLAP results:
 * `WHERE IS_DEFINED(c.VideoFileName) AND IS_DEFINED(c.EscalationCategory_CLAP) AND CONTAINS(c.VideoFileName, 'escalation', true)`
 * @param {*} clapCategory
 * @returns array of coalesced categories
 */
export const coalesceCategories = (clapCategory) => {
  const coalescedCategories = new Set();

  Object.values(clapCategory).forEach((segment) => {
    const sortedLabels = Object.entries(segment)
      .sort(([, a], [, b]) => b - a)
      .slice(0, 3);

    const category = getCategoryForLabel(sortedLabels[0][0]);
    if (category) {
      const allSameCategory = sortedLabels.every(([label]) => getCategoryForLabel(label) === category);
      const averageScore = sortedLabels.reduce((sum, [, score]) => sum + score, 0) / 3;

      if (allSameCategory && averageScore > 0.2) {
        coalescedCategories.add(category);
      }
    }
  });

  return Array.from(coalescedCategories);
};
// ----------------------------------------------------------------

const LabelAlertPopup = ({ open, onClose, onSubmit, initialValues }) => {
  const [classificationLabel, setClassificationLabel] = useState(initialValues.ClassificationLabel || 'Unlabeled');
  const [categorizationLabels, setCategorizationLabels] = useState([]);
  const [isDeleted, setIsDeleted] = useState(initialValues.IsDeleted || false);

  useEffect(() => {
    setClassificationLabel(initialValues.ClassificationLabel || 'Unlabeled');
    setCategorizationLabels(
      initialValues.CategorizationLabel ? initialValues.CategorizationLabel.split(',').map((label) => label.trim()) : []
    );
    setIsDeleted(initialValues.IsDeleted || false);
  }, [initialValues]);

  const handleClassificationChange = (_, newValue) => {
    if (newValue !== null) {
      setClassificationLabel(newValue);
    }
  };

  const handleVisibilityChange = (_, newValue) => {
    if (newValue !== null) {
      setIsDeleted(newValue === 'Delete');
    }
  };

  const handleSubmit = () => {
    onSubmit({
      ClassificationLabel: classificationLabel,
      CategorizationLabel: categorizationLabels
        .map((label) => (typeof label === 'string' ? label : label.label))
        .join(','),
      IsDeleted: isDeleted,
    });
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="label-alert-modal-title"
      aria-describedby="label-alert-modal-description"
    >
      <div className="label-alert-modal-container">
        <h2 id="label-alert-modal-title">Label Escalation</h2>
        <div className="label-alert-content">
          <div className="label-alert-section">
            <label>Classification Label</label>
            <ToggleButtonGroup
              value={classificationLabel}
              exclusive
              onChange={handleClassificationChange}
              className="toggle-button-group"
            >
              <ToggleButton value="Unlabeled">Unlabeled</ToggleButton>
              <ToggleButton value="Correct">Correct</ToggleButton>
              <ToggleButton value="Error">Error</ToggleButton>
            </ToggleButtonGroup>
          </div>
          <div className="label-alert-section">
            <label>Escalation Categories</label>
            <Autocomplete
              multiple
              freeSolo
              options={categories}
              value={categorizationLabels}
              onChange={(_, newValue) => setCategorizationLabels(newValue)}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    label={typeof option === 'string' ? option : option.label}
                    {...getTagProps({ index })}
                    key={index}
                  />
                ))
              }
              renderInput={(params) => <TextField {...params} />}
              renderOption={(props, option) => (
                <li {...props}>
                  <div>
                    {option.label}
                    <br />
                    <span className="category-description">{option.description}</span>
                  </div>
                </li>
              )}
              getOptionLabel={(option) => (typeof option === 'string' ? option : option.label)}
              isOptionEqualToValue={(option, value) => option.label === value || option.label === value.label}
              className="autocomplete"
            />
          </div>
          <div className="label-alert-section">
            <label>Customer Visibility</label>
            <ToggleButtonGroup
              value={isDeleted ? 'Delete' : 'Show'}
              exclusive
              onChange={handleVisibilityChange}
              className="toggle-button-group"
            >
              <ToggleButton value="Show">Show</ToggleButton>
              <ToggleButton value="Delete">Delete</ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>
        <div className="label-alert-buttons">
          <button onClick={onClose} className="cancel-button">
            Cancel
          </button>
          <button onClick={handleSubmit} className="submit-button">
            Submit
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default LabelAlertPopup;
