import React, { useEffect, useState, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup, Circle, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

const BoundsAdjuster = ({ clusters }) => {
  const map = useMap();

  useEffect(() => {
    if (clusters.length) {
      const bounds = clusters.map((cluster) => [cluster.coordinates.latitude, cluster.coordinates.longitude]);
      map.fitBounds(bounds);
    }
  }, [clusters, map]);

  return null;
};

const HeatmapMap = ({ clusters, onMarkerClick }) => {
  const [fullscreen, setFullscreen] = useState(false);
  const mapRef = useRef();

  const toggleFullscreen = () => {
    setFullscreen(!fullscreen);
  };

  const formatDuration = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return `${hours}h ${minutes}m`;
  };

  // Convert miles to pixels based on zoom level and latitude
  const milesToPixels = (miles, lat, zoom) => {
    const earthCircumference = 256 * Math.pow(2, zoom);
    const latRad = lat * (Math.PI / 180);
    return (miles * 1609.34 * earthCircumference) / (2 * Math.PI * 6378137 * Math.cos(latRad));
  };

  // Determine label color based on duration
  const getColor = (duration) => {
    if (duration < 3600) {
      // Less than 1 hour
      return { backgroundColor: 'yellow', color: 'black' };
    } else if (duration < 18000) {
      // Less than 5 hours
      return { backgroundColor: 'orange', color: 'black' };
    } else {
      return { backgroundColor: 'red', color: 'white' };
    }
  };

  // Create a custom icon with duration label and triangle
  const createCustomIcon = (duration) => {
    const { backgroundColor, color } = getColor(duration);
    return L.divIcon({
      className: 'custom-icon',
      html: `
        <div style="position: relative; display: flex; flex-direction: column; align-items: center;">
          <div class="duration-label" style="background-color: ${backgroundColor}; color: ${color};">
            ${formatDuration(duration)}
          </div>
          <div class="triangle" style="border-top-color: ${backgroundColor};"></div>
        </div>
      `,
      iconSize: [20, 30], // Adjust icon size if needed
      iconAnchor: [10, 30], // Center of the icon
      popupAnchor: [0, -30], // Adjust to position the popup correctly
    });
  };

  const handleMarkerClick = (map, coordinates) => {
    map.setView([coordinates.latitude, coordinates.longitude], 16, {
      animate: true,
    });
    onMarkerClick(coordinates);
  };

  const MapEventHandler = ({ clusters }) => {
    const map = useMap();

    return (
      <>
        {clusters.map((cluster, index) => (
          <Marker
            key={index}
            position={[cluster.coordinates.latitude, cluster.coordinates.longitude]}
            icon={createCustomIcon(cluster.duration)}
            eventHandlers={{
              click: () => handleMarkerClick(map, cluster.coordinates),
            }}
          >
            <Popup>
              <div style={{ padding: '5px 10px' }}>
                <strong>Time spent here:</strong> {formatDuration(cluster.duration)}
                <br />
                <strong>Number of devices here:</strong> {cluster.devices.length}
              </div>
            </Popup>
            <Circle
              center={[cluster.coordinates.latitude, cluster.coordinates.longitude]}
              radius={milesToPixels(0.25, cluster.coordinates.latitude, 13)} // Convert 1/4 mile to pixels
              pathOptions={{ color: 'red', fillColor: 'red', fillOpacity: 0.2, stroke: false }}
            />
          </Marker>
        ))}
      </>
    );
  };

  return (
    <div className={fullscreen ? 'map-container fullscreen' : 'map-container'}>
      {fullscreen && (
        <MapContainer
          center={[0, 0]}
          zoom={13}
          style={{ height: '100%', width: '100%', zIndex: 1 }}
          whenCreated={(mapInstance) => (mapRef.current = mapInstance)}
        >
          <TileLayer
            url={`https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/{z}/{x}/{y}?access_token=${process.env.REACT_APP_MAPBOX_PUBLIC_TOKEN}`}
            attribution='&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> contributors'
            tileSize={512}
            zoomOffset={-1}
          />
          <MapEventHandler clusters={clusters} />
          <BoundsAdjuster clusters={clusters} />
        </MapContainer>
      )}
      {!fullscreen && (
        <MapContainer
          center={[0, 0]}
          zoom={13}
          style={{ height: '100%', width: '100%', zIndex: 1 }}
          whenCreated={(mapInstance) => (mapRef.current = mapInstance)}
        >
          <TileLayer
            url={`https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/{z}/{x}/{y}?access_token=${process.env.REACT_APP_MAPBOX_PUBLIC_TOKEN}`}
            attribution='&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> contributors'
            tileSize={512}
            zoomOffset={-1}
          />
          <MapEventHandler clusters={clusters} />
          <BoundsAdjuster clusters={clusters} />
        </MapContainer>
      )}
      <button className="fullscreen-toggle" onClick={toggleFullscreen}>
        {fullscreen ? 'Close ×' : 'Fullscreen ⛶'}
      </button>
    </div>
  );
};

export default HeatmapMap;
