import '../../../../../../../styles/NewDashboard/ShiftReview/ShiftCard/ShiftExpanded/EventInfo/EventInfo.css';
import LocationInfo from './LocationInfo';
import ClickableTranscripts from './ClickableTranscripts';
import MapView from '../EventDetail/MapView';
import SingleMapView from './SingleMapView';

const EventInfo = ({ showVideo, selectedEvent, alertData, assignedTo, allEvents }) => {
  return (
    <div className="event-info-container">
      {selectedEvent.event.type === 'escalation' ? (
        <>
          <div className="event-info-map">
            <SingleMapView location={selectedEvent.event.location} size="small" />
          </div>
          <LocationInfo event={selectedEvent} assignedTo={assignedTo} alertData={alertData} allEvents={allEvents} />
          {/* <ClickableTranscripts event={selectedEvent} alertData={alertData} /> */}
        </>
      ) : (
        <>
          <LocationInfo event={selectedEvent} assignedTo={assignedTo} alertData={alertData} allEvents={allEvents} />
        </>
      )}
    </div>
  );
};

export default EventInfo;
