import { getDistance } from 'geolib';
import { getRecentAlerts, fetchEscalations, fetchDeviceLogs, fetchVideos } from './dashboardCosmosQueries';
import { fetchTimescaleData } from './dashboardTimescaleQueries';
import { calculateMetrics } from './dashboardMetricsHelpers';
import { toast } from 'react-toastify';
// -------------------- Loading Alerts --------------------

export const getInteractions = async (
  databaseIdAlerts,
  containerIdAlerts,
  userId,
  featureMode,
  deviceIds,
  dateRange,
  logs,
  page,
  itemsPerPage
) => {
  const alerts = await getRecentAlerts(databaseIdAlerts, containerIdAlerts, userId, deviceIds, dateRange);

  let escalationAlerts = alerts.filter(
    (alert) =>
      (alert.Label === 'Escalation' || alert.Label === 'Help' || alert.Label === 'Keyword') &&
      alert.VideoFileName &&
      alert.VideoFileName !== ''
  );

  if (featureMode === 'prod') {
    escalationAlerts = escalationAlerts.filter((alert) => alert.ClassificationLabel !== 'Error');
  }

  escalationAlerts.sort((a, b) => {
    if (a.DeviceId === b.DeviceId) {
      return new Date(a.Timestamp) - new Date(b.Timestamp);
    }
    return a.DeviceId.localeCompare(b.DeviceId);
  });

  const deduplicatedAlerts = [];
  for (let i = 0; i < escalationAlerts.length; i++) {
    if (
      i === 0 ||
      escalationAlerts[i].DeviceId !== escalationAlerts[i - 1].DeviceId ||
      new Date(escalationAlerts[i].Timestamp) - new Date(escalationAlerts[i - 1].Timestamp) > 10000
    ) {
      deduplicatedAlerts.push(escalationAlerts[i]);
    }
  }

  const demoAlerts = deduplicatedAlerts.filter((alert) => alert.IsDemo);
  const nonDemoAlerts = deduplicatedAlerts.filter((alert) => !alert.IsDemo);

  const twoDaysAgo = new Date();
  twoDaysAgo.setHours(0, 0, 0, 0);
  twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);

  const earliestTimestamp = nonDemoAlerts.reduce((min, alert) => {
    const alertDate = new Date(alert.Timestamp);
    alertDate.setHours(0, 0, 0, 0);
    return alertDate < min ? alertDate : min;
  }, new Date());

  const offsetDays = 0;

  nonDemoAlerts.forEach((alert) => {
    const originalTimestamp = new Date(alert.Timestamp);
    originalTimestamp.setDate(originalTimestamp.getDate() + offsetDays);
    alert.Timestamp = originalTimestamp.toISOString();
  });

  const createInteractions = (alerts) =>
    alerts.map((alert) => {
      const relevantLogs = logs.filter((log) => log.deviceId === alert.DeviceId);
      const lastLog = relevantLogs.length > 0 ? relevantLogs[relevantLogs.length - 1] : null;

      // Create a base interaction object
      const interaction = {
        id: alert.id,
        start: alert.Timestamp,
        deviceId: alert.DeviceId,
        assignedTo: alert.AssignedTo,
        thumbnail: alert.Thumbnail,
        alerts: [alert],
        summary: [alert.Description],
        interactionType: 'Incident',
        videoFileName: alert.VideoFileName,
        userId: userId,
        Prompt: alert.Prompt,
        VideoLabel: alert.VideoLabel,
        Transcripts: alert.Transcripts,
        VideoTranscripts: alert.VideoTranscripts,
        ClassificationLabel: alert.ClassificationLabel,
        CategorizationLabel: alert.CategorizationLabel,
        IsDeleted: alert.IsDeleted,
        _ts: alert._ts,
        IsDeviceRecording: lastLog
          ? new Date(lastLog.timestamp) <= new Date(alert.Timestamp) && lastLog.action === 'Start Recording'
          : false,
      };

      // Dynamically add all EscalationClass_ and EscalationCategory_ fields
      Object.keys(alert).forEach((key) => {
        if (key.startsWith('EscalationClass_') || key.startsWith('EscalationCategory_')) {
          interaction[key] = alert[key];
        }
      });

      return interaction;
    });

  const demoInteractions = createInteractions(demoAlerts);
  const nonDemoInteractions = createInteractions(nonDemoAlerts);

  let resultInteractions = [...demoInteractions, ...nonDemoInteractions];
  resultInteractions.sort((a, b) => {
    return new Date(b.start) - new Date(a.start);
  });

  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  resultInteractions = resultInteractions.slice(startIndex, endIndex);

  return {
    interactions: resultInteractions,
  };
};

export const loadInteractions = async (
  updateInteractions,
  logs,
  isLoadingMore,
  databaseIdAlerts,
  containerIdAlerts,
  userId,
  featureMode,
  deviceIds,
  dateRange,
  page,
  ITEMS_PER_PAGE,
  setInteractions,
  setNewAlertsAvailable,
  setHasMore,
  setIsLoadingMore,
  lastAlertTimestampRef
) => {
  try {
    const { interactions: newInteractions } = await getInteractions(
      databaseIdAlerts,
      containerIdAlerts,
      userId,
      featureMode,
      deviceIds,
      dateRange,
      logs,
      page,
      ITEMS_PER_PAGE
    );

    const latestTimestamp = newInteractions.length > 0 ? new Date(newInteractions[0].start).getTime() : null;

    if (latestTimestamp && (!lastAlertTimestampRef.current || latestTimestamp > lastAlertTimestampRef.current)) {
      if (!updateInteractions) setNewAlertsAvailable(true);
      lastAlertTimestampRef.current = latestTimestamp;
    }

    if (updateInteractions) {
      setInteractions((prevInteractions) => {
        if (isLoadingMore) {
          return [...prevInteractions, ...newInteractions];
        } else {
          return newInteractions;
        }
      });
      setNewAlertsAvailable(false);
      setHasMore(newInteractions.length === ITEMS_PER_PAGE);
      setIsLoadingMore(false);
    }
  } catch (error) {
    console.error('Failed to load interactions:', error);
    setIsLoadingMore(false);
  }
};

// -------------------- Fetching Location Activity Data --------------------

export const fetchLocationData = async (
  selectedDevices,
  dateRange,
  setIsLoadingLocations,
  setLoadedDevices,
  setLocationData,
  setLocationVisitData
) => {
  if (!selectedDevices.length || !dateRange[0] || !dateRange[1]) {
    return;
  }

  setIsLoadingLocations(true);
  setLoadedDevices(0);
  setLocationData([]);
  setLocationVisitData([]);

  const fromDate = dateRange[0].toISOString();
  const toDate = dateRange[1].toISOString();

  const formatDuration = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return `${hours}h ${minutes}m`;
  };

  for (const [index, device] of selectedDevices.entries()) {
    try {
      const { batteryData, locationData } = await fetchTimescaleData(device.deviceId, fromDate, toDate);

      const sortedBatteryData = batteryData;
      const sortedLocationData = locationData;

      // The rest of the function remains the same...

      let matchedLocations = [];
      for (let i = 0, j = 0; i < sortedLocationData.length; i++) {
        const location = sortedLocationData[i];
        const locationTime = new Date(location.time);

        while (
          j < sortedBatteryData.length &&
          new Date(sortedBatteryData[j].time) < new Date(locationTime.getTime() - 5 * 60000)
        ) {
          j++;
        }

        for (
          let k = j;
          k < sortedBatteryData.length &&
          new Date(sortedBatteryData[k].time) <= new Date(locationTime.getTime() + 5 * 60000);
          k++
        ) {
          const batteryTime = new Date(sortedBatteryData[k].time);
          if (Math.abs(batteryTime - locationTime) <= 5 * 60000) {
            matchedLocations.push({ ...location, batteryInfo: sortedBatteryData[k] });
            break;
          }
        }
      }

      let currentLocationData = [];
      let lastSignificantLocation = matchedLocations[0];
      let lastEndLocation = matchedLocations[0];

      for (let i = 0; i < matchedLocations.length; i++) {
        const current = matchedLocations[i];
        const prev = i > 0 ? matchedLocations[i - 1] : {};
        if (i === matchedLocations.length - 1) {
          lastEndLocation = current;
        }
        const timeElapsed = (new Date(lastEndLocation.time) - new Date(lastSignificantLocation.time)) / (60 * 1000);
        const timeElapsedNow = prev ? (new Date(current.time) - new Date(prev.time)) / (60 * 1000) : 24 * 60;
        const distance = getDistance(
          { latitude: lastSignificantLocation.latitude, longitude: lastSignificantLocation.longitude },
          { latitude: current.latitude, longitude: current.longitude }
        );

        if (distance > 1609.34 || timeElapsedNow > 4 * 60 || i === matchedLocations.length - 1) {
          if (timeElapsed > 15) {
            const duration = (new Date(lastEndLocation.time) - new Date(lastSignificantLocation.time)) / 1000;
            currentLocationData.push({
              device: device.assignedTo || device.deviceId,
              coordinates: { latitude: lastEndLocation.latitude, longitude: lastEndLocation.longitude },
              duration: duration,
              durationStr: formatDuration(duration),
              start: lastSignificantLocation.time,
              end: lastEndLocation.time,
            });
          }
          lastSignificantLocation = current;
          lastEndLocation = current;
        } else {
          lastEndLocation = current;
        }
      }

      const mergeVisits = (visits) => {
        const mergedVisits = [];
        visits.forEach((visit) => {
          let merged = false;
          for (let group of mergedVisits) {
            const distance = getDistance(
              { latitude: group.coordinates.latitude, longitude: group.coordinates.longitude },
              { latitude: visit.coordinates.latitude, longitude: visit.coordinates.longitude }
            );
            if (distance <= 1609.34 / 4) {
              group.coordinates.latitude =
                (group.coordinates.latitude * group.duration + visit.coordinates.latitude * visit.duration) /
                (group.duration + visit.duration);
              group.coordinates.longitude =
                (group.coordinates.longitude * group.duration + visit.coordinates.longitude * visit.duration) /
                (group.duration + visit.duration);
              group.duration += visit.duration;
              group.devices.add(visit.device);
              group.visits.push({ start: visit.start, end: visit.end, device: visit.device });
              merged = true;
              break;
            }
          }
          if (!merged) {
            mergedVisits.push({
              coordinates: { ...visit.coordinates },
              duration: visit.duration,
              devices: new Set([visit.device]),
              visits: [{ start: visit.start, end: visit.end, device: visit.device }],
            });
          }
        });
        return mergedVisits.map((visit) => ({
          ...visit,
          durationStr: formatDuration(visit.duration),
          devices: Array.from(visit.devices),
        }));
      };

      setLocationVisitData((prevData) => [...prevData, ...currentLocationData]);
      setLocationData((prevData) => [...prevData, ...mergeVisits(currentLocationData)]);
      setLoadedDevices(index + 1);
    } catch (error) {
      console.error(`Error fetching location data for device ${device.deviceId}:`, error);
    }
  }

  setIsLoadingLocations(false);
};

export const refreshMetrics = async (
  selectedDevices,
  setIsLoadingLocations,
  setLoadedDevices,
  dateRange,
  setNewAlertsAvailable,
  setIsLoading,
  setMetricsLoaded,
  setMetricsData,
  setSelectedCoordinates,
  featureMode,
  activeTab,
  fetchLocationData,
  setLocationData,
  setLocationVisitData
) => {
  console.log('refresh');
  console.trace();

  const deviceIds = selectedDevices.map((device) => device.deviceId);
  const fromDate = dateRange[0].toISOString();
  const toDate = dateRange[1].toISOString();

  setNewAlertsAvailable(false);
  setIsLoading(true);
  setMetricsLoaded(false);

  try {
    const [escalations, logs, videos] = await Promise.all([
      fetchEscalations(deviceIds, fromDate, toDate),
      fetchDeviceLogs(deviceIds, fromDate, toDate),
      fetchVideos(deviceIds, fromDate, toDate),
    ]);

    await calculateMetrics(selectedDevices, dateRange, escalations, logs, videos, setMetricsData);

    setMetricsLoaded(true);

    if (activeTab === 'escalations') {
      setIsLoading(false);
    }
    setSelectedCoordinates(null);
    if (featureMode === 'dev' && activeTab === 'activity') {
      await fetchLocationData(
        selectedDevices,
        dateRange,
        setIsLoadingLocations,
        setLoadedDevices,
        setLocationData,
        setLocationVisitData
      );
    }
  } catch (error) {
    console.error('Error refreshing metrics:', error);
    toast.error('Failed to refresh metrics. Please try again.');
  } finally {
    setIsLoading(false);
  }
};
