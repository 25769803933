import Metrics from './NewDashboard/Metrics/Metrics';
import ShiftReview from './NewDashboard/ShiftReview/ShiftReview';
import '../styles/NewDashboard/NewDashboard.css';
import { useState, useEffect } from 'react';
import { fetchDevicesFromUserId, fetchBatteryMetricsForDevices } from '../utils/dashboardCosmosQueries';
import { fetchOrganizations, BootstrapInput, MenuProps } from '../utils/dashboardUtils';
import { PLIX_OVERWATCH_ACCOUNT } from '../utils/utilsEvents';
import {
  FormControl,
  MenuItem,
  Select,
  Checkbox,
  ListItemText,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const BatteryMetrics = ({ userId }) => {
  const [devices, setDevices] = useState([]);
  const [metrics, setMetrics] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState('');
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [tempSelectedDeviceIds, setTempSelectedDeviceIds] = useState([]);
  const [selectedMetrics, setSelectedMetrics] = useState([]);

  useEffect(() => {
    fetchDevicesFromUserId(userId).then((devices) => {
      setDevices(devices[0].deviceIds);
      console.log('Devices array:');
      fetchBatteryMetricsForDevices(devices[0].deviceIds).then((res) => {
        setMetrics(res);
        console.log(res);
      });
    });
  }, [userId]);

  useEffect(() => {
    const getOrgs = async () => {
      await fetchOrganizations(setOrganizations);
    };
    getOrgs();
  }, [userId]);

  const handleOrganizationChange = (event, selectedMetrics, setSelectedMetrics) => {
    const selectedOrgName = event.target.value;
    setSelectedOrg(selectedOrgName);
    if (selectedOrgName === 'overwatch') {
      setSelectedMetrics(metrics);
    } else {
      let tempSelectedMetrics = [];
      metrics.forEach((metric) => {
        if (metric.organization === selectedOrgName) {
          console.log(metric);
          tempSelectedMetrics.push(metric);
        }
      });
      setSelectedMetrics(tempSelectedMetrics);
    }
  };

  return (
    <div className="newdashboard-container">
      <div className="newdashboard-header">
        <h1>Battery Metrics</h1>
        <div className="search-bar-container-dashboard">
          {userId === PLIX_OVERWATCH_ACCOUNT && (
            <FormControl sx={{ m: 1, minWidth: 300, maxWidth: 300 }} variant="standard">
              <Select
                labelId="org-select-label"
                id="org-select"
                value={selectedOrg}
                onChange={(event) => handleOrganizationChange(event, selectedMetrics, setSelectedMetrics)}
                input={<BootstrapInput />}
                renderValue={(selected) => {
                  if (!selected) return 'No Org Selected';
                  return selected;
                }}
                displayEmpty
                MenuProps={MenuProps}
              >
                <MenuItem value="">
                  <ListItemText primary="No Org Selected" />
                </MenuItem>
                {organizations.map((org) => (
                  <MenuItem key={org.id} value={org.orgName}>
                    <ListItemText primary={org.orgName} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </div>
      </div>
      <div className="newdashboard-body">
        {selectedMetrics.length > 0 ? (
          selectedMetrics.map((metric, index) => (
            <Accordion key={index}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
              >
                <Typography>{metric.deviceId}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <strong>Average Battery Used per Shift:</strong> {metric.avgBatteryUsedPerShift}%
                </Typography>
                <Typography>
                  <strong>Median Battery Used per Shift:</strong> {metric.medianBatteryUsage}%
                </Typography>

                <Typography>
                  <strong>Average Starting Battery Level:</strong> {metric.avgStartBatteryLevel}%
                </Typography>
                <Typography>
                  <strong>Median Starting Battery Level:</strong> {metric.medianStartBatteryLevel}%
                </Typography>

                <Typography>
                  <strong>Average Ending Battery Level:</strong> {metric.avgEndBatteryLevel}%
                </Typography>
                <Typography>
                  <strong>Median Ending Battery Level:</strong> {metric.medianEndBatteryLevel}%
                </Typography>

                <Typography>
                  <strong>Average Shift Duration (HH:MM) :</strong> {metric.avgShiftDuration}
                </Typography>
                <Typography>
                  <strong>Median Shift Duration (HH:MM) :</strong> {metric.medianShiftDuration}
                </Typography>

                {/* Add more fields as needed */}
              </AccordionDetails>
            </Accordion>
          ))
        ) : (
          <Typography>No metrics available for the selected organization</Typography>
        )}{' '}
      </div>
    </div>
  );
};

export default BatteryMetrics;
