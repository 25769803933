import React, { useState, useEffect, useMemo } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';

import { useDeviceFilter } from '../contexts/DeviceFilterContext';
import '../styles/DeviceAssignment.css';

const formatLastShiftTime = (lastShiftTime, shiftStatus) => {
  if (shiftStatus) return 'Now';
  if (!lastShiftTime) return '';
  const date = new Date(lastShiftTime);
  const now = new Date();
  if (date > now) return 'Future date'; // Handle potential timezone issues
  if (date.toDateString() === now.toDateString()) {
    return `Today at ${format(date, 'h:mm a')}`;
  }
  if (date.toDateString() === new Date(now.getTime() - 86400000).toDateString()) {
    return `Yesterday at ${format(date, 'h:mm a')}`;
  }
  if (date.getFullYear() === now.getFullYear()) {
    return format(date, 'M/d h:mm a');
  }
  return format(date, 'M/d/yyyy h:mm a');
};

const formatTimeInShift = (timeInShift) => {
  if (timeInShift === null) return '';
  if (timeInShift < 1) return 'Just started';
  if (timeInShift < 60) return `${timeInShift}m`;

  const hours = Math.floor(timeInShift / 60);
  const remainingMinutes = timeInShift % 60;
  return `${hours}h ${remainingMinutes}m`;
};

const DeviceAssignment = ({ handleDeviceClick }) => {
  const [rows, setRows] = useState([]);
  const [editedRow, setEditedRow] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const { devices, deviceShifts, updateDeviceAssignment } = useDeviceFilter();

  // Merge devices with shift data
  const devicesWithShiftData = useMemo(() => {
    return devices.map((device) => ({
      ...device,
      ...deviceShifts[device.deviceId], // Merge shift data
    }));
  }, [devices, deviceShifts]);

  const sortedRows = useMemo(() => {
    return [...devicesWithShiftData].sort((a, b) => {
      if (a.shiftStatus && !b.shiftStatus) return -1;
      if (!a.shiftStatus && b.shiftStatus) return 1;
      if (a.shiftStatus && b.shiftStatus) {
        // Sort by time in shift (ascending order)
        return (a.timeInShift || 0) - (b.timeInShift || 0);
      }
      // If neither is in shift, sort by last shift start time
      const aTime = a.lastShiftTime ? new Date(a.lastShiftTime) : new Date(0);
      const bTime = b.lastShiftTime ? new Date(b.lastShiftTime) : new Date(0);
      return bTime - aTime; // Most recent first
    });
  }, [devicesWithShiftData]);

  useEffect(() => {
    setRows(sortedRows);
  }, [sortedRows]);

  const handleProcessRowUpdate = async (newRow) => {
    await updateDeviceAssignment(newRow.deviceId, newRow.assignedTo);
    setEditedRow(newRow);
    setIsExpanded(true);
    return newRow;
  };

  const handleRowClick = (params) => {
    const deviceId = params.row.deviceId;
    if (deviceId) {
      handleDeviceClick(deviceId, [params.row.latitude, params.row.longitude]);
    } else {
      console.error('Device ID is missing in the row data');
    }
  };

  const columns = [
    {
      field: 'assignedTo',
      headerName: (
        <div>
          Owner <FontAwesomeIcon icon={faPen} style={{ marginLeft: '4px', fontSize: '0.6rem' }} />
        </div>
      ),
      width: 120,
      editable: true,
      renderCell: (params) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          <div style={{ fontSize: '0.875em' }}>{params.value || params.row.deviceName || 'Unassigned'}</div>
        </div>
      ),
    },
    {
      field: 'lastShiftTime',
      headerName: 'Last Shift',
      width: 130,
      renderCell: (params) => (
        <div style={{ fontSize: '0.875em' }}>{formatLastShiftTime(params.value, params.row.shiftStatus)}</div>
      ),
    },
    {
      field: 'timeInShift',
      headerName: 'Time In Shift',
      width: 120,
      renderCell: (params) => (
        <div style={{ fontSize: '0.875em' }}>{params.row.shiftStatus ? formatTimeInShift(params.value) : ''}</div>
      ),
    },
  ];

  return (
    <div className={`device-assignment-container ${isExpanded ? 'expanded' : 'collapsed'}`}>
      <div style={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={100}
          rowsPerPageOptions={[100]}
          getRowId={(row) => row.deviceId}
          processRowUpdate={handleProcessRowUpdate}
          onProcessRowUpdateError={(error) => {
            console.error('Error updating row:', error);
          }}
          onRowClick={handleRowClick}
          style={{ width: '100%', height: '100%' }}
          components={{
            Header: (props) => <div {...props} className="custom-header" />,
          }}
          componentsProps={{
            columnHeader: {
              style: {
                height: 30,
                fontSize: '0.875rem',
                lineHeight: '30px',
              },
            },
          }}
          hideFooter={rows.length <= 100}
          hideFooterPagination={rows.length <= 100}
        />
      </div>
    </div>
  );
};

export default DeviceAssignment;
