import React, { useState, useRef, useEffect } from 'react';
import ShiftCard from './ShiftCard/ShiftCard';
import DaySeparator from './DaySeparator';
import '../../../../styles/NewDashboard/ShiftReview/ShiftCardList/ShiftCardList.css';
import '../../../../styles/NewDashboard/ShiftReview/ShiftCardList/DaySeparator.css';
import { filterShiftEvents } from '../../../../utils/shiftUtils';

const ShiftCardList = ({
  shifts,
  devices,
  onLoadMore,
  hasMore,
  onJumpTo,
  onUpdateShiftFlag,
  expandedShiftIds,
  setExpandedShiftIds,
}) => {
  // console.log('hasMore', hasMore);
  // console.log('ShiftCardList received shifts:', shifts);
  const [lastExpandedShiftId, setLastExpandedShiftId] = useState(null);
  const expandedShiftRef = useRef(null);
  const [processedShifts, setProcessedShifts] = useState([]);

  useEffect(() => {
    const processShifts = async () => {
      const allShifts = Object.values(shifts).flat();
      const processed = await Promise.all(allShifts.map(filterShiftEvents));
      setProcessedShifts(processed);
    };

    processShifts();
  }, [shifts]);

  useEffect(() => {
    if (lastExpandedShiftId && expandedShiftRef.current) {
      expandedShiftRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [lastExpandedShiftId]);

  const handleToggle = (shiftId) => {
    setExpandedShiftIds((prevIds) => {
      const newIds = new Set(prevIds);
      if (!newIds.has(shiftId)) {
        newIds.add(shiftId);
        setLastExpandedShiftId(shiftId);
      } else {
        newIds.delete(shiftId);
        setLastExpandedShiftId(null);
      }
      return newIds;
    });
  };

  const renderShifts = () => {
    const sortedShifts = processedShifts.sort((a, b) => new Date(b.startTime) - new Date(a.startTime));

    const shiftsByDate = sortedShifts.reduce((acc, shift) => {
      const date = new Date(shift.startTime).toDateString();
      if (!acc[date]) acc[date] = [];
      acc[date].push(shift);
      return acc;
    }, {});

    return Object.entries(shiftsByDate).map(([date, dateShifts]) => (
      <div key={date}>
        <DaySeparator date={new Date(date)} onJumpTo={onJumpTo} />
        {dateShifts.map((shift) => {
          if (shift.events.length > 0) {
            return (
              <div key={shift.id} ref={lastExpandedShiftId === shift.id ? expandedShiftRef : null}>
                <ShiftCard
                  shift={shift}
                  onUpdateShiftFlag={onUpdateShiftFlag}
                  isExpanded={expandedShiftIds.has(shift.id)}
                  onToggle={() => handleToggle(shift.id)}
                />
              </div>
            );
          }
          return null;
        })}
      </div>
    ));
  };

  return (
    <div className="shift-card-list-container">
      {renderShifts()}
      {hasMore && (
        <button className="load-more-button" onClick={onLoadMore}>
          Load More
        </button>
      )}
    </div>
  );
};

export default ShiftCardList;
