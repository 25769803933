import React from 'react';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { stringToColor } from '../utils/utilsUI';

const ActivityCard = ({ visit }) => {
  const formatDuration = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return `${hours}h ${minutes}m`;
  };

  const createCustomIcon = (duration) => {
    const svgIcon = `
      <svg width="50" height="50" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
        <circle cx="25" cy="25" r="20" fill="red" opacity="0.4"/>
      </svg>
    `;

    return L.divIcon({
      className: 'custom-icon',
      html: svgIcon,
      iconSize: [50, 50],
      iconAnchor: [25, 25],
      popupAnchor: [0, -25],
    });
  };

  const backgroundColor = stringToColor(visit.device);

  // Determine label color based on duration
  const getColor = (duration) => {
    if (duration < 3600) {
      // Less than 1 hour
      return { backgroundColor: 'yellow', color: 'black' };
    } else if (duration < 18000) {
      // Less than 5 hours
      return { backgroundColor: 'orange', color: 'black' };
    } else {
      return { backgroundColor: 'red', color: 'white' };
    }
  };

  const formatTimeWithoutSeconds = (date) => {
    return new Date(date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  return (
    <div className="activity-card">
      <div className="activity-info">
        <p
          className="interaction-deviceId"
          style={{ backgroundColor, padding: '5px 10px', borderRadius: '5px', color: '#333', marginTop: '10px' }}
        >
          {visit.device}
        </p>
        <div className="activity-timeline">
          <div className="activity-timeline-item activity-timeline-content">
            <div className="activity-timeline-dot"></div>
            <div className="activity-timestamp">
              <div>{new Date(visit.start).toLocaleDateString()}</div>
              <div className="activity-time">{formatTimeWithoutSeconds(visit.start)}</div>
            </div>
          </div>
          <div className="activity-timeline-item activity-timeline-content">
            <div className="activity-duration-label" style={getColor(visit.duration)}>
              {formatDuration(visit.duration)}
            </div>
          </div>
          <div className="activity-timeline-item activity-timeline-content">
            <div className="activity-timeline-dot"></div>
            <div className="activity-timestamp">
              <div>{new Date(visit.end).toLocaleDateString()}</div>
              <div className="activity-time">{formatTimeWithoutSeconds(visit.end)}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="activity-map">
        <MapContainer
          center={[visit.coordinates.latitude, visit.coordinates.longitude]}
          zoom={15}
          style={{ height: '100%', width: '100%' }}
          zoomControl={false}
          dragging={false}
          scrollWheelZoom={false}
          doubleClickZoom={false}
          attributionControl={false}
        >
          <TileLayer
            url={`https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/{z}/{x}/{y}?access_token=${process.env.REACT_APP_MAPBOX_PUBLIC_TOKEN}`}
            attribution='&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> contributors'
            tileSize={512}
            zoomOffset={-1}
          />
          <Marker
            position={[visit.coordinates.latitude, visit.coordinates.longitude]}
            icon={createCustomIcon(visit.duration)}
          />
        </MapContainer>
      </div>
    </div>
  );
};

export default ActivityCard;
