import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faTimes } from '@fortawesome/free-solid-svg-icons';
import { styled } from '@mui/material/styles';
import { OutlinedInput } from '@mui/material';
// Add these imports at the top of the file
import { refreshMetrics, loadInteractions } from './dashboardDataHelpers';
import { pingDevices } from './deviceConfig';
import {
  fetchLatestAlertTimestamp,
  queryCosmosForEscalation,
  queryOrganizationsFromCosmos,
  getAlertMetrics,
} from './dashboardCosmosQueries';
import moment from 'moment';
import { PLIX_OVERWATCH_ACCOUNT } from './utilsEvents';
import { toast } from 'react-toastify'; // Add this import for error notifications

// -------------------- Initializing dashboard --------------------

export const checkForNewAlerts = async (userId, selectedDevices, lastAlertTimestampRef, setNewAlertsAvailable) => {
  try {
    const latestTimestamp = new Date(await fetchLatestAlertTimestamp(userId, selectedDevices));
    console.log('latestTimestamp: ', latestTimestamp);

    if (latestTimestamp) {
      const newTimestamp = new Date(latestTimestamp);
      console.log('newTimestamp: ', newTimestamp);

      if (!lastAlertTimestampRef.current || newTimestamp > new Date(lastAlertTimestampRef.current)) {
        console.log('New alert detected');
        setNewAlertsAvailable(true);
        lastAlertTimestampRef.current = newTimestamp.getTime();
      } else {
        console.log('No new alerts');
      }
    }
  } catch (error) {
    console.error('Error checking for new alerts:', error);
    toast.error('Failed to check for new alerts. Please try again later.');
  }
};

export const initializeData = async (
  selectedDevices,
  setLoadedDevices,
  dateRange,
  setNewAlertsAvailable,
  setIsLoading,
  setMetricsLoaded,
  setMetricsData,
  setSelectedCoordinates,
  featureMode,
  activeTab,
  fetchLocationData,
  setLocationData,
  setLocationVisitData,
  setIsLoadingLocations,
  userId,
  page,
  setInteractions,
  setHasMore,
  setIsLoadingMore,
  lastAlertTimestampRef,
  selectedAlertId,
  escalation,
  devices,
  setTempDateRange,
  setDateRange,
  setTempSelectedDeviceIds,
  setSelectedDevices,
  setSelectedAlertId,
  setCoverage,
  setAccuracy,
  setTotalCount,
  setLabeledCount,
  setCorrectCount
) => {
  try {
    if (escalation && !selectedAlertId) {
      const deviceIds = devices.map((device) => device.deviceId);
      const alert = await queryCosmosForEscalation(escalation, deviceIds);

      if (alert) {
        const alertDate = new Date(alert.Timestamp);
        const startDate = new Date(alertDate.setHours(0, 0, 0, 0));
        const endDate = new Date(alertDate.setMinutes(alertDate.getMinutes() + 1));
        setTempDateRange([startDate, endDate]);
        setDateRange([startDate, endDate]);
        setTempSelectedDeviceIds([alert.DeviceId]);
        setSelectedDevices(devices.filter((device) => device.deviceId === alert.DeviceId));
        setSelectedAlertId(alert.id);
      }
    } else {
      await refreshMetrics(
        selectedDevices,
        setIsLoadingLocations,
        setLoadedDevices,
        dateRange,
        setNewAlertsAvailable,
        setIsLoading,
        setMetricsLoaded,
        setMetricsData,
        setSelectedCoordinates,
        featureMode,
        activeTab,
        fetchLocationData,
        setLocationData,
        setLocationVisitData
      );
      await loadInteractions(
        true,
        [],
        false,
        'Alerts',
        'StateMachineAlerts',
        userId,
        featureMode,
        selectedDevices.map((d) => d.deviceId),
        dateRange,
        page,
        10,
        setInteractions,
        setNewAlertsAvailable,
        setHasMore,
        setIsLoadingMore,
        lastAlertTimestampRef
      );
      if (selectedAlertId) {
        setTimeout(() => {
          const alertElement = document.querySelector(`#alert-${selectedAlertId}`);
          if (alertElement) {
            alertElement.parentNode.scrollTop = alertElement.offsetTop - window.innerHeight / 2;
            alertElement.style.backgroundColor = 'lightyellow';
          }
        }, 1000);
      }
    }

    if (userId === PLIX_OVERWATCH_ACCOUNT) {
      try {
        const deviceIds = selectedDevices.map((device) => device.deviceId);
        const fromDate = dateRange[0].toISOString();
        const toDate = dateRange[1].toISOString();
        const { coverage, accuracy, totalCount, labeledCount, correctCount } = await getAlertMetrics(
          deviceIds,
          fromDate,
          toDate
        );
        setCoverage(coverage);
        setAccuracy(accuracy);
        setTotalCount(totalCount);
        setLabeledCount(labeledCount);
        setCorrectCount(correctCount);
      } catch (error) {
        console.error('Error fetching alert metrics:', error);
        toast.error('Failed to fetch alert metrics. Some data may be unavailable.');
      }
    }
  } catch (error) {
    console.error('Error initializing dashboard data:', error);
    toast.error('Failed to initialize dashboard data. Please refresh the page.');
  }
};

export const fetchDevices = async (userId, setDevices, setDeviceFilter) => {
  try {
    const fetchedDevices = await pingDevices(userId);
    setDevices(fetchedDevices);
    if (fetchedDevices.length > 0) {
      const initialDevice = fetchedDevices.find((device) => device.isStreamActive && device.isAlertsActive);
      const initialDeviceId = initialDevice ? initialDevice.deviceId : null;
      setDeviceFilter(initialDeviceId);
      if (!localStorage.getItem('deviceFilter')) {
        localStorage.setItem('deviceFilter', initialDeviceId);
      }
    }
    return fetchedDevices;
  } catch (error) {
    console.error('Error fetching devices:', error);
    toast.error('Failed to fetch devices. Please try again later.');
    return [];
  }
};

export const fetchOrganizations = async (setOrganizations) => {
  try {
    const organizations = await queryOrganizationsFromCosmos();
    setOrganizations(organizations);
  } catch (error) {
    console.error('Error fetching organizations:', error);
    toast.error('Failed to fetch organizations. Please try again later.');
    setOrganizations([]); // Set to empty array in case of error
  }
};

export const fetchDevicesData = async (
  isOnline,
  userId,
  devices,
  setOrganizations,
  setDevices,
  setDeviceFilter,
  fetchDevices
) => {
  if (!isOnline) {
    console.log('Network is offline. Skipping device fetch.');
    return;
  }
  try {
    if (userId === PLIX_OVERWATCH_ACCOUNT) {
      await fetchOrganizations(setOrganizations);
    }
    const fetchedDevices = await fetchDevices(userId, setDevices, setDeviceFilter);
    if (JSON.stringify(fetchedDevices) !== JSON.stringify(devices)) {
      setDevices(fetchedDevices);
    }
  } catch (error) {
    console.error('Error fetching devices data:', error);
    toast.error('Failed to fetch devices data. Some information may be outdated.');
  }
};

// -------------------- Time and Date Formatting --------------------

/**
 * Formats a decimal number of hours into a string representation of hours and minutes.
 * @param {number} hours - The number of hours to format.
 * @returns {string} A formatted string representing the time.
 */
export const formatTime = (hours) => {
  const h = Math.floor(hours);
  const m = Math.round((hours - h) * 60);
  if (h > 0 && m > 0) {
    return `${h}h ${m}m`;
  } else if (h > 0) {
    return `${h}h`;
  } else {
    return `${m}m`;
  }
};

/**
 * Handles chart click events and sets the selected date.
 * @param {Object} data - The click event data.
 * @param {Function} setSelectedDate - Function to set the selected date.
 */
export const handleChartClick = (data, setSelectedDate) => {
  if (data && data.activePayload && data.activePayload[0]) {
    const dateStr = data.activePayload[0].payload.name.split('\n')[0];
    const [month, day] = dateStr.split('/');
    const currentYear = new Date().getFullYear();
    const clickedDate = new Date(currentYear, month - 1, day).toISOString().split('T')[0];
    setSelectedDate(clickedDate);
  }
};

// -------------------- UI Components and Styles --------------------

/**
 * Styled component for a bootstrap-like input.
 */
export const BootstrapInput = styled(OutlinedInput)(({ theme }) => ({
  '&.MuiOutlinedInput-root': {
    borderRadius: 20,
    backgroundColor: theme.palette.background.paper,
    '& fieldset': {
      borderColor: '#ced4da',
      margin: 0,
    },
    '&:hover fieldset': {
      borderColor: '#ced4da',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#000',
    },
    borderColor: '#ced4da',
  },
  '& .MuiInputBase-input': {
    borderColor: '#ced4da',
    borderRadius: 20,
    position: 'relative',
    height: '30px',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
  },
}));

/**
 * Custom input component for date picker.
 */
export const CustomInput = React.forwardRef(({ value, onClick, onClear }, ref) => (
  <div className="date-picker-wrapper" onClick={onClick}>
    <div className="date-picker-content">
      <FontAwesomeIcon icon={faCalendar} className="calendar-icon" />
      {value ? (
        <span className="selected-date-range">{value}</span>
      ) : (
        <span className="placeholder">Select date range</span>
      )}
    </div>
    {value && (
      <button
        className="clear-date-button"
        onClick={(e) => {
          e.stopPropagation();
          onClear();
        }}
      >
        <FontAwesomeIcon icon={faTimes} />
      </button>
    )}
  </div>
));

/**
 * Props for menu components.
 */
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      width: 300,
    },
  },
};

// -------------------- Data Filtering and Processing --------------------

/**
 * Filters interactions based on a search query.
 * @param {Array} interactions - The array of interactions to filter.
 * @param {string} searchQuery - The search query to filter by.
 * @returns {Array} Filtered array of interactions.
 */
export const filterInteractions = (interactions, searchQuery, userId) => {
  const caseInsensitiveSearchQuery = searchQuery.toLowerCase();
  return interactions.filter(
    (interaction) =>
      interaction.alerts &&
      interaction.alerts.some(
        (alert) =>
          (alert.Description && alert.Description.toLowerCase().includes(caseInsensitiveSearchQuery)) ||
          (alert.Label && alert.Label.toLowerCase().includes(caseInsensitiveSearchQuery)) ||
          (alert.AssignedTo && alert.AssignedTo.toLowerCase().includes(caseInsensitiveSearchQuery)) ||
          (alert.DeviceId && alert.DeviceId.toLowerCase().includes(caseInsensitiveSearchQuery))
      ) &&
      (!interaction.IsDeleted || userId === PLIX_OVERWATCH_ACCOUNT)
  );
};

export const applyFilters = (
  tempDateRange,
  tempSelectedDeviceIds,
  devices,
  setDateRange,
  setSelectedDevices,
  setPage,
  setIsLoadingMore,
  setShouldRefetch,
  setShouldRefetchMetrics,
  userId,
  selectedOrg,
  organizations,
  setCoverage,
  setAccuracy,
  setTotalCount,
  setLabeledCount,
  setCorrectCount
) => {
  if (tempDateRange[0] && tempDateRange[1]) {
    const newEndDate = new Date(tempDateRange[1]);
    newEndDate.setDate(newEndDate.getDate() + 1);
    setDateRange([tempDateRange[0], newEndDate]);
  }

  setSelectedDevices(
    tempSelectedDeviceIds.length === 0
      ? devices
      : devices.filter((device) => tempSelectedDeviceIds.includes(device.deviceId))
  );

  setPage(1);
  setIsLoadingMore(false);
  setShouldRefetch(true);
  setShouldRefetchMetrics(true);

  if (userId === PLIX_OVERWATCH_ACCOUNT) {
    const deviceIds =
      tempSelectedDeviceIds.length === 0 ? devices.map((device) => device.deviceId) : tempSelectedDeviceIds;
    const fromDate = tempDateRange[0].toISOString();
    const toDate = new Date(tempDateRange[1]);
    toDate.setDate(toDate.getDate() + 1);
    const toDateString = toDate.toISOString();

    getAlertMetrics(deviceIds, fromDate, toDateString)
      .then(({ coverage, accuracy, totalCount, labeledCount, correctCount }) => {
        setCoverage(coverage);
        setAccuracy(accuracy);
        setTotalCount(totalCount);
        setLabeledCount(labeledCount);
        setCorrectCount(correctCount);
      })
      .catch((error) => {
        console.error('Error fetching alert metrics:', error);
        toast.error('Failed to fetch alert metrics. Some data may be unavailable.');
      });
  }
};

export const NUM_DAYS_DEFAULT_RANGE = 3;

export const resetFilters = (
  setTempDateRange,
  setDateRange,
  setTempSelectedDeviceIds,
  setSelectedDevices,
  devices,
  setSearchQuery,
  setPage,
  setIsLoadingMore,
  setShouldRefetch,
  setShouldRefetchMetrics,
  setIsResetting
) => {
  const newStartDate = moment().subtract(NUM_DAYS_DEFAULT_RANGE, 'days').startOf('day').toDate();
  const newEndDate = moment().endOf('day').toDate();
  // const adjustedEndDate = new Date(newEndDate);
  // adjustedEndDate.setDate(adjustedEndDate.getDate() + 1);

  setTempDateRange([newStartDate, newEndDate]);
  setDateRange([newStartDate, newEndDate]);
  setTempSelectedDeviceIds(devices.map((d) => d.deviceId));
  setSelectedDevices(devices);
  setSearchQuery('');
  setPage(1);
  setIsLoadingMore(false);
  setShouldRefetch(true);
  setShouldRefetchMetrics(true);
  setIsResetting(true);
};

export const handleNewAlertsClick = async (
  setNewAlertsAvailable,
  setPage,
  setIsLoadingMore,
  loadInteractions,
  resultsContainerRef
) => {
  try {
    setNewAlertsAvailable(false);
    setPage(1);
    setIsLoadingMore(false);
    await loadInteractions(
      true,
      [],
      false,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      setNewAlertsAvailable,
      null,
      setIsLoadingMore
    );
    if (resultsContainerRef.current) {
      resultsContainerRef.current.scrollTop = 0;
    }
  } catch (error) {
    console.error('Failed to fetch new alerts:', error);
    toast.error('Failed to fetch new alerts. Please try again later.');
  }
};

export const handleSelectionChange = (event, devices, setTempSelectedDeviceIds) => {
  const value = event.target.value;
  if (value.includes('all')) {
    setTempSelectedDeviceIds((prevSelected) =>
      prevSelected.length === devices.length ? [] : devices.map((d) => d.deviceId)
    );
  } else {
    setTempSelectedDeviceIds(typeof value === 'string' ? [value] : value);
  }
};

export const handleOrganizationChange = (
  event,
  organizations,
  devices,
  setSelectedOrg,
  setSelectedDevices,
  setTempSelectedDeviceIds
) => {
  const selectedOrgName = event.target.value;
  setSelectedOrg(selectedOrgName);

  if (selectedOrgName === '') {
    // "All Organizations" is selected
    setSelectedDevices(devices);
    setTempSelectedDeviceIds(devices.map((d) => d.deviceId));
  } else {
    const selectedOrgData = organizations.find((org) => org.orgName === selectedOrgName);
    if (selectedOrgData && selectedOrgData.deviceIds) {
      const orgDevices = devices.filter((device) => selectedOrgData.deviceIds.includes(device.deviceId));
      setSelectedDevices(orgDevices);
      setTempSelectedDeviceIds(orgDevices.map((d) => d.deviceId));
    }
  }
};
