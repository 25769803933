import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { getDistance } from 'geolib';
import { toast } from 'react-toastify';

// UI Components
import SearchResult from './SearchResult';
import ActivityCard from './ActivityCard';
import HeatmapMap from './HeatmapMap';
import DashboardChart from './DashboardChart';
import DatePicker from 'react-datepicker';
import { FormControl, MenuItem, Select, Checkbox, ListItemText, Button } from '@mui/material';

// Styles
import '../styles/Dashboard.css';
import 'react-datepicker/dist/react-datepicker.css';

// Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faStroopwafel,
  faRefresh,
  faTimes,
  faArrowRight,
  faArrowRightArrowLeft,
  faCalendar,
  faArrowUp,
} from '@fortawesome/free-solid-svg-icons';

// Utilities
import {
  BootstrapInput,
  CustomInput,
  MenuProps,
  formatTime,
  filterInteractions,
  resetFilters,
  handleNewAlertsClick,
  checkForNewAlerts,
  initializeData,
  fetchDevices,
  fetchDevicesData,
  applyFilters,
  handleSelectionChange,
  NUM_DAYS_DEFAULT_RANGE,
  handleOrganizationChange,
  fetchOrganizations,
} from '../utils/dashboardUtils';

// Data Helpers
import { loadInteractions, fetchLocationData, refreshMetrics } from '../utils/dashboardDataHelpers';
import { calculateMemoizedMetrics } from '../utils/dashboardMetricsHelpers';
import { getAlertMetrics } from '../utils/dashboardCosmosQueries';

import { PLIX_OVERWATCH_ACCOUNT } from '../utils/utilsEvents';

// Add Font Awesome icons to the library
library.add(faStroopwafel, faRefresh, faTimes, faArrowRight, faArrowRightArrowLeft, faCalendar, faArrowUp);

const Dashboard = ({ userId, featureMode }) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [devices, setDevices] = useState([]);
  const [deviceFilter, setDeviceFilter] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [interactions, setInteractions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dateRange, setDateRange] = useState([
    moment().subtract(NUM_DAYS_DEFAULT_RANGE, 'days').startOf('day').toDate(),
    moment().endOf('day').toDate(),
  ]);
  const [tempDateRange, setTempDateRange] = useState([
    moment().subtract(NUM_DAYS_DEFAULT_RANGE, 'days').startOf('day').toDate(),
    moment().endOf('day').toDate(),
  ]);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [tempSelectedDeviceIds, setTempSelectedDeviceIds] = useState([]);

  const [newAlertsAvailable, setNewAlertsAvailable] = useState(false);
  const lastAlertTimestampRef = useRef(null);
  const [firstDataLoad, setFirstDataLoad] = useState(true);
  const [selectedMetric, setSelectedMetric] = useState('shiftDuration');
  const [selectedAlertId, setSelectedAlertId] = useState(null);
  const [activeTab, setActiveTab] = useState('escalations');
  const [locationData, setLocationData] = useState([]);
  const [locationVisitData, setLocationVisitData] = useState([]);
  const [selectedCoordinates, setSelectedCoordinates] = useState(null);
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [shouldRefetchMetrics, setShouldRefetchMetrics] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isResetting, setIsResetting] = useState(false);
  const [error, setError] = useState(null);
  const [metricsData, setMetricsData] = useState({
    escalations: [],
    shiftDuration: [],
    recordingDuration: [],
  });
  const [metricsLoaded, setMetricsLoaded] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [isLoadingLocations, setIsLoadingLocations] = useState(false);
  const [loadedDevices, setLoadedDevices] = useState(0);
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState('');

  const [coverage, setCoverage] = useState(null);
  const [accuracy, setAccuracy] = useState(null);
  const [totalCount, setTotalCount] = useState(null);
  const [labeledCount, setLabeledCount] = useState(null);
  const [correctCount, setCorrectCount] = useState(null);

  const resultsContainerRef = useRef(null);
  const { escalation } = useParams();

  const memoizedMetrics = useMemo(
    () => calculateMemoizedMetrics(metricsData, selectedDevices, metricsLoaded),
    [metricsData, selectedDevices, metricsLoaded]
  );

  const [refreshError, setRefreshError] = useState(null);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  useEffect(() => {
    const fetchDevicesData = async () => {
      if (!isOnline) {
        console.log('Network is offline. Skipping device fetch.');
        return;
      }
      if (userId === PLIX_OVERWATCH_ACCOUNT) {
        await fetchOrganizations(setOrganizations);
      }
      const fetchedDevices = await fetchDevices(userId, setDevices, setDeviceFilter);
      setDevices((prevDevices) => {
        if (JSON.stringify(fetchedDevices) !== JSON.stringify(prevDevices)) {
          return fetchedDevices;
        }
        return prevDevices;
      });
    };
    fetchDevicesData();
  }, [isOnline, userId]);

  useEffect(() => {
    const loadData = async () => {
      if (selectedDevices.length > 0 && firstDataLoad) {
        setFirstDataLoad(false);
        try {
          await initializeData(
            selectedDevices,
            setLoadedDevices,
            dateRange,
            setNewAlertsAvailable,
            setIsLoading,
            setMetricsLoaded,
            setMetricsData,
            setSelectedCoordinates,
            featureMode,
            activeTab,
            fetchLocationData,
            setLocationData,
            setLocationVisitData,
            setIsLoadingLocations,
            userId,
            page,
            setInteractions,
            setHasMore,
            setIsLoadingMore,
            lastAlertTimestampRef,
            selectedAlertId,
            escalation,
            devices,
            setTempDateRange,
            setDateRange,
            setTempSelectedDeviceIds,
            setSelectedDevices,
            setSelectedAlertId,
            setCoverage,
            setAccuracy,
            setTotalCount,
            setLabeledCount,
            setCorrectCount
          );
        } catch (error) {
          console.error('Error initializing data:', error);
          setError(error.message || 'An error occurred while loading data');
        } finally {
          setFirstDataLoad(false);
        }
      }
    };
    loadData();
  }, [
    selectedDevices.length,
    selectedAlertId,
    firstDataLoad,
    escalation,
    devices,
    featureMode,
    userId,
    dateRange,
    page,
  ]);

  // Replace the existing useEffect for checking new alerts with this:
  useEffect(() => {
    const checkForNewAlertsWrapper = () => {
      checkForNewAlerts(userId, selectedDevices, lastAlertTimestampRef, setNewAlertsAvailable);
    };
    const interval = setInterval(checkForNewAlertsWrapper, 30000);
    return () => clearInterval(interval);
  }, [selectedDevices, userId]);

  useEffect(() => {
    if (shouldRefetch) {
      loadInteractions(
        true,
        [],
        isLoadingMore,
        'Alerts',
        'StateMachineAlerts',
        userId,
        featureMode,
        selectedDevices.map((d) => d.deviceId),
        dateRange,
        page,
        10,
        setInteractions,
        setNewAlertsAvailable,
        setHasMore,
        setIsLoadingMore,
        lastAlertTimestampRef
      );
      setShouldRefetch(false);
      setIsResetting(false);
    }
  }, [shouldRefetch, page, isLoadingMore, userId, featureMode, selectedDevices, dateRange]);

  useEffect(() => {
    if (shouldRefetchMetrics) {
      refreshMetrics(
        selectedDevices,
        setIsLoadingLocations,
        setLoadedDevices,
        dateRange,
        setNewAlertsAvailable,
        setIsLoading,
        setMetricsLoaded,
        setMetricsData,
        setSelectedCoordinates,
        featureMode,
        activeTab,
        fetchLocationData,
        setLocationData,
        setLocationVisitData
      ).catch((error) => {
        console.error('Error refreshing metrics:', error);
        setRefreshError('Failed to refresh metrics. Please try again.');
        toast.error('Failed to refresh metrics. Please try again.');
      });
      setShouldRefetchMetrics(false);
      setIsResetting(false);
    }
  }, [shouldRefetchMetrics, selectedDevices, dateRange, featureMode, activeTab]);

  // Modify the useEffect
  useEffect(() => {
    if (devices.length > 0 && selectedDevices.length === 0) {
      setSelectedDevices(devices);
      setTempSelectedDeviceIds(devices.map((device) => device.deviceId));
    }
  }, [devices]);

  const memoizedFilteredInteractions = useMemo(() => {
    return filterInteractions(interactions, searchQuery, userId);
  }, [interactions, searchQuery]);

  const metricBoxStyle = (metric) => ({
    cursor: metric !== 'totalDuration' ? 'pointer' : 'auto',
    boxShadow: selectedMetric === metric ? '0 0 10px 2px rgba(255, 0, 0, 0.5)' : '0 4px 6px rgba(0, 0, 0, 0.1)',
    backgroundColor: selectedMetric === metric ? '#fff' : 'white',
  });

  if (error) {
    return (
      <div className="error-container">
        <p className="error-message">{error}</p>
      </div>
    );
  }

  const setTab = (newTab) => {
    setActiveTab(newTab);
    setSelectedCoordinates(null);
    if (newTab === 'activity' && featureMode === 'dev') {
      fetchLocationData(
        selectedDevices,
        dateRange,
        setIsLoadingLocations,
        setLoadedDevices,
        setLocationData,
        setLocationVisitData
      );
    }
  };

  const handleNewAlertsClickWrapper = () => {
    handleNewAlertsClick(
      setNewAlertsAvailable,
      setPage,
      setIsLoadingMore,
      (reset, prevInteractions, loadMore) =>
        loadInteractions(
          reset,
          prevInteractions,
          loadMore,
          'Alerts',
          'StateMachineAlerts',
          userId,
          featureMode,
          selectedDevices.map((d) => d.deviceId),
          dateRange,
          page,
          10,
          setInteractions,
          setNewAlertsAvailable,
          setHasMore,
          setIsLoadingMore,
          lastAlertTimestampRef
        ),
      resultsContainerRef
    );
  };

  return (
    <div className="dashboard-container">
      <div className="controls-container">
        <div className="search-bar-container-dashboard">
          {userId === PLIX_OVERWATCH_ACCOUNT && (
            <FormControl sx={{ m: 1, minWidth: 300, maxWidth: 300 }} variant="standard">
              <Select
                labelId="org-select-label"
                id="org-select"
                value={selectedOrg}
                onChange={(event) =>
                  handleOrganizationChange(
                    event,
                    organizations,
                    devices,
                    setSelectedOrg,
                    setSelectedDevices,
                    setTempSelectedDeviceIds
                  )
                }
                input={<BootstrapInput />}
                renderValue={(selected) => {
                  if (!selected) return 'All Organizations';
                  return selected;
                }}
                displayEmpty
                MenuProps={MenuProps}
              >
                <MenuItem value="">
                  <ListItemText primary="All Organizations" />
                </MenuItem>
                {organizations.map((org) => (
                  <MenuItem key={org.id} value={org.orgName}>
                    <ListItemText primary={org.orgName} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          <FormControl sx={{ m: 1, minWidth: 300, maxWidth: 300 }} variant="standard">
            <Select
              labelId="device-select-label"
              id="device-select"
              multiple
              value={tempSelectedDeviceIds}
              onChange={(event) => handleSelectionChange(event, devices, setTempSelectedDeviceIds)}
              onClose={() => {}}
              input={<BootstrapInput />}
              renderValue={(selected) => {
                if (selected.length === 0) return 'Filter by Users';
                if (selected.length === devices.length) return 'All Users';
                return selected
                  .map((id) => {
                    const device = devices.find((d) => d.deviceId === id);
                    return device ? device.assignedTo || device.deviceId : id;
                  })
                  .join(', ');
              }}
              displayEmpty
              MenuProps={MenuProps}
            >
              <MenuItem value="all">
                <Checkbox
                  checked={tempSelectedDeviceIds.length === devices.length}
                  indeterminate={tempSelectedDeviceIds.length > 0 && tempSelectedDeviceIds.length < devices.length}
                  style={{ color: '#cb2d3e' }}
                />
                <ListItemText
                  primary={tempSelectedDeviceIds.length === devices.length ? 'Deselect All' : 'Select All'}
                />
              </MenuItem>
              {devices.map((device) => (
                <MenuItem key={device.deviceId} value={device.deviceId}>
                  <Checkbox checked={tempSelectedDeviceIds.includes(device.deviceId)} style={{ color: '#cb2d3e' }} />
                  <ListItemText primary={device.assignedTo || device.deviceId} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <DatePicker
            selected={tempDateRange[0]}
            onChange={setTempDateRange}
            startDate={tempDateRange[0]}
            endDate={tempDateRange[1]}
            selectsRange
            customInput={
              <CustomInput
                onClear={() => setTempDateRange([new Date(new Date().setDate(new Date().getDate() - 3)), new Date()])}
              />
            }
            className="date-range-filter-dashboard"
            wrapperClassName="date-picker-container"
          />
          <Button
            variant="contained"
            onClick={() =>
              applyFilters(
                tempDateRange,
                tempSelectedDeviceIds,
                devices,
                setDateRange,
                setSelectedDevices,
                setPage,
                setIsLoadingMore,
                setShouldRefetch,
                setShouldRefetchMetrics,
                userId,
                selectedOrg,
                organizations,
                setCoverage,
                setAccuracy,
                setTotalCount,
                setLabeledCount,
                setCorrectCount
              )
            }
            className="filter-button"
          >
            Filter
          </Button>
          <Button
            variant="outlined"
            onClick={() =>
              resetFilters(
                setTempDateRange,
                setDateRange,
                setTempSelectedDeviceIds,
                setSelectedDevices,
                devices,
                setSearchQuery,
                setPage,
                setIsLoadingMore,
                setShouldRefetch,
                setShouldRefetchMetrics,
                setIsResetting
              )
            }
            className="reset-button"
          >
            Reset
          </Button>
        </div>
      </div>
      <div className="content-container">
        <div className="metrics-section">
          <DashboardChart metricsData={metricsData} selectedMetric={selectedMetric} devices={devices} />
          <div className="metrics-container">
            <div
              className="metric-box"
              style={metricBoxStyle('shiftDuration')}
              onClick={() => setSelectedMetric('shiftDuration')}
            >
              <div className="metric-value">
                {memoizedMetrics.averageShiftDurationPerDay !== '--'
                  ? formatTime(parseFloat(memoizedMetrics.averageShiftDurationPerDay))
                  : '--'}
              </div>
              <div className="metric-subheader">Hours In Shift</div>
              <div className="metric-subsubheader">Daily Average</div>
            </div>
            <div
              className="metric-box"
              style={metricBoxStyle('escalations')}
              onClick={() => setSelectedMetric('escalations')}
            >
              <div className="metric-value">
                {memoizedMetrics.averageEscalationsPerDay !== '--' ? memoizedMetrics.averageEscalationsPerDay : '--'}
              </div>
              <div className="metric-subheader">Escalations</div>
              <div className="metric-subsubheader">Daily Average</div>
            </div>
            <div
              className="metric-box"
              style={metricBoxStyle('recordingDuration')}
              onClick={() => setSelectedMetric('recordingDuration')}
            >
              <div className="metric-value">
                {memoizedMetrics.averageRecordingDurationPerDay !== '--'
                  ? formatTime(parseFloat(memoizedMetrics.averageRecordingDurationPerDay))
                  : '--'}
              </div>
              <div className="metric-subheader">Hours Recorded</div>
              <div className="metric-subsubheader">Daily Average</div>
            </div>
            <div className="metric-box" style={metricBoxStyle('totalDuration')}>
              <div className="metric-value">
                {memoizedMetrics.totalRecordingHours !== '--'
                  ? formatTime(parseFloat(memoizedMetrics.totalRecordingHours))
                  : '--'}
              </div>
              <div className="metric-subheader">Total Time Recorded</div>
              <div className="metric-subsubheader"></div>
            </div>
          </div>
          {userId === PLIX_OVERWATCH_ACCOUNT && totalCount && (
            <div className="alert-metrics">
              <p>Coverage: {coverage !== null ? `${coverage}% (${labeledCount}/${totalCount})` : 'N/A'}</p>
              <p>Accuracy: {accuracy !== null ? `${accuracy}% (${correctCount}/${labeledCount})` : 'N/A'}</p>
            </div>
          )}
        </div>

        <div className="search-section">
          {isLoading && (
            <div className="loading-spinner">
              <i className="fas fa-spinner spinner"></i>
            </div>
          )}
          <div className="search-section-header">
            <div className="tabs-container">
              <button
                className={activeTab === 'escalations' ? 'tab-active' : 'tab'}
                onClick={() => setTab('escalations')}
              >
                Escalations
              </button>
              {featureMode === 'dev' && (
                <button className={activeTab === 'activity' ? 'tab-active' : 'tab'} onClick={() => setTab('activity')}>
                  Activity
                </button>
              )}
            </div>

            {activeTab === 'escalations' ? (
              <div className="search-bar-container-dashboard">
                <div>
                  <div className="search-form">
                    <input
                      type="text"
                      value={searchInputValue}
                      onChange={(event) => setSearchInputValue(event.target.value)}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          setSearchQuery(searchInputValue);
                        }
                      }}
                      placeholder="Search transcript"
                      className="search-input-escalations"
                    />
                    <button onClick={() => setSearchQuery(searchInputValue)} className="search-submit-button">
                      <FontAwesomeIcon icon={faArrowRight} />
                    </button>
                  </div>
                </div>
              </div>
            ) : locationData.length > 0 ? (
              <div className="heatmap-container" style={{ width: '100%', height: '300px' }}>
                <HeatmapMap clusters={locationData} onMarkerClick={setSelectedCoordinates} />
              </div>
            ) : (
              <div></div>
            )}
          </div>
          {activeTab === 'escalations' ? (
            <div className="results-container" ref={resultsContainerRef}>
              <div className="new-alerts-button-container">
                {newAlertsAvailable && (
                  <button
                    className={`new-alerts-button ${newAlertsAvailable ? 'pulse' : ''}`}
                    onClick={handleNewAlertsClickWrapper}
                  >
                    <FontAwesomeIcon icon={faArrowUp} className="arrow-icon" />
                    New Alerts
                  </button>
                )}
              </div>
              {memoizedFilteredInteractions.length > 0 ? (
                memoizedFilteredInteractions.map((interaction, index) => (
                  <SearchResult
                    key={`${interaction.id}-${index}`}
                    interaction={interaction}
                    type="incident"
                    index={index}
                    featureMode={featureMode}
                    devices={devices}
                    userId={userId}
                  />
                ))
              ) : (
                <div className="empty-results">
                  <FontAwesomeIcon icon={faStroopwafel} size="3x" color="#ccc" />
                  <p>No escalations in the selected date range.</p>
                </div>
              )}

              {hasMore && !isLoadingMore && (
                <button
                  onClick={() => {
                    setIsLoadingMore(true);
                    setPage((prevPage) => prevPage + 1);
                    setShouldRefetch(true);
                  }}
                  className="load-more-button"
                >
                  Load More
                </button>
              )}
              {isLoadingMore && <div className="loading-more">Loading more...</div>}
            </div>
          ) : (
            <div className="activity-tab-content">
              {isLoadingLocations ? (
                <div className="loading-locations">
                  <p>
                    Loading location data... ({loadedDevices}/{selectedDevices.length} devices)
                  </p>
                  <progress value={loadedDevices} max={selectedDevices.length}></progress>
                </div>
              ) : null}
              <div className="activity-log-container">
                {locationVisitData
                  .filter(
                    (loc) =>
                      !selectedCoordinates ||
                      getDistance(
                        { latitude: selectedCoordinates.latitude, longitude: selectedCoordinates.longitude },
                        { latitude: loc.coordinates.latitude, longitude: loc.coordinates.longitude }
                      ) <= 400 // 0.4 km is approximately 0.25 mile
                  )
                  .map((visit, index) => (
                    <ActivityCard key={index} visit={visit} />
                  ))}
              </div>
            </div>
          )}
        </div>
      </div>
      {refreshError && <div className="error-message">{refreshError}</div>}
    </div>
  );
};

export default Dashboard;
