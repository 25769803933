import { useState } from 'react';

const InfoTooltip = ({ interaction }) => {
  const [activeTab, setActiveTab] = useState('pre');

  const formatTime = (timeValue) => {
    if (typeof timeValue === 'number') {
      // Handle relativeTimestamp (seconds)
      const minutes = Math.floor(timeValue / 60);
      const seconds = timeValue % 60;
      return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    } else if (typeof timeValue === 'string') {
      // Handle timestamp (ISO string)
      const date = new Date(timeValue);
      return date.toLocaleTimeString('en-US', { hour12: false, timeZone: 'UTC' });
    }
    return 'N/A';
  };

  const transcripts = activeTab === 'pre' ? interaction.Transcripts : interaction.VideoTranscripts;

  return (
    <div className="info-tooltip">
      <div className="tooltip-tabs">
        <button className={`tab-button ${activeTab === 'pre' ? 'active' : ''}`} onClick={() => setActiveTab('pre')}>
          Before Video
        </button>
        <button className={`tab-button ${activeTab === 'post' ? 'active' : ''}`} onClick={() => setActiveTab('post')}>
          In Video
        </button>
      </div>
      <div className="transcript-list">
        {transcripts &&
          transcripts.map((item, index) => (
            <div key={index} className={`transcript-item ${item.isEscalation ? 'escalation' : ''}`}>
              <span className="transcript-timestamp">{formatTime(item.relativeTimestamp || item.timestamp)}</span>
              <span className="transcript-text">{item.transcript}</span>
            </div>
          ))}
      </div>
    </div>
  );
};

export default InfoTooltip;
