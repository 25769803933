import React, { useEffect, useState } from 'react';
import ShiftCollapsed from './ShiftCollapsed/ShiftCollapsed';
import ShiftExpanded from './ShiftExpanded/ShiftExpanded';
import { fetchBatteryAtTimestamp } from '../../../../../utils/dashboardTimescaleQueries';
import '../../../../../styles/NewDashboard/ShiftReview/ShiftCard/ShiftCard.css';

const ShiftCard = ({ shift, onUpdateShiftFlag, isExpanded, onToggle }) => {
  const [batteryLevels, setBatteryLevels] = useState({});

  // Only for demo purposes
  const queryDeviceId = shift.deviceIdDemo || shift.deviceId;

  const fetchBatteryLevels = async (events, deviceId) => {
    if (!events || events.length === 0) {
      // console.warn('No events to fetch battery levels for');
      return {};
    }

    const eventsToFetch = [events[0]];
    if (events.length > 1 && events[events.length - 1].type === 'end') {
      eventsToFetch.push(events[events.length - 1]);
    }

    const levels = await Promise.all(
      eventsToFetch.map(async (event) => {
        if (!event || !event.timestamp) {
          // console.warn('Invalid event or missing timestamp', event);
          return null;
        }
        try {
          const level = await fetchBatteryAtTimestamp(deviceId, event.timestamp);
          return { timestamp: event.timestamp, level };
        } catch (err) {
          // console.error('Failed to fetch battery level:', err);
          return { timestamp: event.timestamp, level: null };
        }
      })
    );

    return levels.reduce((acc, item) => {
      if (item && item.timestamp) {
        acc[item.timestamp] = item.level;
      }
      return acc;
    }, {});
  };

  useEffect(() => {
    const updateShift = async () => {
      const updatedShift = shift;
      const batteryLevels = await fetchBatteryLevels(updatedShift.events, queryDeviceId);
      // setValidatedShift(updatedShift);
      setBatteryLevels(batteryLevels);
    };

    updateShift();
  }, [shift, queryDeviceId]);

  const handleToggle = () => {
    onToggle(shift.id);
  };

  const handleFlagShift = (e) => {
    const newFlaggedState = !shift.isFlagged;
    onUpdateShiftFlag(shift.id, newFlaggedState);
  };

  const calculateTimeRatio = (onlineTime, offlineTime) => {
    const total = onlineTime + offlineTime;
    return {
      onlineRatio: (onlineTime / total) * 100,
      offlineRatio: (offlineTime / total) * 100,
    };
  };

  const timeRatio = calculateTimeRatio(shift.calculatedOnlineTime, shift.calculatedOfflineTime);

  return (
    <div className="shift-card-container">
      {isExpanded ? (
        <ShiftExpanded
          shift={shift}
          batteryLevels={batteryLevels}
          onCollapse={handleToggle}
          onFlagShift={handleFlagShift}
          timeRatio={timeRatio}
          deviceId={queryDeviceId}
        />
      ) : (
        <ShiftCollapsed
          shift={shift}
          batteryLevels={batteryLevels}
          onExpand={handleToggle}
          onFlagShift={handleFlagShift}
          timeRatio={timeRatio}
          deviceId={queryDeviceId}
        />
      )}
    </div>
  );
};

export default ShiftCard;
