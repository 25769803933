import React, { useState, useEffect } from 'react';
import '../styles/Configuration.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus, faWalkieTalkie, faSave, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { cosmosClient } from '../utils/eventstorage';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Link } from 'react-router-dom';

const Configuration = ({ userId, retentionPeriodDays, featureMode, userName }) => {
  const [triggers, setTriggers] = useState([]);
  const [errors, setErrors] = useState([]);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [liveViewPolicy, setLiveViewPolicy] = useState('During Shift');
  const [recordingPolicy, setRecordingPolicy] = useState('User');
  const [consentModalOpen, setConsentModalOpen] = useState(false);
  const [pendingSave, setPendingSave] = useState(false);
  const [initialPhoneNumbers, setInitialPhoneNumbers] = useState([]);

  useEffect(() => {
    const fetchInitialTriggersAndPolicy = async () => {
      try {
        const devicesContainer = cosmosClient.database('Devices').container('Devices');
        const { resources: devices } = await devicesContainer.items
          .query({
            query: 'SELECT * FROM c WHERE c.userId = @userId',
            parameters: [{ name: '@userId', value: userId }],
          })
          .fetchAll();

        if (devices.length > 0) {
          const firstDevice = devices[0];
          const keywords = (firstDevice.activationKeyword || '').split(',').filter(Boolean);
          const phoneNumbers = (firstDevice.notificationPhoneNumber || '').split(',');

          const initialTriggers = keywords.map((keyword, index) => ({
            condition: keyword.trim(),
            actions: {
              startRecording: true,
              generateEscalation: true,
              sendWhatsApp: phoneNumbers[index] ? true : false,
            },
            phoneNumber: phoneNumbers[index]
              ? `(${phoneNumbers[index].slice(0, 3)}) ${phoneNumbers[index].slice(3, 6)}-${phoneNumbers[index].slice(6)}`
              : '',
          }));

          setTriggers(initialTriggers);
          setErrors(initialTriggers.map(() => ({ condition: '', actions: {}, phoneNumber: '' })));
          setLiveViewPolicy(firstDevice.liveViewPolicy || 'During Shift');
          setInitialPhoneNumbers(phoneNumbers);
          setRecordingPolicy(firstDevice.recordingPolicy || 'User');
          // setRecordingPolicy('User');
        }
      } catch (error) {
        console.error('Failed to fetch initial triggers and policy:', error);
      }
    };

    fetchInitialTriggersAndPolicy();
  }, [userId]);

  const handleAddTrigger = () => {
    setTriggers([
      ...triggers,
      {
        condition: '',
        actions: { startRecording: true, generateEscalation: true, sendWhatsApp: false },
        phoneNumber: '',
      },
    ]);
    setErrors([...errors, { condition: '', actions: {}, phoneNumber: '' }]);
    setUnsavedChanges(true);
  };

  const handleTriggerChange = (index, field, value) => {
    const newTriggers = [...triggers];
    if (field === 'actions') {
      newTriggers[index][field] = value;
    } else {
      newTriggers[index][field] = value || '';
    }
    setTriggers(newTriggers);

    const newErrors = [...errors];
    newErrors[index][field] = validateField(field, value, newTriggers[index]);
    setErrors(newErrors);
    setUnsavedChanges(true);
  };

  const validateField = (field, value, trigger) => {
    switch (field) {
      case 'condition':
        if (!value || !value.trim()) return 'Trigger phrase is required';
        if (value.trim().length < 4) return 'Trigger phrase must be at least 4 characters long';
        if (value.includes('Wolverines')) return 'Invalid sports team to support';
        if (value.trim().split(' ').length > 2 || value.length > 50) return 'Must be at most 2 words and 50 characters';
        break;
      case 'actions':
        if (
          !value ||
          (typeof value === 'object' && !value.startRecording && !value.generateEscalation && !value.sendWhatsApp)
        )
          return 'At least one action is required';
        break;
      case 'phoneNumber':
        if (trigger && trigger.actions.sendWhatsApp && (!value || !value.trim())) return 'Phone number is required';
        const phonePattern = /^\(\d{3}\) \d{3}-\d{4}$/;
        if (value && !phonePattern.test(value)) return 'Valid phone number required.';
        break;
      default:
        break;
    }
    return '';
  };

  const handleRemoveTrigger = (index) => {
    const newTriggers = triggers.filter((_, i) => i !== index);
    setTriggers(newTriggers);
    const newErrors = errors.filter((_, i) => i !== index);
    setErrors(newErrors);
    setUnsavedChanges(true);
    updateDeviceKeywords(newTriggers);
  };

  const updateDeviceKeywords = async (newTriggers) => {
    const activationKeywords = newTriggers
      .map((trigger) => trigger.condition.trim())
      .filter(Boolean)
      .join(',');

    const notificationPhoneNumbers = newTriggers
      .map((trigger) => (trigger.actions.sendWhatsApp ? trigger.phoneNumber.replace(/[^\d]/g, '') : ''))
      .filter(Boolean)
      .join(',');

    const devicesContainer = cosmosClient.database('Devices').container('Devices');
    const orgContainer = cosmosClient.database('Organizations').container('Organizations');

    try {
      // Fetch devices
      const { resources: devices } = await devicesContainer.items
        .query({
          query: 'SELECT * FROM c WHERE c.userId = @userId',
          parameters: [{ name: '@userId', value: userId }],
        })
        .fetchAll();

      // Fetch organization
      const { resources: orgs } = await orgContainer.items
        .query({
          query: 'SELECT * FROM c WHERE c.orgUserId = @userId',
          parameters: [{ name: '@userId', value: userId }],
        })
        .fetchAll();

      if (orgs.length === 0) {
        throw new Error('Organization not found');
      }

      const org = orgs[0];

      // Update devices
      const updateDevicePromises = devices.map(async (device) => {
        const updatedDevice = {
          ...device,
          liveViewPolicy,
          recordingPolicy,
          activationKeyword: activationKeywords || undefined,
          notificationPhoneNumber: notificationPhoneNumbers || undefined,
        };

        try {
          await devicesContainer.item(device.id, device.deviceId).replace(updatedDevice);
          console.log(`Device ${device.deviceId} updated successfully`);
        } catch (error) {
          if (error.code === 404) {
            console.warn(`Device ${device.deviceId} not found, skipping update`);
          } else {
            throw error;
          }
        }
      });

      // Update organization
      const updatedOrg = {
        ...org,
        liveViewPolicy,
        recordingPolicy,
        activationKeyword: activationKeywords || undefined,
        notificationPhoneNumber: notificationPhoneNumbers || undefined,
      };

      await orgContainer.item(org.id, org.orgName).replace(updatedOrg);

      await Promise.all(updateDevicePromises);

      console.log('Device keywords, policies, and organization updated successfully');
      setInitialPhoneNumbers(notificationPhoneNumbers.split(',').filter(Boolean));
    } catch (error) {
      console.error('Failed to update device keywords, policies, and organization:', error);
      throw error;
    }
  };

  const handleSaveTrigger = async () => {
    const newPhoneNumbers = triggers
      .filter((trigger) => trigger.actions.sendWhatsApp)
      .map((trigger) => trigger.phoneNumber.replace(/[^\d]/g, ''));

    const hasNewPhoneNumber = newPhoneNumbers.some((number) => !initialPhoneNumbers.includes(number));

    if (!liveViewPolicy || !recordingPolicy) {
      setErrors((prevErrors) =>
        prevErrors.map((error) => ({
          ...error,
          liveViewPolicy: !liveViewPolicy ? 'Live View policy is required' : error.liveViewPolicy,
          recordingPolicy: !recordingPolicy ? 'Recording policy is required' : error.recordingPolicy,
        }))
      );
      return;
    }

    const newErrors = triggers.map((trigger) => ({
      condition: validateField('condition', trigger.condition),
      actions: {
        startRecording: validateField('actions', trigger.actions.startRecording ? 'startRecording' : ''),
        generateEscalation: validateField('actions', trigger.actions.generateEscalation ? 'generateEscalation' : ''),
        sendWhatsApp: validateField('actions', trigger.actions.sendWhatsApp ? 'sendWhatsApp' : ''),
      },
      phoneNumber: validateField('phoneNumber', trigger.phoneNumber, trigger),
    }));

    setErrors(newErrors);

    const hasErrors = newErrors.some(
      (error, index) =>
        error.condition ||
        error.actions.startRecording ||
        error.actions.generateEscalation ||
        (triggers[index].actions.sendWhatsApp && error.phoneNumber)
    );

    if (hasErrors) {
      return;
    }

    if (hasNewPhoneNumber) {
      setConsentModalOpen(true);
      setPendingSave(true);
      return;
    }

    completeSave();
  };

  const completeSave = async () => {
    if (
      triggers.every(
        (trigger) =>
          trigger.condition &&
          trigger.actions.startRecording &&
          trigger.actions.generateEscalation &&
          (!trigger.actions.sendWhatsApp || (trigger.actions.sendWhatsApp && trigger.phoneNumber))
      )
    ) {
      try {
        await updateDeviceKeywords(triggers);
        setUnsavedChanges(false);
        alert('Configuration saved successfully for both devices and organization');
      } catch (error) {
        console.error('Failed to save configuration:', error);
        alert('Failed to save configuration. Please check the console for more details and try again.');
      }
    } else {
      const newErrors = triggers.map((trigger) => ({
        condition: validateField('condition', trigger.condition),
        actions: {
          startRecording: validateField('actions', trigger.actions.startRecording ? 'startRecording' : ''),
          generateEscalation: validateField('actions', trigger.actions.generateEscalation ? 'generateEscalation' : ''),
          sendWhatsApp: validateField('actions', trigger.actions.sendWhatsApp ? 'sendWhatsApp' : ''),
        },
        phoneNumber: validateField('phoneNumber', trigger.phoneNumber, trigger),
      }));
      setErrors(newErrors);
    }
    setPendingSave(false);
  };

  const handleConsentAgree = () => {
    setConsentModalOpen(false);
    completeSave();
  };

  const handleConsentCancel = () => {
    setConsentModalOpen(false);
    setPendingSave(false);
  };

  const handlePolicyChange = (event, newValue) => {
    console.log('Live view policy:', newValue);
    setLiveViewPolicy(newValue);
    setUnsavedChanges(true);
  };

  const handleRecordingPolicyChange = (event, newValue) => {
    console.log('Recording policy:', newValue);
    setRecordingPolicy(newValue);
    setUnsavedChanges(true);
  };

  return (
    <div className="configuration-wrapper">
      <div className="configuration-container">
        <div className="configuration-header">
          <h1 className="configuration-title">Configuration</h1>
          <div className="save-section">
            {unsavedChanges && <span className="unsaved-changes-message show">Unsaved changes!</span>}

            <button onClick={handleSaveTrigger} className="save-button">
              <FontAwesomeIcon icon={faSave} className="button-icon" />
              Save
            </button>
          </div>
        </div>

        <div className="configuration-section">
          <h2>Trigger Phrases</h2>
          <div className="trigger-info">
            <FontAwesomeIcon icon={faWalkieTalkie} className="trigger-icon" />
            <span>Trigger an escalation and start recording when a user says a particular phrase.</span>
          </div>
          <div className="triggers-list">
            {triggers.map((trigger, index) => (
              <div key={index} className="trigger-row">
                {/* <span className="trigger-label">If user says</span> */}
                <Autocomplete
                  freeSolo
                  options={['Code Red', 'Code Pink', 'Code Blue', 'Code Green', 'Code Yellow']}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      error={!!errors[index]?.condition} // Add optional chaining
                      helperText={errors[index]?.condition} // Add optional chaining
                    />
                  )}
                  value={trigger.condition || ''}
                  onChange={(event, newValue) => handleTriggerChange(index, 'condition', newValue)}
                  inputValue={trigger.condition || ''}
                  onInputChange={(event, newInputValue) => handleTriggerChange(index, 'condition', newInputValue)}
                  className="trigger-input"
                />
                <FontAwesomeIcon
                  icon={faTrash}
                  className="trigger-delete-icon"
                  onClick={() => handleRemoveTrigger(index)}
                />
              </div>
            ))}
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '50px' }}>
            <Button variant="contained" color="primary" onClick={handleAddTrigger} className="add-trigger-button">
              <FontAwesomeIcon icon={faPlus} className="button-icon" />
              Add Trigger Phrase
            </Button>
          </div>
        </div>
        <div className="configuration-section">
          <h2>Video History Settings</h2>
          <div className="policy-container">
            <div className="policy-dropdown">
              <label>Retention Period</label>
              <div className="policy-description">
                <span>Number of days video recordings will be stored before being deleted.</span>
              </div>
              <FormControl component="fieldset">
                <RadioGroup aria-label="retention-policy" name="retention-policy" value={retentionPeriodDays}>
                  <FormControlLabel
                    value={30}
                    control={<Radio />}
                    label="30 days"
                    disabled={retentionPeriodDays !== 30}
                  />
                  <FormControlLabel
                    value={90}
                    control={<Radio />}
                    label="90 days"
                    disabled={retentionPeriodDays !== 90}
                  />
                  <FormControlLabel
                    value={100000}
                    control={<Radio />}
                    label="Unlimited"
                    disabled={retentionPeriodDays !== 100000}
                  />
                </RadioGroup>
              </FormControl>
              <div className="option-description">
                <span>Video recordings will be deleted after {retentionPeriodDays} days.</span>
              </div>
              <div></div>
            </div>
          </div>
        </div>
        <div className="configuration-section">
          <h2>Device Settings</h2>
          <div className="policy-container">
            <div className="policy-dropdown">
              <label>Video Recording</label>
              <div className="policy-description">Controls how video recording is triggered</div>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="recording-policy"
                  name="recording-policy"
                  value={recordingPolicy}
                  onChange={(event) => handleRecordingPolicyChange(event, event.target.value)}
                >
                  <FormControlLabel value="User" control={<Radio />} label="Triggered By User" />
                  <FormControlLabel value="Automatic" control={<Radio />} label="When Device is in Shift" />
                  {/* <FormControlLabel
                    value="Automatic"
                    control={<Radio />}
                    label="When Device is in Shift"
                    className="disabled-option"
                  /> */}
                </RadioGroup>
              </FormControl>
              <div className="option-description">
                {recordingPolicy === 'User'
                  ? 'Video recording will only start when manually triggered by the user.'
                  : 'Video recording will start automatically when the devices are in shift.'}
              </div>
            </div>
            <div className="policy-dropdown">
              <label>Live View</label>
              <div className="policy-description">Controls when live view is allowed</div>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="live-view-policy"
                  name="live-view-policy"
                  value={liveViewPolicy}
                  onChange={(event) => handlePolicyChange(event, event.target.value)}
                >
                  <FormControlLabel value="During Shift" control={<Radio />} label="During Shift" />
                  <FormControlLabel value="Only During Recording" control={<Radio />} label="Only During Recording" />
                </RadioGroup>
              </FormControl>
              <div className="option-description">
                {liveViewPolicy === 'During Shift'
                  ? 'Live view will be available throughout the entire shift.'
                  : 'Live view will only be available when video recording is active.'}
              </div>
            </div>
          </div>
        </div>

        {featureMode === 'dev' && (
          <div className="configuration-section">
            <h2>Advanced Settings</h2>
            <Link to="/device-management" className="device-management-link">
              Manage Devices
            </Link>
          </div>
        )}
      </div>

      <Modal open={consentModalOpen} onClose={handleConsentCancel}>
        <Box className="modal-container">
          <h2>Consent Required</h2>
          <p>By providing your phone number, you consent to receive text messages for notifications. Do you agree?</p>
          <div className="modal-buttons">
            <Button onClick={handleConsentCancel} className="cancel-button">
              Cancel
            </Button>
            <Button onClick={handleConsentAgree} className="confirm-button">
              Agree
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default Configuration;
