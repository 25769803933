import React, { useState, memo, useCallback, useRef, useEffect } from 'react';
import '../styles/SearchResult.css';
import '../styles/InfoTooltip.css';
import '../styles/InfoTooltip.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { stringToColor } from '../utils/utilsUI';
import VideoPlayer from './VideoPlayer';
import Modal from '@mui/material/Modal';
import {
  faLink,
  faTrash,
  faInfoCircle,
  faVideo,
  faClock,
  faCircleUser,
  faMagicWandSparkles,
  faThumbsUp,
  faThumbsDown,
} from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import LabelAlertPopup, { coalesceCategories } from './LabelAlertPopup';
import { PLIX_OVERWATCH_ACCOUNT } from '../utils/utilsEvents';
import { updateAlertClassification, updateAlertLabels } from '../utils/dashboardCosmosQueries';
import categoriesData from '../config/categories.json';
import { formatTimestamp } from '../utils/utilsUI';
import InfoTooltip from './InfoTooltip';
import Transcript from './Transcript';

const getEscalationMethods = (interaction) => {
  const methods = {};
  Object.keys(interaction).forEach((key) => {
    if (key.startsWith('EscalationClass_') || key.startsWith('EscalationCategory_')) {
      const [type, ...methodParts] = key.split('_');
      const method = methodParts.join('_');
      if (!methods[method]) {
        methods[method] = {};
      }
      methods[method][type] = interaction[key];
    }
  });

  // Add gpt4o_post method if VideoLabel is present
  if (interaction.VideoLabel) {
    methods['gpt4o_post'] = {
      EscalationClass: interaction.VideoLabel,
    };
  }

  return methods;
};

const getCategoryColor = (categoryName) => {
  const category = categoriesData.categories.find((c) => c.name === categoryName);
  return category ? category.color : '#e0e0e0'; // default color if not found
};

const SearchResult = memo(
  ({ interaction, type, index, onDelete, featureMode, devices, userId }) => {
    const [currentInteraction, setCurrentInteraction] = useState(interaction);
    const backgroundColor = stringToColor(interaction.assignedTo || interaction.deviceId);
    const token = process.env.REACT_APP_BLOB_SAS_TOKEN;

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [showVideo, setShowVideo] = useState(false);
    const [showLabelAlert, setShowLabelAlert] = useState(false);
    const [labelAlertInitialValues, setLabelAlertInitialValues] = useState({});
    const resultRef = useRef(null);

    useEffect(() => {
      if (showVideo && resultRef.current) {
        resultRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
      }
    }, [showVideo]);

    const markers = interaction.alerts.map((alert) => ({
      time: alert.RelativeTimestamp,
      label: alert.Label,
    }));
    console.log('markers from dash', markers);
    const getDeviceName = (deviceId) => {
      const device = devices.find((device) => device.deviceId === deviceId);
      return device ? (device.assignedTo ? device.assignedTo : deviceId) : 'Unknown Device';
    };

    const handleDelete = useCallback(async () => {
      try {
        const updatedInteraction = {
          ...currentInteraction,
          IsDeleted: true,
          ClassificationLabel: 'Error',
        };

        // Update Cosmos DB
        await updateAlertLabels(updatedInteraction.id, updatedInteraction.deviceId, {
          IsDeleted: true,
          ClassificationLabel: 'Error',
        });

        // Update local state
        setCurrentInteraction(updatedInteraction);

        setShowDeleteModal(false);
        if (onDelete) {
          onDelete(updatedInteraction);
        }
      } catch (error) {
        console.error('Failed to update the alert:', error);
      }
    }, [currentInteraction, onDelete]);

    const handleErrorClassification = async () => {
      try {
        const updatedInteraction = {
          ...currentInteraction,
          IsDeleted: false,
          ClassificationLabel: 'Correct',
        };

        // Update Cosmos DB
        await updateAlertLabels(updatedInteraction.id, updatedInteraction.deviceId, {
          IsDeleted: false,
          ClassificationLabel: 'Correct',
        });

        // Update local state
        setCurrentInteraction(updatedInteraction);

        if (onDelete) {
          onDelete(updatedInteraction);
        }
      } catch (error) {
        console.error('Failed to update the alert:', error);
      }
    };

    const handleLabelAlertSubmit = async (values) => {
      try {
        const updatedInteraction = await updateAlertLabels(interaction.id, interaction.deviceId, values);
        console.log('updatedInteraction: ', updatedInteraction);
        setCurrentInteraction(updatedInteraction);
        setShowLabelAlert(false);
        if (onDelete) {
          onDelete(updatedInteraction);
        }
      } catch (error) {
        console.error('Failed to update the alert:', error);
      }
    };

    const handleLabelAlertOpen = () => {
      setLabelAlertInitialValues({
        ClassificationLabel: currentInteraction.ClassificationLabel || 'Unlabeled',
        CategorizationLabel: currentInteraction.CategorizationLabel || '',
        IsDeleted: currentInteraction.IsDeleted || false,
      });
      setShowLabelAlert(true);
    };

    const handleThumbClick = async (newClassification) => {
      try {
        const updatedInteraction = {
          ...currentInteraction,
          ClassificationLabel: newClassification,
        };

        // Update Cosmos DB
        await updateAlertLabels(updatedInteraction.id, updatedInteraction.deviceId, {
          ClassificationLabel: newClassification,
        });

        // Update local state
        setCurrentInteraction(updatedInteraction);

        if (onDelete) {
          onDelete(updatedInteraction);
        }
      } catch (error) {
        console.error('Failed to update the alert:', error);
      }
    };

    const getThumbButtonClass = (thumbType) => {
      const isActive = currentInteraction.ClassificationLabel === (thumbType === 'up' ? 'Correct' : 'Error');
      return `thumb-button ${isActive ? 'active' : ''}`;
    };

    const formatPrompt = (prompt) => {
      return prompt ? prompt.split('\n').map((line, index) => <p key={index}>{line}</p>) : null;
    };

    const containerStyle = {
      backgroundColor: featureMode === 'dev' && currentInteraction.IsDeleted ? '#ffe6e6' : 'white',
      opacity: currentInteraction.IsDeleted ? 0.5 : 1,
    };

    const handleGoToRecording = () => {
      const timestamp = interaction.alerts[0].RelativeTimestamp;
      const encodedFilename = encodeURIComponent(interaction.videoFileName);
      return `/videos?filename=${encodedFilename}&timestamp=${timestamp}`;
    };

    const truncateDescription = (description, maxLength) => {
      if (description.length <= maxLength) return '"' + description + '"';

      const truncated = description.substring(0, maxLength);
      const lastSpaceIndex = truncated.lastIndexOf(' ');

      if (lastSpaceIndex === -1) return truncated + '...';

      return '"' + truncated.substring(0, lastSpaceIndex) + '...' + '"';
    };

    const videoRef = useRef(null);
    const [videoTime, setVideoTime] = useState(0);

    const getPlayerState = useCallback(() => {
      if (videoRef.current && videoRef.current.plyr) {
        return {
          currentTime: videoRef.current.plyr.currentTime,
          isPlaying: !videoRef.current.plyr.paused,
        };
      }
      return { currentTime: 0, isPlaying: false };
    }, []);

    const handleVideoTimeUpdate = useCallback(() => {
      if (videoRef.current) {
        setVideoTime(videoRef.current.currentTime);
      }
    }, []);

    const handleTranscriptClick = (timestamp) => {
      if (videoRef.current && videoRef.current.plyr) {
        videoRef.current.plyr.currentTime = timestamp;
      }
    };

    const getCLAPCategories = (clapResults) => {
      if (!clapResults || !clapResults.Category) return [];

      const coalescedCategories = coalesceCategories(clapResults.Category);
      return coalescedCategories;
    };

    // Filter out search results where ClassificationLabel is Error unless the account is overwatch
    if (currentInteraction.ClassificationLabel === 'Error' && userId !== PLIX_OVERWATCH_ACCOUNT) {
      return null;
    }

    // Handle cases where not all expected parameters are provided
    const deviceName = interaction.assignedTo || getDeviceName(interaction.deviceId) || 'Unknown Device';
    const interactionDescription = interaction.Description || 'No description available';
    const interactionTimestamp = interaction.Timestamp
      ? new Date(interaction.Timestamp).toLocaleString()
      : 'Unknown time';

    const getLabelAlertButtonClass = (c) => {
      switch (c) {
        case 'Correct':
          return ' label-alert-button label-correct';
        case 'Error':
          return ' label-alert-button label-error';
        default:
          return 'label-alert-button';
      }
    };

    const getTopCategory = (categories) => {
      if (!categories) return null;

      const categoryList = categories.split(',');
      const priorityCategories = categoriesData.categories.filter((c) => c.priority === 2 || c.priority === 3);

      for (let category of priorityCategories) {
        if (categoryList.includes(category.name)) {
          return category.display || category.name;
        }
      }

      for (let category of categoriesData.categories) {
        if (categoryList.includes(category.name)) {
          return category.display || category.name;
        }
      }

      return categoryList[0];
    };

    return (
      <div className="interaction-container" style={containerStyle} id={`alert-${interaction.id}`} ref={resultRef}>
        <div className="interaction-header">
          <div className="interaction-id-category">
            <p
              className="interaction-deviceId"
              style={{ backgroundColor, padding: '5px 10px', borderRadius: '5px', color: '#333', marginTop: '10px' }}
            >
              {deviceName}
            </p>
            {userId === PLIX_OVERWATCH_ACCOUNT && (
              <Tooltip
                title={
                  <div className="custom-tooltip">
                    {Object.entries(getEscalationMethods(interaction)).map(([method, data]) => (
                      <div key={method} className="method-section">
                        <div className="method-header">
                          <span className="method-name">{method}</span>
                          {data.EscalationClass && (
                            <FontAwesomeIcon
                              icon={data.EscalationClass === 'Escalation' ? faThumbsUp : faThumbsDown}
                              className="method-icon"
                            />
                          )}
                        </div>
                        {data.EscalationCategory && (
                          <div className="category-pills">
                            {typeof data.EscalationCategory === 'string'
                              ? data.EscalationCategory.split(',').map((cat) => (
                                  <span
                                    key={cat}
                                    className="category-pill"
                                    style={{ backgroundColor: getCategoryColor(cat.trim()) }}
                                  >
                                    {cat.trim()}
                                  </span>
                                ))
                              : Object.keys(data.EscalationCategory).map((cat) => (
                                  <span
                                    key={cat}
                                    className="category-pill"
                                    style={{ backgroundColor: getCategoryColor(cat) }}
                                  >
                                    {cat}
                                  </span>
                                ))}
                          </div>
                        )}
                      </div>
                    ))}
                    {interaction.CategorizationLabel && (
                      <div className="method-section">
                        <div className="method-header">
                          <span className="method-name">Ground Truth</span>
                        </div>
                        <div className="category-pills">
                          {interaction.CategorizationLabel.split(',').map((cat) => (
                            <span
                              key={cat}
                              className="category-pill"
                              style={{ backgroundColor: getCategoryColor(cat.trim()) }}
                            >
                              {cat.trim()}
                            </span>
                          ))}
                        </div>
                      </div>
                    )}
                    {interaction.EscalationCategory_CLAP && (
                      <div className="method-section">
                        <div className="method-header">
                          <span className="method-name">CLAP Categories</span>
                        </div>
                        <div className="category-pills">
                          {getCLAPCategories(interaction.EscalationCategory_CLAP).map((category) => (
                            <span
                              key={category}
                              className="category-pill"
                              style={{ backgroundColor: getCategoryColor(category) }}
                            >
                              {category}
                            </span>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                }
                placement="bottom-start"
                classes={{ tooltip: 'custom-tooltip-root' }}
              >
                <p className="interaction-category">{getTopCategory(interaction.EscalationCategory_gpt4o)}</p>
              </Tooltip>
            )}
          </div>
          <div className="icon-buttons">
            <Tooltip title={interaction.videoFileName ? 'Go to recording' : 'Recording queued for upload'} arrow>
              {interaction.videoFileName ? (
                <Link to={handleGoToRecording()} className="info-button recording-available">
                  <FontAwesomeIcon icon={faVideo} className="info-icon" style={{ color: 'gray' }} />
                </Link>
              ) : (
                <button className={`info-button recording-soon`}>
                  <FontAwesomeIcon icon={faClock} className="info-icon" style={{ color: 'gray' }} />
                </button>
              )}
            </Tooltip>
            <Tooltip title="Delete" arrow>
              <button
                className="trash-button"
                onClick={() => {
                  if (currentInteraction.ClassificationLabel === 'Error') {
                    handleErrorClassification();
                  } else {
                    setShowDeleteModal(true);
                  }
                }}
              >
                <FontAwesomeIcon icon={faTrash} className="trash-icon" style={{ color: 'gray' }} />
              </button>
            </Tooltip>
            {featureMode === 'dev' && (
              <Tooltip
                title={<InfoTooltip interaction={interaction} />}
                classes={{ tooltip: 'info-tooltip-root' }}
                placement="bottom-end"
                interactive
              >
                <span className="info-button">
                  <FontAwesomeIcon icon={faInfoCircle} className="info-icon" style={{ color: 'gray' }} />
                </span>
              </Tooltip>
            )}
            {userId === PLIX_OVERWATCH_ACCOUNT && (
              <>
                <Tooltip title="Mark as Correct" arrow>
                  <button className={getThumbButtonClass('up')} onClick={() => handleThumbClick('Correct')}>
                    <FontAwesomeIcon icon={faThumbsUp} className="thumb-icon" />
                  </button>
                </Tooltip>
                <Tooltip title="Mark as Error" arrow>
                  <button className={getThumbButtonClass('down')} onClick={() => handleThumbClick('Error')}>
                    <FontAwesomeIcon icon={faThumbsDown} className="thumb-icon" />
                  </button>
                </Tooltip>
                <Tooltip title="Label Alert" arrow>
                  <button
                    className={getLabelAlertButtonClass(currentInteraction.ClassificationLabel)}
                    onClick={handleLabelAlertOpen}
                  >
                    <FontAwesomeIcon
                      icon={faMagicWandSparkles}
                      className="label-alert-icon"
                      style={{ color: 'gray' }}
                    />
                  </button>
                </Tooltip>
              </>
            )}
          </div>
        </div>
        {type === 'incident' && (
          <div
            className={
              interaction.videoFileName ? 'interaction-content interaction-content-video' : 'interaction-content'
            }
          >
            {interaction.videoFileName ? (
              showVideo ? (
                <div key={`${interaction.id}-${index}`} className="video-player-container">
                  <VideoPlayer
                    videoFileName={interaction.videoFileName}
                    markers={markers}
                    token={token}
                    ref={videoRef}
                    preStart={30}
                    onTimeUpdate={handleVideoTimeUpdate}
                    initialTime={videoTime}
                  />
                </div>
              ) : (
                <div className="video-placeholder">
                  <button className="view-video-button" onClick={() => setShowVideo(true)}>
                    View Video
                  </button>
                </div>
              )
            ) : (
              <div className="no-video-content"></div>
            )}
          </div>
        )}
        <div className="timeline">
          {(interaction.alerts || []).map((alert, alertIndex) => (
            <div key={alertIndex} className="timeline-item">
              <div className="timeline-dot"></div>
              <div className="timeline-content">
                <div className="timestamp">{new Date(alert.Timestamp || interaction.Timestamp).toLocaleString()}</div>
                <div className="alert-details">
                  <Transcript alert={alert} getPlayerState={getPlayerState} onTranscriptClick={handleTranscriptClick} />
                </div>
              </div>
            </div>
          ))}
        </div>

        <Modal
          open={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          aria-labelledby="delete-alert-modal-title"
          aria-describedby="delete-alert-modal-description"
        >
          <div className="modal-container">
            <h2 id="delete-alert-modal-title">Confirm Delete</h2>
            <p id="delete-alert-modal-description">
              Deleting this incident is a permanent action that cannot be undone. Are you sure you want to delete this
              incident?
            </p>
            <div className="modal-buttons">
              <button onClick={() => setShowDeleteModal(false)} className="cancel-delete-button">
                Cancel
              </button>
              <button onClick={handleDelete} className="confirm-delete-button">
                Yes, Delete
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          open={showInfoModal}
          onClose={() => setShowInfoModal(false)}
          aria-labelledby="info-alert-modal-title"
          aria-describedby="info-alert-modal-description"
        >
          <div className="modal-container info-modal-container">
            <h2 id="info-alert-modal-title">Alert Info</h2>
            <div id="info-alert-modal-description" className="modal-content">
              {formatPrompt(interaction.Prompt)}
            </div>
            <div className="modal-buttons">
              <button onClick={() => setShowInfoModal(false)} className="cancel-info-button">
                Close
              </button>
            </div>
          </div>
        </Modal>
        <LabelAlertPopup
          open={showLabelAlert}
          onClose={() => setShowLabelAlert(false)}
          onSubmit={handleLabelAlertSubmit}
          initialValues={labelAlertInitialValues}
        />
      </div>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.interaction.id === nextProps.interaction.id && prevProps.showVideo === nextProps.showVideo;
  }
);

export default SearchResult;
