import { fetchBatteryDropTime } from './dashboardTimescaleQueries';
import pLimit from 'p-limit';

const limit = pLimit(5); // Limit to 5 concurrent operations

// -------------------- Refreshing Metrics --------------------

const getDeviceName = (devices, deviceId) => {
  const device = devices.find((device) => device.deviceId === deviceId);
  return device ? (device.assignedTo ? device.assignedTo : deviceId) : 'Unknown Device';
};

export const calculateMetrics = async (devices, dateRange, escalations, logs, videos, setMetricsData) => {
  const deviceIds = devices.map((device) => device.deviceId);
  const fromDate = dateRange[0].toISOString();
  const toDate = dateRange[1].toISOString();
  const today = new Date();
  const numDaysInRange = (dateRange[1] - dateRange[0]) / (1000 * 60 * 60 * 24);

  console.log('Calculating metrics and reading from Cosmos');

  let totalEscalations = 0;
  const shiftDurations = {};
  const recordingDurations = {};

  const escalationsPerDay = {};
  const shiftDurationPerDay = {};
  const recordingDurationPerDay = {};

  const dateArray = Array.from(
    { length: numDaysInRange },
    (_, i) => new Date(dateRange[0].getTime() + i * 24 * 60 * 60 * 1000)
  );

  dateArray.forEach((date) => {
    const dateString = date.toISOString().split('T')[0];
    escalationsPerDay[dateString] = { Total: 0 };
    shiftDurationPerDay[dateString] = { Total: 0 };
    recordingDurationPerDay[dateString] = { Total: 0 };
  });

  const devicePromises = devices.map((device) =>
    limit(async () => {
      const deviceId = device.deviceId;
      const deviceName = getDeviceName(devices, deviceId);
      const deviceEscalations = escalations.filter((alert) => alert.DeviceId === deviceId);
      totalEscalations += deviceEscalations.length;

      deviceEscalations.forEach((escalation) => {
        const dateString = new Date(escalation.Timestamp).toISOString().split('T')[0];
        if (escalationsPerDay[dateString]) {
          escalationsPerDay[dateString].Total++;
          if (!escalationsPerDay[dateString][deviceName]) {
            escalationsPerDay[dateString][deviceName] = 0;
          }
          escalationsPerDay[dateString][deviceName]++;
        }
      });

      const deviceLogs = logs.filter((log) => log.deviceId === deviceId);
      shiftDurations[deviceId] = shiftDurations[deviceId] || {};

      await Promise.all(
        deviceLogs
          .filter((log) => log.action === 'Start Shift')
          .map(async (log) => {
            const logDate = new Date(log.timestamp).toDateString();
            let shiftEndTime;

            const endShift = deviceLogs.find(
              (s) => s.action === 'End Shift' && new Date(s.timestamp) > new Date(log.timestamp)
            );

            if (endShift) {
              shiftEndTime = new Date(endShift.timestamp);
            } else {
              const possibleEndTime = new Date(new Date(log.timestamp).getTime() + 8 * 60 * 60 * 1000);
              shiftEndTime = possibleEndTime < today ? possibleEndTime : today;
            }

            const nextStartShift = deviceLogs.find(
              (s) => s.action === 'Start Shift' && new Date(s.timestamp) > new Date(log.timestamp)
            );
            if (nextStartShift && new Date(nextStartShift.timestamp) < shiftEndTime) {
              shiftEndTime = new Date(nextStartShift.timestamp);
            }

            let shiftDuration = (shiftEndTime - new Date(log.timestamp)) / (1000 * 60 * 60);
            let batteryDropTime;
            if (shiftDuration > 4) {
              batteryDropTime = await fetchBatteryDropTime(
                deviceId,
                new Date(log.timestamp),
                new Date(new Date(log.timestamp).getTime() + 12 * 60 * 60 * 1000)
              );
            }

            if (batteryDropTime && batteryDropTime < shiftEndTime) {
              shiftEndTime = batteryDropTime;
            }

            shiftDuration = (shiftEndTime - new Date(log.timestamp)) / (1000 * 60 * 60);
            if (shiftDuration > 20) {
              shiftDuration = 1;
            }

            if (!shiftDurations[deviceId][logDate]) {
              shiftDurations[deviceId][logDate] = 0;
            }
            shiftDurations[deviceId][logDate] += shiftDuration;

            const dateString = new Date(log.timestamp).toISOString().split('T')[0];
            if (shiftDurationPerDay[dateString]) {
              shiftDurationPerDay[dateString].Total += shiftDuration;
              if (!shiftDurationPerDay[dateString][deviceName]) {
                shiftDurationPerDay[dateString][deviceName] = 0;
              }
              shiftDurationPerDay[dateString][deviceName] += shiftDuration;
            }

            console.log(
              'Shift - device: ',
              deviceId,
              ' duration: ',
              shiftDuration,
              ' start: ',
              new Date(log.timestamp),
              ' end: ',
              shiftEndTime
            );
          })
      );

      const deviceVideos = videos.filter((video) => video.deviceId === deviceId);
      recordingDurations[deviceId] = deviceVideos.reduce((total, video) => {
        return total + video.Duration / (1000 * 60 * 60);
      }, 0);

      deviceVideos.forEach((video) => {
        const dateString = new Date(video.TimeRecorded).toISOString().split('T')[0];
        if (recordingDurationPerDay[dateString]) {
          recordingDurationPerDay[dateString].Total += video.Duration / (1000 * 60 * 60);
          if (!recordingDurationPerDay[dateString][deviceName]) {
            recordingDurationPerDay[dateString][deviceName] = 0;
          }
          recordingDurationPerDay[dateString][deviceName] += video.Duration / (1000 * 60 * 60);
        }
      });
    })
  );

  await Promise.all(devicePromises);
  console.log('Finished shifts from all devices');

  setMetricsData({
    escalations: dateArray.map((date) => {
      const dateString = date.toISOString().split('T')[0];
      const dayData = escalationsPerDay[dateString];
      devices.forEach((device) => {
        const deviceName = getDeviceName(devices, device.deviceId);
        if (!dayData[deviceName]) {
          dayData[deviceName] = 0;
        }
      });
      return {
        name: dateString,
        Total: dayData.Total,
        ...dayData,
      };
    }),
    shiftDuration: dateArray.map((date) => {
      const dateString = date.toISOString().split('T')[0];
      const dayData = shiftDurationPerDay[dateString];
      devices.forEach((device) => {
        const deviceName = getDeviceName(devices, device.deviceId);
        if (!dayData[deviceName]) {
          dayData[deviceName] = 0;
        }
      });
      return {
        name: dateString,
        Total: dayData.Total,
        ...dayData,
      };
    }),
    recordingDuration: dateArray.map((date) => {
      const dateString = date.toISOString().split('T')[0];
      const dayData = recordingDurationPerDay[dateString];
      devices.forEach((device) => {
        const deviceName = getDeviceName(devices, device.deviceId);
        if (!dayData[deviceName]) {
          dayData[deviceName] = 0;
        }
      });
      return {
        name: dateString,
        Total: dayData.Total,
        ...dayData,
      };
    }),
  });

  const averageEscalationsPerDayCalc = totalEscalations / devices.length / numDaysInRange;
  const totalShiftHours = Object.values(shiftDurations).reduce((sum, deviceShifts) => {
    return sum + Object.values(deviceShifts).reduce((deviceSum, shiftDuration) => deviceSum + shiftDuration, 0);
  }, 0);
  const averageShiftDurationPerDay = totalShiftHours / devices.length / numDaysInRange;
  const totalRecordingHoursCalc = Object.values(recordingDurations).reduce((sum, duration) => sum + duration, 0);
  const averageRecordingDurationPerDay = totalRecordingHoursCalc / devices.length / numDaysInRange;

  console.log('Average number of escalations per day per device:', averageEscalationsPerDayCalc);
  console.log('Average number of hours spent in shift per day per device:', averageShiftDurationPerDay);
  console.log('Average number of hours spent recording per day per device:', averageRecordingDurationPerDay);
  console.log('Total hours spent recording:', totalRecordingHoursCalc);
};

export const calculateMemoizedMetrics = (metricsData, selectedDevices, metricsLoaded) => {
  if (!metricsLoaded || !metricsData.escalations || !metricsData.shiftDuration || !metricsData.recordingDuration) {
    return {
      averageEscalationsPerDay: '--',
      averageShiftDurationPerDay: '--',
      averageRecordingDurationPerDay: '--',
      totalRecordingHours: '--',
    };
  }
  const numDays = metricsData.escalations.length;
  const numDevices = selectedDevices.length;
  const totalEscalations = metricsData.escalations.reduce((sum, day) => sum + day.Total, 0);
  const totalShiftHours = metricsData.shiftDuration.reduce((sum, day) => sum + day.Total, 0);
  const totalRecordingHours = metricsData.recordingDuration.reduce((sum, day) => sum + day.Total, 0);
  return {
    averageEscalationsPerDay: (totalEscalations / numDevices / numDays).toFixed(1),
    averageShiftDurationPerDay: (totalShiftHours / numDevices / numDays).toFixed(2),
    averageRecordingDurationPerDay: (totalRecordingHours / numDevices / numDays).toFixed(2),
    totalRecordingHours: totalRecordingHours.toFixed(2),
  };
};
