import React from 'react';
import '../../../../../../../styles/NewDashboard/ShiftReview/ShiftCard/ShiftExpanded/EventInfo/LocationInfo.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowUpRightFromSquare,
  faUser,
  faMapMarkerAlt,
  faClock,
  faCalendar,
} from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from '@mui/material';

const LocationInfo = ({ event, assignedTo, alertData, allEvents }) => {
  console.log('event', event);
  if (!event.event) {
    return (
      <div className="location-info-container">
        <h3>Event Info</h3>
        <p>No event information available</p>
      </div>
    );
  }

  const handleGoToRecording = () => {
    const timestamp = alertData.RelativeTimestamp;
    const encodedFilename = encodeURIComponent(alertData.VideoFileName);
    return `/videos?filename=${encodedFilename}&timestamp=${timestamp}`;
  };

  const formatTime = (event) => {
    const dateString = event.timestamp || event.start || event.timestampStart;
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return isNaN(date.getTime())
      ? 'Invalid Date'
      : date.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit' });
  };

  const formatDate = (event) => {
    const dateString = event.timestamp || event.start || event.timestampStart;
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return isNaN(date.getTime())
      ? 'Invalid Date'
      : date.toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
  };

  const address = event.event.location ? event.event.location.address : 'Address not available';

  const eventTypes = {
    escalation: { color: 'warning', title: 'Escalation' },
    offline: { color: 'error', title: 'Offline' },
    start: { color: 'success', title: 'Shift Started' },
    end: { color: 'error', title: 'Shift Ended' },
    default: { color: 'info', title: 'Event' },
    recording_end: { color: 'info', title: 'Recording Ended' },
    recording_start: { color: 'info', title: 'Recording Started' },
    online: { color: 'success', title: 'Online' },
  };

  const getEventTitle = (event, index, events) => {
    if (event.type === 'start') {
      return index === 0 ? 'Shift Started' : 'Online';
    } else if (event.type === 'end') {
      return index === events.length - 1 ? 'Shift Ended' : 'Offline';
    } else {
      return eventTypes[event.type]?.title || eventTypes.default.title;
    }
  };

  return (
    <div className="location-info-container">
      <div className="location-info-header">
        <h3>{getEventTitle(event.event, event.index, allEvents)}</h3>
        <div className="header-spacer">
          {alertData && (
            <Tooltip title="Go to recording">
              <Link to={handleGoToRecording()} className="info-button recording-available" title="View Video">
                <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="info-icon" style={{ color: 'gray' }} />
              </Link>
            </Tooltip>
          )}
        </div>
      </div>
      <div className="location-details">
        <div className="location-detail-row">
          <div className="location-detail-icon">
            <FontAwesomeIcon icon={faUser} />
          </div>
          <div className="location-detail-value">{assignedTo || 'N/A'}</div>
        </div>
        <div className="location-detail-row">
          <div className="location-detail-icon">
            <FontAwesomeIcon icon={faClock} />
          </div>
          <div className="location-detail-value">{formatTime(event.event)}</div>
        </div>
        <div className="location-detail-row">
          <div className="location-detail-icon">
            <FontAwesomeIcon icon={faCalendar} />
          </div>
          <div className="location-detail-value">{formatDate(event.event)}</div>
        </div>
        <div className="location-detail-row">
          <div className="location-detail-icon">
            <FontAwesomeIcon icon={faMapMarkerAlt} />
          </div>
          <div className="location-detail-value">{address}</div>
        </div>
      </div>
    </div>
  );
};

export default LocationInfo;
