import '../../../../../../styles/NewDashboard/ShiftReview/ShiftCard/ShiftCollapsed.css';
import '../../../../../../styles/NewDashboard/ShiftReview/ShiftCard/ShiftCard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faFlag as faFlagSolid } from '@fortawesome/free-solid-svg-icons';
import { faFlag as faFlagOutlined } from '@fortawesome/free-regular-svg-icons';
import { faBatteryQuarter } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
// import { formatDuration } from '../../../../../../utils/timeUtils';
import { getDaysDifference } from '../../../../../../utils/shiftUtils';
import React from 'react';
import { Tooltip } from '@mui/material';

const ShiftCollapsed = ({ shift, batteryLevels, onExpand, onFlagShift, timeRatio }) => {
  const { assignedTo, startTime, endTime, events, duration, inProgress } = shift;
  const escalationCount = events.filter((event) => event.type === 'escalation').length;
  const formatDuration = (durationInSeconds) => {
    // console.log('durationInSeconds', durationInSeconds);
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    console.log('hours', hours);
    console.log('minutes', minutes);
    return `${hours}h ${minutes}m`;
  };

  const handleFlagShift = (e) => {
    e.stopPropagation(); // Stop the event from propagating to parent elements
    const currentFlagStatus = Boolean(shift.isFlagged);
    const newFlagStatus = !currentFlagStatus;
    console.log(`Toggling flag for shift ${shift.id} from ${currentFlagStatus} to ${newFlagStatus}`);
    onFlagShift(shift.id, newFlagStatus);
  };

  const [isHovered, setIsHovered] = useState(false);

  const onlineTime = formatDuration(shift.calculatedOnlineTime);
  const offlineTime = formatDuration(shift.calculatedOfflineTime);

  // const timeRatioTooltip = (
  //   <div>
  //     <div>Online: {onlineTime}</div>
  //     <div>Offline: {offlineTime}</div>
  //   </div>
  // );
  const daysDifference = endTime ? getDaysDifference(startTime, endTime) : 0;

  const formatDecimalHours = (seconds) => {
    const hours = seconds / 3600;
    return Math.trunc(hours * 10) / 10; // Truncate to 1 decimal place
  };

  // const onlineTimeDecimal = formatDecimalHours(shift.calculatedOnlineTime);
  // const offlineTimeDecimal = formatDecimalHours(shift.calculatedOfflineTime);

  const timeRatioTooltip = (
    <div className="custom-tooltip">
      <div className="tooltip-row">
        <span className="tooltip-dot online"></span>
        <span className="tooltip-label">Online</span>
        <span className="tooltip-value">{onlineTime}</span>
      </div>
      <div className="tooltip-row">
        <span className="tooltip-dot offline"></span>
        <span className="tooltip-label">Offline</span>
        <span className="tooltip-value">{offlineTime}</span>
      </div>
    </div>
  );

  return (
    <div className="shift-collapsed-container" onClick={onExpand}>
      <div className="shift-collapsed-left">
        <div className="shift-collapsed-personnel">{assignedTo}</div>
        {/* <div className="shift-collapsed-date">
          <span className="date-badge">{new Date(startTime).toLocaleDateString()}</span>
        </div> */}
        <div className="shift-collapsed-time">
          <div className="start-time-container">
            <span className="time-badge">
              {new Date(startTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
            </span>
            <span className="online-time-decimal">{new Date(startTime).toLocaleDateString()}</span>
          </div>
          <div className="duration-container">
            {!inProgress ? (
              <span className="online-time-decimal">{formatDuration(duration)}</span>
            ) : (
              <span className="online-time-decimal">{formatDuration((Date.now() - new Date(startTime)) / 1000)}</span>
            )}
            <hr className="duration-divider"></hr>
          </div>
          <div className="end-time-container">
            {inProgress ? (
              <>
                <span className="time-badge in-progress">In Progress</span>
                <span className="online-time-decimal">{new Date().toLocaleDateString()}</span>
              </>
            ) : (
              <>
                <span className="time-badge">
                  {new Date(endTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                  {daysDifference > 0 && <sup>+{daysDifference}</sup>}
                </span>
                <span className="online-time-decimal">{new Date(endTime).toLocaleDateString()}</span>
              </>
            )}
          </div>
        </div>
        {!inProgress && (
          <>
            {/* <span className="duration-badge">{formatDuration(duration)}</span> */}
            <div className="online-time-container">
              <span className="online-time-decimal">{onlineTime} online</span>
              <Tooltip title={timeRatioTooltip} classes={{ tooltip: 'custom-tooltip-wrapper' }}>
                <div className="time-ratio-bar">
                  <div
                    className="online-time"
                    style={{
                      width: `${timeRatio.onlineRatio}%`,
                    }}
                  ></div>
                  {shift.calculatedOfflineTime > 0 && (
                    <div
                      className="offline-time"
                      style={{
                        width: `${timeRatio.offlineRatio}%`,
                      }}
                    ></div>
                  )}
                </div>
              </Tooltip>
            </div>
          </>
        )}
      </div>
      <div className="shift-collapsed-right">
        <div className="shift-collapsed-escalations">
          {escalationCount > 0 && (
            <span className="escalation-badge">
              <>
                <FontAwesomeIcon icon={faExclamationTriangle} className="escalation-icon" />
                <span className="escalation-count">{escalationCount}</span>
              </>
            </span>
          )}
        </div>
      </div>
      <div className="shift-flags" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
        {shift.isFlagged ? (
          <FontAwesomeIcon icon={faFlagSolid} className="flag-icon filled" onClick={handleFlagShift} />
        ) : (
          <FontAwesomeIcon
            icon={isHovered ? faFlagSolid : faFlagOutlined}
            className={`flag-icon ${isHovered ? 'filled' : 'outlined'}`}
            onClick={handleFlagShift}
          />
        )}
      </div>
      <button className="shift-collapsed-expand">+</button>
    </div>
  );
};

export default ShiftCollapsed;
