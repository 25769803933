import React, { useState, useEffect, useRef } from 'react';
import ShiftToggle from './ShiftToggle/ShiftToggle';
import ShiftCardList from './ShiftCardList/ShiftCardList';
import '../../../styles/NewDashboard/ShiftReview/ShiftReview.css';
import {
  fetchShiftsForDevices,
  updateShiftFlagStatus,
  fetchAllFlaggedShiftsForDevices,
} from '../../../utils/dashboardCosmosQueries';
import { groupShiftsByDateRange } from '../../../utils/shiftUtils';

const MIN_ONLINE_TIME = 2 * 60 * 1000; // 5 minutes in milliseconds

const ShiftReview = ({ devices, userId, deviceAssignments }) => {
  const [shifts, setShifts] = useState({ today: [], yesterday: [], lastWeek: [], older: [] });
  const [flaggedShifts, setFlaggedShifts] = useState([]);
  const [selectedPersonnel, setSelectedPersonnel] = useState([]);
  const [showFlaggedOnly, setShowFlaggedOnly] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [expandedShiftIds, setExpandedShiftIds] = useState(new Set());
  const [flaggedShiftsCount, setFlaggedShiftsCount] = useState(0);
  // const [initialLoad, setInitialLoad] = useState(true);

  const updateShiftFlag = async (shiftId, isFlagged) => {
    console.log(`Updating shift ${shiftId} flag to ${isFlagged}`);

    if (typeof isFlagged !== 'boolean') {
      console.error(`Invalid isFlagged value: ${isFlagged}. Expected a boolean.`);
      return;
    }

    try {
      await updateShiftFlagStatus(shiftId, userId, isFlagged);
      console.log(`Shift ${shiftId} flag status updated to ${isFlagged} in the database`);

      setShifts((prevShifts) => {
        const updatedShifts = { ...prevShifts };
        for (const category in updatedShifts) {
          updatedShifts[category] = updatedShifts[category].map((shift) =>
            shift.id === shiftId ? { ...shift, isFlagged: isFlagged } : shift
          );
        }
        return updatedShifts;
      });

      // Update flagged shifts count
      setFlaggedShiftsCount((prevCount) => Math.max(0, prevCount + (isFlagged ? 1 : -1)));

      // If we're in the flagged shifts view, update the flaggedShifts state
      if (showFlaggedOnly) {
        setFlaggedShifts((prevFlaggedShifts) => {
          const updatedFlaggedShifts = { ...prevFlaggedShifts };
          for (const category in updatedFlaggedShifts) {
            if (isFlagged) {
              // Add the shift to flaggedShifts if it's not already there
              const shiftToAdd = shifts[category].find((shift) => shift.id === shiftId);
              if (shiftToAdd && !updatedFlaggedShifts[category].some((shift) => shift.id === shiftId)) {
                updatedFlaggedShifts[category] = [...updatedFlaggedShifts[category], shiftToAdd];
              }
            } else {
              // Remove the shift from flaggedShifts
              updatedFlaggedShifts[category] = updatedFlaggedShifts[category].filter((shift) => shift.id !== shiftId);
            }
          }
          return updatedFlaggedShifts;
        });
      }
    } catch (error) {
      console.error('Error updating shift flag status:', error);
      // Optionally, you can add error handling here, such as showing an error message to the user
    }
  };

  useEffect(() => {
    if (Array.isArray(devices) && devices.length > 0) {
      const validDevices = devices.filter((device) => typeof device === 'string' && device.trim() !== '');
      setSelectedPersonnel(validDevices);
    } else {
      console.error('Invalid devices prop:', devices);
      setSelectedPersonnel([]);
    }
  }, [devices]);

  useEffect(() => {
    if (selectedPersonnel.length > 0) {
      loadShifts(true);
      loadAllFlaggedShifts(); // Load flagged shifts initially
    }
  }, [selectedPersonnel]);

  const loadShifts = async (reset = false, startDate = new Date(), personnel = selectedPersonnel) => {
    if (reset) {
      setPage(1);
      setShifts({ today: [], yesterday: [], lastWeek: [], older: [] });
    }

    const currentPage = reset ? 1 : page;
    try {
      console.log('Fetching shifts with params:', { personnel, currentPage, startDate });
      if (!personnel || personnel.length === 0) {
        console.log('No personnel selected, skipping fetch');
        setHasMore(false);
        return;
      }

      // Modify the fetchShiftsForDevices call to include a pageSize parameter
      const newShifts = await fetchShiftsForDevices(userId, personnel, currentPage);
      // const pageSize = initialLoad ? 10 : 20; // Fetch fewer shifts on initial load
      // const newShifts = await fetchShiftsForDevices(userId, personnel, currentPage, pageSize);

      console.log('Fetched shifts:', newShifts);

      if (!newShifts || newShifts.length === 0) {
        console.log('No shifts fetched or newShifts is undefined');
        setHasMore(false);
        return;
      }

      const filteredShifts = newShifts.filter((shift) => {
        if (shift.inProgress || !shift.hasOwnProperty('duration')) {
          return true;
        }

        let offlineTime = 0;
        for (let i = 0; i < shift.events.length - 1; i++) {
          if (shift.events[i].type === 'end' && shift.events[i + 1].type === 'start') {
            offlineTime += new Date(shift.events[i + 1].timestamp) - new Date(shift.events[i].timestamp);
          }
        }

        const onlineTime = shift.duration * 1000 - offlineTime; // Convert duration to milliseconds
        // console.log('onlineTime', onlineTime);

        // Add calculated properties to the shift object
        // shift.calculatedOnlineTime = onlineTime;
        // shift.calculatedOfflineTime = offlineTime;

        return onlineTime > MIN_ONLINE_TIME;
      });

      const groupedShifts = groupShiftsByDateRange(filteredShifts, startDate);
      console.log('Grouped shifts:', groupedShifts);

      setShifts((prevShifts) => {
        const mergeShifts = (oldShifts, newShifts) => {
          const merged = [...oldShifts];
          newShifts.forEach((shift) => {
            if (!merged.some((s) => s.id === shift.id)) {
              merged.push(shift);
            }
          });
          return merged;
        };

        const updatedShifts = {
          today: mergeShifts(prevShifts.today, groupedShifts.today || []),
          yesterday: mergeShifts(prevShifts.yesterday, groupedShifts.yesterday || []),
          lastWeek: mergeShifts(prevShifts.lastWeek, groupedShifts.lastWeek || []),
          older: mergeShifts(prevShifts.older, groupedShifts.older || []),
        };

        // console.log('Updated shifts:', updatedShifts);
        return updatedShifts;
      });

      // setHasMore(newShifts.length === pageSize);
      setHasMore(newShifts.length === 20);
      setPage(currentPage + 1);
      // setInitialLoad(false); // Set initialLoad to false after the first load
    } catch (error) {
      console.error('Error loading shifts:', error);
      setHasMore(false);
    }
  };

  Object.values(shifts).forEach((shiftArray) => {
    shiftArray.forEach((shift) => {
      if (shift.id === 'c79b0930-f84b-4c3d-ad6b-84bc71256988') {
        console.log('Shift with ID c79b0930-f84b-4c3d-ad6b-84bc71256988:', shift.duration);
      }
    });
  });
  const filterFlaggedShifts = (shifts) => {
    const flaggedShifts = {
      today: shifts.today.filter((shift) => shift.isFlagged),
      yesterday: shifts.yesterday.filter((shift) => shift.isFlagged),
      lastWeek: shifts.lastWeek.filter((shift) => shift.isFlagged),
      older: shifts.older.filter((shift) => shift.isFlagged),
    };
    return flaggedShifts;
  };

  const handlePersonnelChange = (personnel) => {
    setSelectedPersonnel(personnel);
    setShifts({ today: [], yesterday: [], lastWeek: [], older: [] });
    setPage(1);
    setFlaggedShiftsCount(0);
    setHasMore(personnel.length > 0);
    if (showFlaggedOnly) {
      loadAllFlaggedShifts(personnel);
    } else if (personnel.length > 0) {
      loadShifts(true, new Date(), personnel);
    }
  };

  const handleToggleChange = (showFlagged) => {
    setShowFlaggedOnly(showFlagged);
    if (showFlagged) {
      loadAllFlaggedShifts();
    }
    setExpandedShiftIds(new Set());
  };

  const handleLoadMore = () => {
    loadShifts(false);
  };

  const handleJumpTo = (option) => {
    let startDate;
    switch (option) {
      case 'today':
        startDate = new Date();
        break;
      case 'yesterday':
        startDate = new Date();
        startDate.setDate(startDate.getDate() - 1);
        break;
      case 'lastWeek':
        startDate = new Date();
        startDate.setDate(startDate.getDate() - 7);
        break;
      case 'lastMonth':
        startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 1);
        break;
      case 'specificDate':
        // You can implement a date picker here
        break;
      default:
        return;
    }

    setShifts({ today: [], yesterday: [], lastWeek: [], older: [] });
    setPage(1);
    loadShifts(true, startDate);
  };

  const calculateFlaggedShiftsCount = () => {
    return Object.values(shifts).reduce((count, shiftGroup) => {
      return count + shiftGroup.filter((shift) => shift.isFlagged).length;
    }, 0);
  };

  const loadAllFlaggedShifts = async (personnel = selectedPersonnel) => {
    try {
      console.log('Fetching all flagged shifts for personnel:', personnel);
      if (!personnel || personnel.length === 0) {
        console.log('No personnel selected, skipping fetch');
        setFlaggedShiftsCount(0);
        return;
      }
      const allFlaggedShifts = await fetchAllFlaggedShiftsForDevices(personnel);
      console.log('Fetched flagged shifts:', allFlaggedShifts);

      const groupedShifts = groupShiftsByDateRange(allFlaggedShifts);
      setFlaggedShifts(groupedShifts);
      setFlaggedShiftsCount(allFlaggedShifts.length);
    } catch (error) {
      console.error('Error loading flagged shifts:', error);
      setFlaggedShiftsCount(0);
    }
  };

  return (
    <div className="shiftreview-container">
      <ShiftToggle
        onToggleChange={handleToggleChange}
        flaggedShiftsCount={flaggedShiftsCount}
        deviceAssignments={deviceAssignments}
        onPersonnelChange={handlePersonnelChange}
        selectedPersonnel={selectedPersonnel}
      />
      <div className="shiftreview-body">
        {showFlaggedOnly && flaggedShiftsCount === 0 ? (
          <div className="no-flagged-shifts-message">No Flagged Shifts to show</div>
        ) : (
          <ShiftCardList
            shifts={showFlaggedOnly ? flaggedShifts : shifts}
            devices={selectedPersonnel}
            onLoadMore={handleLoadMore}
            hasMore={!showFlaggedOnly && hasMore}
            onJumpTo={handleJumpTo}
            onUpdateShiftFlag={updateShiftFlag}
            expandedShiftIds={expandedShiftIds}
            setExpandedShiftIds={setExpandedShiftIds}
          />
        )}
      </div>
    </div>
  );
};

export default ShiftReview;
