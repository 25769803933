import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import Typography from '@mui/material/Typography';
import '../../../../../../../styles/NewDashboard/ShiftReview/ShiftCard/ShiftExpanded/CustomizedTimeline.css';
import { useState, useEffect } from 'react';
import { fetchAlertById } from '../../../../../../../utils/dashboardCosmosQueries';
import BatteryIcon from './BatteryIcon'; // Add this import

export default function CustomizedTimeline({ events, onEventSelect, deviceId, batteryLevels }) {
  // console.log('events', events);
  const [selectedItem, setSelectedItem] = useState(0);
  const [clickedItem, setClickedItem] = useState(null);
  const [escalationDescriptions, setEscalationDescriptions] = useState({});

  useEffect(() => {
    if (events.length > 0) {
      onEventSelect(events[0]);
    }
    const fetchDescriptions = async () => {
      const descriptions = await Promise.all(
        events
          .filter((event) => event.type === 'escalation')
          .map(async (event) => {
            try {
              const data = await fetchAlertById(event.alertId);
              return {
                description: data.Description,
                id: event.alertId,
                label: data.Label,
              };
            } catch (err) {
              console.error('Failed to fetch alert data:', err);
              return null;
            }
          })
      );
      const descriptionsMap = descriptions.reduce((acc, description) => {
        if (description) {
          acc[description.id] = description.description;
        }
        return acc;
      }, {});
      setEscalationDescriptions(descriptionsMap);
    };
    fetchDescriptions();
  }, [events, deviceId]);

  const handleItemClick = (index) => {
    setSelectedItem(index);
    setClickedItem(index);
    onEventSelect({ index: index, event: events[index] });
    setTimeout(() => setClickedItem(null), 300);
  };

  const eventTypes = {
    escalation: { color: 'warning', title: 'Escalation' },
    offline: { color: 'error', title: 'Offline' },
    start: { color: 'success', title: 'Shift Started' },
    end: { color: 'error', title: 'Shift Ended' },
    default: { color: 'info', title: 'Event' },
    recording_end: { color: 'info', title: 'Recording Ended' },
    recording_start: { color: 'info', title: 'Recording Started' },
    online: { color: 'success', title: 'Online' },
  };

  const getEventTitle = (event, index, events) => {
    if (event.type === 'start') {
      return index === 0 ? 'Shift Started' : 'Online';
    } else if (event.type === 'end') {
      return index === events.length - 1 ? 'Shift Ended' : 'Offline';
    } else {
      return eventTypes[event.type]?.title || eventTypes.default.title;
    }
  };

  const formatTime = (event) => {
    const dateString = event.timestamp;
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return isNaN(date.getTime())
      ? 'Invalid Date'
      : date.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit' });
  };

  const getBatteryIcon = (event) => {
    const level = batteryLevels[event.timestamp];
    if (level === null || level === undefined) return null;

    const percentage = parseInt(level);
    if (isNaN(percentage)) return null;

    return (
      <span className="battery-info">
        <BatteryIcon percentage={percentage} size="0.9em" />
        <span className="battery-percentage">{percentage}%</span>
      </span>
    );
  };

  console.log('Current battery levels state:', batteryLevels); // Debug log

  return (
    <Timeline
      position="right"
      className="timeline-container"
      sx={{
        '& .MuiTimelineItem-root': { minHeight: 'auto' },
        '& .MuiTimelineOppositeContent-root': { flex: 0.3, paddingLeft: '5px' },
        '& .MuiTimelineContent-root': { flex: 0.7, paddingRight: '5px', marginTop: '-4px' },
        '& .MuiTimelineConnector-root': { minHeight: '40px' },
        margin: '0',
      }}
    >
      {events.map((event, index) => (
        <TimelineItem
          key={index}
          onClick={() => handleItemClick(index)}
          sx={{
            cursor: 'pointer',
            opacity: selectedItem === index ? 1 : 0.7,
            transition: 'opacity 0.1s ease',
            '&:hover': { opacity: 1 },
            minWidth: '10px',
          }}
          className={clickedItem === index ? 'timeline-item-clicked' : ''}
        >
          <TimelineOppositeContent color="text.secondary">{formatTime(event)}</TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot color={eventTypes[event.type]?.color || eventTypes.default.color} />
            {index < events.length - 1 && event.type !== 'end' && <TimelineConnector />}
            {event.type === 'end' && (
              <>
                <br />
                <br />{' '}
              </>
            )}
          </TimelineSeparator>
          <TimelineContent>
            <Typography variant="h6" component="span" className={selectedItem === index ? 'selected-title' : ''}>
              {getEventTitle(event, index, events)}
              {(index === 0 || (index === events.length - 1 && event.type === 'end')) && (
                <span className="battery-icon-wrapper">{getBatteryIcon(event)}</span>
              )}
            </Typography>
            {event.type === 'escalation' && (
              <span className="timeline-item-description">{escalationDescriptions[event.alertId]}</span>
            )}
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
}
