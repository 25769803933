import '../../../../../../../styles/NewDashboard/ShiftReview/ShiftCard/ShiftExpanded/EventDetail/VideoView.css';
import VideoPlayer from '../../../../../../VideoPlayer';
import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { fetchAlertById } from '../../../../../../../utils/dashboardCosmosQueries';

const VideoView = ({ event }) => {
  const [alertData, setAlertData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const videoRef = useRef(null);
  const [videoTime, setVideoTime] = useState(0);
  const [initialTime, setInitialTime] = useState(0);

  useEffect(() => {
    if (event.type === 'escalation' && event.alertId) {
      setLoading(true);
      fetchAlertById(event.alertId)
        .then((data) => {
          setAlertData(data);
          setLoading(false);
        })
        .catch((err) => {
          setError('Failed to fetch alert data: ' + err.message);
          setLoading(false);
        });
    } else if (event.videoFileName) {
      setAlertData({ VideoFileName: event.videoFileName });
      setLoading(false);
    }
  }, [event]);

  const handleVideoTimeUpdate = useCallback(() => {
    if (videoRef.current) {
      setVideoTime(videoRef.current.currentTime);
    }
  }, []);

  const markers = useMemo(() => {
    if (alertData && alertData.RelativeTimestamp) {
      return [
        {
          time: alertData.RelativeTimestamp,
          label: alertData.Label,
        },
      ];
    }
    return [];
  }, [alertData]);

  const videoPlayerKey = useMemo(() => event.alertId || event.videoFileName, [event.alertId, event.videoFileName]);

  useEffect(() => {
    setInitialTime(videoTime);
  }, [event]);

  if (loading) {
    return <div className="event-detail-video-view-container">Loading...</div>;
  }

  if (error) {
    return <div className="event-detail-video-view-container">{error}</div>;
  }

  if (alertData && alertData.VideoFileName) {
    // console.log('alertData---------', alertData);
    return (
      <div className="event-detail-video-view-container">
        <div className="event-detail-video-player-container">
          <VideoPlayer
            videoFileName={alertData.VideoFileName}
            markers={markers}
            token={process.env.REACT_APP_BLOB_SAS_TOKEN}
            ref={videoRef}
            onTimeUpdate={handleVideoTimeUpdate}
            preStart={10}
            initialTime={initialTime}
            key={videoPlayerKey} // Add a key prop
          />
        </div>
      </div>
    );
  }

  return (
    <div className="event-detail-video-view-container">
      <p>Video is not available for this event</p>
    </div>
  );
};

export default React.memo(VideoView);
