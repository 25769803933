import '../../../../../../styles/NewDashboard/ShiftReview/ShiftCard/ShiftExpanded/ShiftExpanded.css';
import '../../../../../../styles/NewDashboard/ShiftReview/ShiftCard/ShiftCard.css';
import CustomizedTimeline from './Timeline/CustomizedTimeline';
import EventDetail from './EventDetail/EventDetail';
import EventInfo from './EventInfo/EventInfo';
import { useState, useEffect } from 'react';
import { fetchAlertById } from '../../../../../../utils/dashboardCosmosQueries';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { faFlag as faFlagSolid } from '@fortawesome/free-solid-svg-icons';
import { faFlag as faFlagOutlined } from '@fortawesome/free-regular-svg-icons';
import { fetchLocationsForShift } from '../../../../../../utils/dashboardTimescaleQueries';
import { getDaysDifference } from '../../../../../../utils/shiftUtils';
// import { faBatteryQuarter } from '@fortawesome/free-solid-svg-icons';
import ErrorBoundary from '../../../../../ErrorBoundary';
import { Tooltip } from '@mui/material';
// import TimelineMap from './TimelineMap';

const ShiftExpanded = ({ shift, batteryLevels, onCollapse, onFlagShift, timeRatio, deviceId }) => {
  const { assignedTo, startTime, endTime, events, duration, inProgress, isFlagged } = shift;
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showVideo, setShowVideo] = useState(false);
  const [alertData, setAlertData] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  const [locations, setLocations] = useState([]);
  const daysDifference = endTime ? getDaysDifference(startTime, endTime) : 0;
  // console.log('timeRatio', timeRatio);
  console.log('shift', shift);
  useEffect(() => {
    const fetchOrCreateLocations = async () => {
      // test_id shift is only for demo purposes
      if (shift.id === 'test_id') {
        const fakeLocations = createFakeLocations(shift.startTime, shift.endTime);
        setLocations(fakeLocations);
      } else {
        const endTimeToUse = shift.inProgress ? new Date().toISOString() : shift.endTime;
        const fetchedLocations = await fetchLocationsForShift(deviceId, shift.startTime, endTimeToUse);
        setLocations(fetchedLocations);
      }
    };

    fetchOrCreateLocations();
  }, [deviceId, shift.startTime, shift.endTime, shift.inProgress]);

  useEffect(() => {
    if (shift.events && shift.events.length > 0) {
      setSelectedEvent({ index: 0, event: shift.events[0] });
    }
  }, [shift.events]);

  useEffect(() => {
    if (
      selectedEvent &&
      selectedEvent.event &&
      selectedEvent.event.type === 'escalation' &&
      selectedEvent.event.alertId
    ) {
      setAlertData(null);
      fetchAlertById(selectedEvent.event.alertId)
        .then((data) => {
          setAlertData(data);
          setShowVideo(true);
        })
        .catch((err) => {
          console.error('Failed to fetch alert data:', err);
        });
    } else {
      setAlertData(null);
      setShowVideo(false);
    }
  }, [selectedEvent]);
  console.log('selectedEvent', selectedEvent);
  const escalationCount = events.filter((event) => event.type === 'escalation').length;

  const formatDuration = (durationInSeconds) => {
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    return `${hours}h ${minutes}m`;
  };
  const handleFlagShift = (e) => {
    e.stopPropagation(); // Stop the event from propagating to parent elements
    const currentFlagStatus = Boolean(shift.isFlagged);
    const newFlagStatus = !currentFlagStatus;
    console.log(`Toggling flag for shift ${shift.id} from ${currentFlagStatus} to ${newFlagStatus}`);
    onFlagShift(shift.id, newFlagStatus);
  };

  const onlineTime = formatDuration(shift.calculatedOnlineTime);
  const offlineTime = formatDuration(shift.calculatedOfflineTime);

  const timeRatioTooltip = (
    <div className="custom-tooltip">
      <div className="tooltip-row">
        <span className="tooltip-dot online"></span>
        <span className="tooltip-label">Online</span>
        <span className="tooltip-value">{onlineTime}</span>
      </div>
      <div className="tooltip-row">
        <span className="tooltip-dot offline"></span>
        <span className="tooltip-label">Offline</span>
        <span className="tooltip-value">{offlineTime}</span>
      </div>
    </div>
  );

  return (
    <div className="shift-expanded-container">
      <div className="shift-expanded-header" onClick={onCollapse}>
        <div className="shift-expanded-left">
          <div className="shift-expanded-personnel">{assignedTo}</div>
          {/* <div className="shift-expanded-date">
            <span className="date-badge">{new Date(startTime).toLocaleDateString()}</span>
          </div> */}
          <div className="shift-expanded-time">
            <div className="start-time-container">
              <span className="time-badge">
                {new Date(startTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
              </span>
              <span className="online-time-decimal">{new Date(startTime).toLocaleDateString()}</span>
            </div>
            <div className="duration-container">
              {!inProgress ? (
                <span className="online-time-decimal">{formatDuration(duration)}</span>
              ) : (
                <span className="online-time-decimal">{formatDuration((Date.now() - new Date(startTime)) / 1000)}</span>
              )}
              <hr className="duration-divider"></hr>
            </div>
            <div className="end-time-container">
              {inProgress ? (
                <>
                  <span className="time-badge in-progress">In Progress</span>
                  <span className="online-time-decimal">{new Date().toLocaleDateString()}</span>
                </>
              ) : (
                <>
                  <span className="time-badge">
                    {new Date(endTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                    {daysDifference > 0 && <sup>+{daysDifference}</sup>}
                  </span>
                  <span className="online-time-decimal">{new Date(endTime).toLocaleDateString()}</span>
                </>
              )}
            </div>
          </div>
          {!inProgress && (
            <>
              {/* <span className="duration-badge">{formatDuration(duration)}</span> */}
              <div className="online-time-container">
                <span className="online-time-decimal">{onlineTime} online</span>
                <Tooltip title={timeRatioTooltip} classes={{ tooltip: 'custom-tooltip-wrapper' }}>
                  <div className="time-ratio-bar">
                    <div
                      className="online-time"
                      style={{
                        width: `${timeRatio.onlineRatio}%`,
                      }}
                    ></div>
                    {shift.calculatedOfflineTime > 0 && (
                      <div
                        className="offline-time"
                        style={{
                          width: `${timeRatio.offlineRatio}%`,
                        }}
                      ></div>
                    )}
                  </div>
                </Tooltip>
              </div>
            </>
          )}
        </div>
        <div className="shift-expanded-right">
          <div className="shift-expanded-escalations">
            {escalationCount > 0 && (
              <span className="escalation-badge">
                <>
                  <FontAwesomeIcon icon={faExclamationTriangle} className="escalation-icon" />
                  <span className="escalation-count">{escalationCount}</span>
                </>
              </span>
            )}
          </div>
        </div>
        <div
          className="shift-flags"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={handleFlagShift}
        >
          {shift.isFlagged ? (
            <FontAwesomeIcon icon={faFlagSolid} className="flag-icon filled" onClick={handleFlagShift} />
          ) : (
            <FontAwesomeIcon
              icon={isHovered ? faFlagSolid : faFlagOutlined}
              className={`flag-icon ${isHovered ? 'filled' : 'outlined'}`}
              onClick={handleFlagShift}
            />
          )}
        </div>
        <button className="shift-expanded-collapse">-</button>
      </div>
      <div className="shift-expanded-content">
        <CustomizedTimeline
          events={shift.events}
          onEventSelect={setSelectedEvent}
          deviceId={deviceId}
          batteryLevels={batteryLevels}
        />
        {selectedEvent && (
          <>
            <ErrorBoundary>
              <EventDetail
                showVideo={showVideo}
                event={selectedEvent.event}
                onShowVideo={setShowVideo}
                alertData={alertData}
                locations={locations}
              />
            </ErrorBoundary>
            <EventInfo
              showVideo={showVideo}
              selectedEvent={selectedEvent}
              alertData={alertData}
              assignedTo={assignedTo}
              allEvents={shift.events}
            />
          </>
        )}
      </div>
    </div>
  );
};

// for demo purposes
const createFakeLocations = (startTime, endTime) => {
  const start = new Date('2024-07-30T04:15:05.51228+00:00');
  const end = new Date('2024-07-30T11:42:36.221062+00:00');
  const fakeLocations = [];
  const baseLatitude = 38.5809;
  const baseLongitude = -121.496;

  for (let time = start; time <= end; time.setMinutes(time.getMinutes() + 10)) {
    fakeLocations.push({
      time: time.toISOString(),
      latitude: baseLatitude + (Math.random() - 0.005) * 0.0001,
      longitude: baseLongitude + (Math.random() - 0.005) * 0.0001,
    });
  }

  return fakeLocations;
};

export default ShiftExpanded;
