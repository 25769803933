import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { formatTime } from '../utils/dashboardUtils';

const DashboardChart = ({ metricsData, selectedMetric, devices, onChartClick }) => {
  if (!metricsData[selectedMetric] || metricsData[selectedMetric].length === 0) {
    return <p>No chart available for the current selection.</p>;
  }

  const metricData = metricsData[selectedMetric];

  const formatName = (name) => {
    return name.length === 0 ? 'Unnamed' : name;
  };

  const getYAxisLabel = () => {
    switch (selectedMetric) {
      case 'escalations':
        return 'Escalations';
      case 'shiftDuration':
        return 'Hours in Shift';
      case 'recordingDuration':
        return 'Hours Recorded';
      default:
        return '';
    }
  };

  const customTooltip = ({ payload, label }) => {
    if (!payload || payload.length === 0) return null;

    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          border: '1px solid #ccc',
          padding: '10px',
          borderRadius: '5px',
        }}
      >
        <p className="label">{label}</p>
        {payload
          .filter((entry) => entry.value > 0)
          .map((entry, index) => (
            <p key={`item-${index}`} style={{ color: entry.color }}>
              {formatName(entry.name)}: {formatTime(entry.value)}
            </p>
          ))}
      </div>
    );
  };

  return (
    <LineChart width={600} height={300} data={metricData} style={{ margin: '0 auto' }} onClick={onChartClick}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis label={{ value: getYAxisLabel(), angle: -90, position: 'insideLeft', style: { textAnchor: 'middle' } }} />
      <Tooltip content={customTooltip} />
      <Line type="monotone" dataKey="Total" stroke="#000000" activeDot={{ r: 8 }} />
      {devices.map((device, index) => (
        <Line
          key={device.deviceId}
          type="monotone"
          dataKey={device.assignedTo || device.deviceId}
          stroke={`hsl(${index * 50}, 70%, 50%)`}
        />
      ))}
    </LineChart>
  );
};

export default DashboardChart;
