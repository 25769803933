export const fetchBatteryDropTime = async (deviceId, startTime, endTime) => {
  try {
    const response = await fetch(
      `https://nginx-test.tail24705.ts.net/battery?device=eq.${deviceId}&time=gt.${startTime.toISOString()}&time=lt.${endTime.toISOString()}&level=lt.5&order=time.asc&limit=1`
    );
    const data = await response.json();
    return data.length ? new Date(data[0].time) : null;
  } catch (error) {
    console.error('Error fetching battery data:', error);
    return null;
  }
};

export const fetchTimescaleData = async (deviceId, fromDate, toDate) => {
  const batteryUrl = `https://nginx-test.tail24705.ts.net/battery?streaming_audio=eq.true&device=eq.${deviceId}&time=gte.${fromDate}&time=lt.${toDate}&level=gt.5&order=time.asc`;
  const locationUrl = `https://nginx-test.tail24705.ts.net/location?device=eq.${deviceId}&time=gte.${fromDate}&time=lt.${toDate}&order=time.asc`;
  try {
    const [batteryData, locationData] = await Promise.all([
      fetch(batteryUrl).then((res) => res.json()),
      fetch(locationUrl).then((res) => res.json()),
    ]);

    return {
      batteryData: batteryData.sort((a, b) => new Date(a.time) - new Date(b.time)),
      locationData: locationData.sort((a, b) => new Date(a.time) - new Date(b.time)),
    };
  } catch (error) {
    console.error(`Error fetching data for device ${deviceId}:`, error);
    return { batteryData: [], locationData: [] };
  }
};

export const fetchLocationAtTimestamp = async (deviceId, timestamp) => {
  const startTime = Date.now();

  // Convert timestamp to Date object
  const date = new Date(timestamp);

  // Calculate 10 minutes before and after
  const tenMinutesBefore = new Date(date.getTime() - 10 * 60000).toISOString();
  const tenMinutesAfter = new Date(date.getTime() + 10 * 60000).toISOString();

  let url = `https://nginx-test.tail24705.ts.net/location?device=eq.${deviceId}&time=gte.${tenMinutesBefore}&time=lte.${tenMinutesAfter}&order=time.desc&limit=1`;

  try {
    let response = await fetch(url);
    let data = await response.json();

    // If no location found in the time window, fetch the last recorded location
    if (data.length === 0) {
      url = `https://nginx-test.tail24705.ts.net/location?device=eq.${deviceId}&order=time.desc&limit=1`;
      response = await fetch(url);
      data = await response.json();
    }

    const endTime = Date.now();
    const latency = endTime - startTime;

    if (true || process.env.REACT_APP_PRINT_LATENCY === 'true') {
      console.log(`Latency for fetching location: ${latency}ms`);
    }

    return data.length ? { lat: data[0].latitude, lng: data[0].longitude } : null;
  } catch (error) {
    console.error(`Error fetching location for device ${deviceId} at ${timestamp}:`, error);
    return null;
  }
};

export const fetchBatteryAtTimestamp = async (deviceId, timestamp) => {
  const startTime = Date.now();

  // Convert timestamp to Date object
  const date = new Date(timestamp);

  // Calculate 25 minutes after
  const twentyFiveMinutesAfter = new Date(date.getTime() + 25 * 60000).toISOString();

  let url = `https://nginx-test.tail24705.ts.net/battery?device=eq.${deviceId}&time=lte.${twentyFiveMinutesAfter}&order=time.desc&limit=15`;

  try {
    let response = await fetch(url);
    let data = await response.json();

    const endTime = Date.now();
    const latency = endTime - startTime;

    if (true || process.env.REACT_APP_PRINT_LATENCY === 'true') {
      console.log(`Latency for fetching battery: ${latency}ms`);
    }

    if (data.length) {
      // Find the battery level closest to the timestamp
      let closest = data.reduce((prev, curr) => {
        return Math.abs(new Date(curr.time) - date) < Math.abs(new Date(prev.time) - date) ? curr : prev;
      });

      return closest.level !== undefined ? closest.level : null;
    } else {
      return null;
    }
  } catch (error) {
    console.error(`Error fetching battery for device ${deviceId} at ${timestamp}:`, error);
    return null;
  }
};

export const fetchLocationsForShift = async (deviceId, startTime, endTime) => {
  console.log('fetching locations for shift', deviceId, startTime, endTime);
  const startISO = new Date(startTime).toISOString();
  const endISO = new Date(endTime).toISOString();
  const url = `https://nginx-test.tail24705.ts.net/location?device=eq.${deviceId}&time=gte.${startISO}&time=lt.${endISO}&order=time.asc`;
  console.log('url', url);
  try {
    let response = await fetch(url);
    let data = await response.json();
    console.log('locations from fetchLocationsForShift', data);
    return data;
  } catch (error) {
    console.error(`Error fetching locations for device ${deviceId} from ${startISO} to ${endISO}:`, error);
    return [];
  }
};
