import { CosmosClient } from '@azure/cosmos';
import { getQueryAndParams } from '../utils/utilsEvents';
import pLimit from 'p-limit';
import { PLIX_OVERWATCH_ACCOUNT } from '../utils/utilsEvents';

const cosmosClient = new CosmosClient({
  endpoint: 'https://plixeventstorage.documents.azure.com:443/',
  key: process.env.REACT_APP_AZURE_COSMOS_KEY,
});

const devicesContainer = cosmosClient.database('Devices').container('Devices');
const organizationsContainer = cosmosClient.database('Organizations').container('Organizations');

const shiftsContainer = cosmosClient.database('Shifts').container('Shifts');

const limit = pLimit(6);

export const fetchDeviceConfigs = async (userIdOrOrgId) => {
  try {
    let query;
    if (userIdOrOrgId === PLIX_OVERWATCH_ACCOUNT) {
      // Fetch all devices for overwatch
      query = {
        query: 'SELECT * FROM c',
      };
    } else {
      // Fetch devices for specific user or org
      query = {
        query: 'SELECT * FROM c WHERE c.userId = @userId OR c.orgUserId = @userId',
        parameters: [{ name: '@userId', value: userIdOrOrgId }],
      };
    }

    const { resources: devices } = await devicesContainer.items.query(query).fetchAll();
    return devices;
  } catch (error) {
    console.error('Error fetching device configs:', error);
    return [];
  }
};

export const fetchLatestDeviceLog = async (deviceId) => {
  const container = cosmosClient.database('DeviceLogs').container('DeviceLogs');
  const querySpec = {
    query: 'SELECT TOP 1 * FROM c WHERE c.deviceId = @deviceId ORDER BY c.timestamp DESC',
    parameters: [{ name: '@deviceId', value: deviceId }],
  };
  const {
    resources: [latestLog],
  } = await container.items.query(querySpec).fetchAll();
  return latestLog;
};

const mapDeviceState = (action) => {
  switch (action) {
    case 'Start Shift':
      return 'In Shift';
    case 'End Shift':
      return 'Offline';
    case 'Start Recording':
      return 'Recording';
    case 'Stop Recording':
      return 'In Shift';
    default:
      return 'Unknown';
  }
};

export const fetchDeviceStates = async (deviceIds) => {
  try {
    if (!Array.isArray(deviceIds) || deviceIds.length === 0) {
      console.error('Invalid deviceIds:', deviceIds);
      return {};
    }

    const now = new Date();

    // Fetch Timescale data
    const deviceQuery = deviceIds.map((id) => `device.eq.${id}`).join(',');
    const timescaleResponse = await fetch(
      `https://nginx-test.tail24705.ts.net/get_shift_state?or=(${deviceQuery})&order=time.desc`,
      {
        headers: {
          Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoid2ViX3VzZXIifQ.WNKqXKSTeEH9THF4BAhUWkn9FpPaUudrPjQ4n3mNnz0`,
        },
      }
    );
    const timescaleData = await timescaleResponse.json();

    const timescaleStates = timescaleData.reduce((acc, item) => {
      acc[item.device] = {
        shiftStatus: item.shift,
        lastShiftTime: new Date(item.time),
      };
      return acc;
    }, {});

    // Fetch Cosmos DB data
    const fetchCosmosData = async (deviceId) => {
      const query = {
        query: `
          SELECT TOP 1 c.startTime, c.events, c.inProgress, c.duration
          FROM c
          WHERE c.deviceId = @deviceId
          AND (NOT IS_DEFINED(c.duration) OR c.duration > 120)
          ORDER BY c.startTime DESC
        `,
        parameters: [{ name: '@deviceId', value: deviceId }],
      };
      const { resources } = await shiftsContainer.items.query(query).fetchAll();
      if (resources.length > 0) {
        return {
          [deviceId]: resources[0],
        };
      }
      return { [deviceId]: null };
    };

    const cosmosDataArray = await Promise.all(deviceIds.map((deviceId) => limit(() => fetchCosmosData(deviceId))));
    const cosmosData = Object.assign({}, ...cosmosDataArray);

    // Combine and adjust data
    const finalDeviceStates = {};
    for (const deviceId of deviceIds) {
      const timescaleState = timescaleStates[deviceId];
      const cosmosState = cosmosData[deviceId];

      let shiftStatus, lastShiftTime, duration;

      // Determine shift status from Timescale
      shiftStatus = timescaleState ? timescaleState.shiftStatus : false;

      // Determine last shift time
      if (shiftStatus) {
        lastShiftTime = 'Now';
      } else if (cosmosState) {
        const events = cosmosState.events || [];
        const lastEvent = events[events.length - 1];
        if (lastEvent) {
          lastShiftTime = new Date(lastEvent.timestamp).toISOString();
        } else {
          lastShiftTime = new Date(cosmosState.startTime).toISOString();
        }
      } else {
        lastShiftTime = null;
      }

      // Determine duration
      console.log('Device: ');
      if (shiftStatus && cosmosState.inProgress) {
        const shiftStartTime = new Date(cosmosState.startTime);
        duration = Math.round((now - shiftStartTime) / (1000 * 60));
      } else {
        duration = null;
      }

      finalDeviceStates[deviceId] = {
        shiftStatus,
        lastShiftTime,
        duration,
        recording_audio: false, // We don't have this info, so defaulting to false
      };

      // Log the requested information
      console.log(`Device ${deviceId}:
        Timescale shift status: ${timescaleState ? timescaleState.shiftStatus : 'N/A'}
        Final shift status: ${shiftStatus}
        Last shift time: ${lastShiftTime}
        Duration: ${duration !== null ? `${duration} minutes` : 'N/A'}
        inProgress: ${cosmosState ? cosmosState.inProgress : 'N/A'}
        Cosmos last event: ${cosmosState && cosmosState.events ? cosmosState.events[cosmosState.events.length - 1].type : 'N/A'}
      `);
    }

    return finalDeviceStates;
  } catch (error) {
    console.error('Error fetching device states:', error);
    return deviceIds.reduce((acc, id) => {
      acc[id] = { shiftStatus: false, lastShiftTime: null, duration: null, recording_audio: false };
      return acc;
    }, {});
  }
};

export const fetchSignalStrengths = async (deviceIds) => {
  if (!deviceIds || deviceIds.length === 0) return {};

  try {
    const deviceQuery = deviceIds.map((id) => `device.eq.${id}`).join(',');
    const response = await fetch(`https://nginx-test.tail24705.ts.net/latest_signals?or=(${deviceQuery})`, {
      headers: {
        Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoid2ViX3VzZXIifQ.WNKqXKSTeEH9THF4BAhUWkn9FpPaUudrPjQ4n3mNnz0`,
      },
    });
    if (!response.ok) {
      if (response.status === 504) {
        return Object.fromEntries(deviceIds.map((id) => [id, 0]));
      }
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();

    const signalStrengths = {};
    deviceIds.forEach((id) => {
      const deviceData = data.find((item) => item.device === id);
      signalStrengths[id] = deviceData ? deviceData.level : 1;
    });

    return signalStrengths;
  } catch (error) {
    console.error('Error fetching signal strengths:', error);
    return Object.fromEntries(deviceIds.map((id) => [id, 1]));
  }
};

export const pingDevices = async (userId) => {
  try {
    const devices = await fetchDeviceConfigs(userId);
    const deviceIds = devices.map((device) => device.deviceId);

    const [deviceStates] = await Promise.all([
      // fetchSignalStrengths(deviceIds),
      fetchDeviceStates(deviceIds),
    ]);

    const latestLogs = await Promise.all(deviceIds.map((deviceId) => fetchLatestDeviceLog(deviceId)));

    return devices.map((device, index) => {
      const latestLog = latestLogs[index];
      // const signalStrength = signalStrengths[device.deviceId];
      const state = deviceStates[device.deviceId];

      return {
        deviceId: device.deviceId,
        location: [device.latitude, device.longitude],
        config: { streamAddress: device.streamUrl },
        isStreamActive: device.isVideoActive,
        isAlertsActive: true,
        deviceStatus: device.deviceStatus,
        deviceState: state.shiftStatus ? 'In Shift' : 'Offline',
        deviceName: device.deviceName,
        batteryStatus: device.batteryStatus,
        latitude: device.latitude,
        longitude: device.longitude,
        userId: device.userId,
        fcmToken: device.fcmToken,
        assignedTo: device.assignedTo,
        latestLog: latestLog ? { action: latestLog.action, buttonId: latestLog.buttonId } : null,
        // signalStrength,
        liveViewPolicy: device.liveViewPolicy,
        shiftStatus: state.shiftStatus,
        lastShiftTime: state.lastShiftTime,
        timeInShift: state.duration,
        isRecording: state.recording_audio,
      };
    });
  } catch (error) {
    console.error('Error in pingDevices:', error);
    return [];
  }
};

const determineDeviceState = (deviceId, state, latestLog) => {
  if (state) {
    if (state.recording_audio) return 'Recording';
    if (state.shiftStatus) return 'In Shift';
    return 'Offline';
  }
  // Fallback to Cosmos DB data if device not found in new data
  return latestLog ? mapDeviceState(latestLog.action) : 'Unknown';
};

export const updateDeviceConfig = async (deviceId, updates, userId) => {
  try {
    const baseQuery = 'SELECT * FROM c WHERE c.deviceId = @deviceId AND c.userId = @userId';
    const { query, parameters } = getQueryAndParams(baseQuery, userId, [{ name: '@deviceId', value: deviceId }]);

    const {
      resources: [existingDevice],
    } = await devicesContainer.items.query({ query, parameters }).fetchAll();
    if (!existingDevice) {
      console.error('userId, deviceId: ', userId, deviceId);
      throw new Error('Device not found or you do not have permission to update this device');
    }
    const updatedDevice = { ...existingDevice, ...updates };
    await devicesContainer.items.upsert(updatedDevice);
  } catch (error) {
    console.error('Error updating device config:', error);
    throw error; // Rethrow the error to be caught by the calling function
  }
};
