import { startOfDay, subDays, isToday, isYesterday, isThisWeek } from 'date-fns';
import { fetchAlertById } from './dashboardCosmosQueries';

const MIN_TIME_BETWEEN_EVENTS = 120;

export const groupShiftsByDateRange = (shifts) => {
  const today = startOfDay(new Date());
  const yesterday = subDays(today, 1);

  return shifts.reduce(
    (acc, shift) => {
      const shiftDate = new Date(shift.startTime);
      if (isToday(shiftDate)) {
        acc.today.push(shift);
      } else if (isYesterday(shiftDate)) {
        acc.yesterday.push(shift);
      } else if (isThisWeek(shiftDate)) {
        acc.lastWeek.push(shift);
      } else {
        acc.older.push(shift);
      }
      return acc;
    },
    { today: [], yesterday: [], lastWeek: [], older: [] }
  );
};

export const filterShiftEvents = async (shift) => {
  const filteredEvents = [];
  const events = shift.events;
  let calculatedOfflineTime = 0;

  for (let i = 0; i < events.length; i++) {
    const currentEvent = events[i];
    const nextEvent = events[i + 1];

    // Validate escalation events
    if (currentEvent.type === 'escalation') {
      try {
        const data = await fetchAlertById(currentEvent.alertId);
        if (data.hasOwnProperty('IsDeleted') && data.IsDeleted) {
          continue; // Skip this event if it's deleted
        }
      } catch (err) {
        console.error('Failed to fetch alert data:', err);
        continue; // Skip this event if there's an error
      }
    }

    // Check for consecutive start-end or end-start events
    if (nextEvent) {
      const timeDiff = (new Date(nextEvent.timestamp) - new Date(currentEvent.timestamp)) / 1000;
      if (
        (currentEvent.type === 'start' && nextEvent.type === 'end' && timeDiff <= MIN_TIME_BETWEEN_EVENTS) ||
        (currentEvent.type === 'end' && nextEvent.type === 'start' && timeDiff <= MIN_TIME_BETWEEN_EVENTS)
      ) {
        i++; // Skip the next event
        continue;
      }
    }

    filteredEvents.push(currentEvent);

    // Calculate offline time
    if (currentEvent.type === 'end' && nextEvent && nextEvent.type === 'start') {
      const offlineDuration = (new Date(nextEvent.timestamp) - new Date(currentEvent.timestamp)) / 1000;
      calculatedOfflineTime += offlineDuration;
    }
  }

  const updatedShift = { ...shift, events: filteredEvents };

  if (filteredEvents.length > 0) {
    updatedShift.startTime = filteredEvents[0].timestamp;
    if (!shift.inProgress && filteredEvents.length > 1) {
      updatedShift.endTime = filteredEvents[filteredEvents.length - 1].timestamp;
      updatedShift.duration = Math.round((new Date(updatedShift.endTime) - new Date(updatedShift.startTime)) / 1000);

      // Calculate online time
      updatedShift.calculatedOfflineTime = calculatedOfflineTime;
      updatedShift.calculatedOnlineTime = updatedShift.duration - calculatedOfflineTime;
    }
  }

  return updatedShift;
};

export const getDaysDifference = (start, end) => {
  const startDate = new Date(start).setHours(0, 0, 0, 0);
  const endDate = new Date(end).setHours(0, 0, 0, 0);
  return Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24));
};
