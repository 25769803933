import React, { useState, useEffect } from 'react';
import Metrics from './Metrics/Metrics';
import ShiftReview from './ShiftReview/ShiftReview';
import '../../styles/NewDashboard/NewDashboard.css';
import { fetchAssignedToFromDeviceIds } from '../../utils/dashboardCosmosQueries';
import { fetchDeviceConfigs } from '../../utils/deviceConfig';
import { toast } from 'react-toastify';
import { PLIX_OVERWATCH_ACCOUNT } from '../../utils/utilsEvents';
import { useDeviceFilter } from '../../contexts/DeviceFilterContext';

const NewDashboard = ({ userId }) => {
  const [devices, setDevices] = useState([]);
  const [deviceAssignments, setDeviceAssignments] = useState({});
  const [error, setError] = useState(null);
  const { selectedOrg } = useDeviceFilter();

  useEffect(() => {
    const fetchDevices = async () => {
      try {
        const effectiveUserId = userId === PLIX_OVERWATCH_ACCOUNT ? selectedOrg : userId;
        const fetchedDevices = await fetchDeviceConfigs(effectiveUserId);

        if (fetchedDevices && fetchedDevices.length > 0) {
          const deviceIds = fetchedDevices.map((device) => device.deviceId);
          setDevices(deviceIds);

          // Fetch device assignments
          const assignments = await fetchAssignedToFromDeviceIds(deviceIds);
          setDeviceAssignments(assignments);
        } else {
          setDevices([]);
          toast.error('No devices found');
        }
      } catch (err) {
        console.error('Error fetching devices:', err);
        toast.error('Failed to fetch devices. Please try again later.');
        setError('Failed to fetch devices. Please try again later.');
      }
    };

    fetchDevices();
  }, [userId, selectedOrg]);

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="newdashboard-container">
      <div className="newdashboard-header">
        <h1>Dashboard</h1>
      </div>
      <div className="newdashboard-body">
        {/* <Metrics /> */}
        <ShiftReview
          devices={devices}
          userId={userId}
          deviceAssignments={deviceAssignments}
          isOverwatch={userId === PLIX_OVERWATCH_ACCOUNT}
        />
      </div>
    </div>
  );
};

export default NewDashboard;
